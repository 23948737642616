import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
} from '../../Containers/PasserCommande/PasserCommande.styles'
import React, { useEffect } from 'react'
import {useState } from 'react'
import Modal from 'react-modal'
import { useForm ,Controller} from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import moment from 'moment'
import { CustomInput } from '../../Containers/RegisterTransporter/style'
import UploadFile from '../UploadSingleFile/upload.component'
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from '../../api/base'
import { VEHICLE_TYPES, LIGHT_VEHICLES, HEAVY_VEHICLES, COMMON_OPTIONS,IMAGES }  from '../../utils/vehicleData'
import { VehicleCard } from '../VehicleCard'
import { VehicleOptions } from '../VehicleOptions'

Modal.setAppElement('#root')

function AddVehicleRegister({
	onChange,
	initialVehicles = [],
	disableAdd = false,
}) {
	const [vehicles, setVehicles] = useState([])
	const [editIndex, setEditIndex] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const { control, handleSubmit, register,reset, setValue, watch, formState: { errors } } = useForm({
		defaultValues: {
			type: '',
			date_mise_circulation: '',
			immatriculation: '',
			carte_grise: null,
			location: false,
		},
	});	
	
    const handleSubmitForm = (data) => {
		let filteredData = { ...data };
		if (data.type === 'Véhicule léger') {
			filteredData = {
			  type: data.type,
			  date_mise_circulation: data.date_mise_circulation,
			  immatriculation: data.immatriculation,
			  location: data.location,
			  carte_grise:data.carte_grise,
			  options_vehicule_leger: data.options_vehicule_leger,
			  vehicule_leger: data.vehicule_leger,
			};
		  } else if (data.type === 'Poids lourds') {
			filteredData = {
			  type: data.type,
			  date_mise_circulation: data.date_mise_circulation,
			  immatriculation: data.immatriculation,
			  location: data.location,
			  carte_grise:data.carte_grise,
			  options_poids_lourds: data.options_poids_lourds,
			  vehicule_poids_lourds: data.vehicule_poids_lourds,
			};
		  }
		if (editIndex !== null) {
		  const updatedVehicles = vehicles.map((v, index) =>
			index === editIndex ? data : v
		  );
		  setVehicles(updatedVehicles);
		  onChange(updatedVehicles);
		  setEditIndex(null); 
		 

		} else {
		  setVehicles([...vehicles, filteredData]);
		  onChange([...vehicles, filteredData]);

		}
		reset({type: '',
			date_mise_circulation: '',
			immatriculation: '',
			carte_grise: null,
			location: false,})
		setModalIsOpen(false); 
	};
	
	const handleDelete = (index) => {
		const newVehicles = vehicles.filter((_, i) => i !== index);
		setVehicles(newVehicles);
		onChange(newVehicles);
	};
	
	const handleEdit = (index) => {
		const selectedVehicle = vehicles[index];
		reset(selectedVehicle);
		setModalIsOpen(true); 
		setEditIndex(index); 
	};
	const getVehicleOptions = () => {
		if (type === VEHICLE_TYPES.LIGHT) {
		  if (['Break', 'Fourgon 12m³', 'Fourgon 14m³'].includes(vehicule_leger)) {
			return COMMON_OPTIONS;
		  }
		  return [...COMMON_OPTIONS, 'Bachée'];
		}
		
		if (type === VEHICLE_TYPES.HEAVY) {
		  if (vehicule_poids_lourds === 'Tracteur') {
			return COMMON_OPTIONS;
		  }
		  return [...COMMON_OPTIONS, 'Bachée'];
		}
		
		return [];
	  };
	

	useEffect(() => {
		setVehicles(initialVehicles)
	}, [initialVehicles.length])

	const type = watch('type');
	const vehicule_leger = watch('vehicule_leger');
	const vehicule_poids_lourds = watch('vehicule_poids_lourds');
	const handleOptionChange = (e) => {
		const selectedOption = e.target.value;
	  
		// Si l'option sélectionnée est "Bachée" ou "Frigorifique"
		if (selectedOption === "Bachée" || selectedOption === "Frigorifique") {
		  // Désélectionner l'autre option si elle est sélectionnée
		  const otherOption = selectedOption === "Bachée" ? "Frigorifique" : "Bachée";
		  const otherOptionCheckbox = document.querySelector(`[value="${otherOption}"]`);
		  if (otherOptionCheckbox) {
			otherOptionCheckbox.checked = false;
		  }
		}
	  };



	

	return (
		<FormContentSection>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<FormSectionTitle>Véhicules</FormSectionTitle>
					<span>
						Veuillez indiquer le type et la quantité de véhicules
						que vous exploiter
					</span>
				</div>
				{!disableAdd && (
					<button
						type='button'
						onClick={(e) => {
							setModalIsOpen(true)
							e.stopPropagation()
						}}
						style={{
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							fontFamily: 'Montserrat',
							padding: '0 2rem',
							borderRadius: 6,
							fontWeight: 'bold',
							
						}}
					>
						Ajouter véhicule
					</button>
				)}
			</div>
			<FormSectionCard>
			<table
		    cellPadding={0}
		    cellSpacing={0}
		    style={{
			width: '100%',
			textAlign: 'left',
			borderRadius: 20,
			overflow: 'hidden',
			border: 'none',
		    }}
	       >
		<thead>
			<tr
				style={{
					paddingBottom: 10,
				}}
			>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Détails
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Options
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Date de mise en circulation
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Immatriculation
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Location
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Carte grise
				</th>
				
			</tr>
		</thead>

		<tbody>
			{vehicles.map((v, index) => (
				<tr
					key={String(index)}
					style={{
						background: 'white',
					}}
				>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.type == 'Poids lourds' &&
							Array.isArray(v?.vehicule_poids_lourds) ? v?.vehicule_poids_lourds?.join(', ') : v?.vehicule_poids_lourds}
						{v?.type == 'Véhicule léger' &&
							v?.vehicule_leger}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.type == 'Poids lourds' &&
							Array.isArray(v?.options_poids_lourds) ? v?.options_poids_lourds?.join(', ') : v?.options_poids_lourds}
						{v?.type == 'Véhicule léger' &&
							Array.isArray(v?.options_vehicule_leger) ? v?.options_vehicule_leger?.join(', ') : v?.options_vehicule_leger}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.date_mise_circulation}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.immatriculation}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.location ? 'Oui' :'Non'} 
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
					<span
                    style={{
                      cursor: v?.carte_grise?.path ? 'pointer' : 'default', 
                    }}
                    onClick={() => {
                    if (v?.carte_grise?.path) {
                     window.open(`${BASE_URL}/${v?.carte_grise?.path}`);
                    }
                    }}
                    >
                   {v?.carte_grise?.name || 'Aucun document'} 
                   </span>
					

						
						
					</td>
					<td
						style={{
							padding: '1rem',
							display: 'flex',
							flexDirection: 'row',

						}}
					>
						<div
						        onClick={() => handleEdit(index)}
										style={{
											cursor: 'pointer',
										}}
									>
										<BiEdit size={24} style={{color: '#51C7F2',}}/>
										
						</div>
						<div
							onClick={() => handleDelete(index)}
							style={{
											cursor: 'pointer',
										}}
									>
										<MdDelete size={24} style={{color: '#d9534f',}}/>
										
						</div>
						
					
					</td>
				</tr>
			))}
		</tbody>
	        </table>
			</FormSectionCard>

			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
						zIndex: 101,
					},
					content: {
						top: 'calc(50% + 60px)', 
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
						paddin: '4rem',
						height:"450px",
						width:"70%",
						zIndex: 102,
					},
				}}
				contentLabel="Ajouter un véhicule"
				    portalClassName="modal-portal"
				>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						fontSize: 15,
						textAlign: 'left',
						color: '#838EAB',
					}}
				>
					<div
						style={{
							color: '#51C7F2',
							fontSize: 30,
							marginBottom: '1rem',
							fontWeight: 'bold',
							fontFamily: 'Montserrat',
							display: 'flex',
							alignItems: 'center',
						    justifyContent: 'center',
							
							
						}}
						
					>
					{editIndex !== null ? 'Modifier un véhicule' : 'Ajouter un véhicule'} 

					</div>

					<form onSubmit={handleSubmit()}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								marginBottom: '1.5rem',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Type de véhicules
							</span>
							<div
								style={{
									display: 'flex',
										flexDirection: 'row',
								}}
							>
							{Object.values(VEHICLE_TYPES).map((vehicleType) => (
                            <div key={vehicleType} style={{ display: 'flex', alignItems: 'center',width: '240px'}}>
                            <input
                             type="radio"
							 style={{
								marginRight: '16px'
							 }}
                            
                             {...register('type', { required: true })}
                             value={vehicleType}
                            />
                             <span>{vehicleType}</span>
							 <img
                               src={IMAGES[vehicleType]}
                               alt={vehicleType}
                               style={{
                                width: 40,
                                marginLeft: '16px',
                               }}
                              />
                            </div>
                            ))}	
							</div>
							{errors.type && (
                                  <div style={{ color: 'red',marginTop:".5rem"}}>
                                  Veuillez sélectionner le type de véhicules
                                 </div>
                            )}
						</div>

                       {/* Vehicle Selection */}
					   {type === VEHICLE_TYPES.LIGHT && (
						 <div style={{ display: 'grid',gridTemplateColumns: 'repeat(2, 1fr)',gap: '16px',marginBottom:"1rem"}}>
						 {LIGHT_VEHICLES.map((vehicle) => (
						   <VehicleCard
							 key={vehicle.id}
							 vehicle={vehicle}
							 selected={vehicule_leger === vehicle.name}
							 onSelect={() => setValue('vehicule_leger', vehicle.name)}
							 register={register}
							 name="vehicule_leger"
						   />
						 ))}
						 {errors.vehicule_leger && (<div style={{ color: 'red' }}>Veuillez choisir le type de véhicule détaillé</div>)}
					   </div>
					   )}
					   {type === VEHICLE_TYPES.HEAVY && (
                        <div  style={{ display: 'grid',gridTemplateColumns: 'repeat(2, 1fr)',gap: '16px'}}>
                         {HEAVY_VEHICLES.map((vehicle) => (
                         <VehicleCard
                          key={vehicle.id}
                          vehicle={vehicle}
                          selected={vehicule_poids_lourds === vehicle.name}
                          onSelect={() => setValue('vehicule_poids_lourds', vehicle.name)}
                          register={register}
                           name="vehicule_poids_lourds"
                        />
                          ))}
						  {errors.vehicule_poids_lourds && (<div style={{ color: 'red' }}>Veuillez choisir le type de véhicule détaillé.</div>)}
                        </div>
                       )}

					    {/* Vehicle Options */}
                        {type && (
						<div>
                        <VehicleOptions
                        title={`Options ${type === VEHICLE_TYPES.LIGHT ? 'véhicule léger' : 'poids lourds'}`}
                        options={getVehicleOptions()}
                        register={register}
                        name={type === VEHICLE_TYPES.LIGHT ? 'options_vehicule_leger' : 'options_poids_lourds'}
                        required={true}
                        onOptionChange={handleOptionChange}
                       />
					   	{(errors.options_vehicule_leger || errors?.options_poids_lourds ) && (<div style={{ color: 'red' }}>{errors?.options_poids_lourds?.message || errors?.options_vehicule_leger?.message}</div>)}

					   </div>
                       )}
						
                       
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1.5rem',

							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									marginTop:"1rem",
									color: 'black',
									fontSize: 14,
								}}
							>
                            Date de mise en circulation
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
								}}
							>
						   <Controller
                              name="date_mise_circulation"
                              control={control}
                              defaultValue={null}
                              rules={{
                               required: 'Veuillez sélectionner la date de mise en circulation',
                              }}
                              render={({ field }) => (
                              <Flatpickr
                               {...field}
                               className="date-input"
                                options={{
                                 mode: 'single',
                                 locale: French,
                                 dateFormat: 'd/m/Y',
                                 maxDate: new Date(),
                               }}
                               onChange={([date]) => {
                               const formattedDate = moment(date).format('DD/MM/YYYY');
                               field.onChange(formattedDate);
                               }}
                               ref={(ref) => field.ref(ref?.input)}
                              />
                            )}
                            />
						   
							</div>
							{errors?.date_mise_circulation && (
                          <div style={{ color: 'red', marginTop: '0.5rem' }}>
                           {errors?.date_mise_circulation?.message}
                         </div>
                         )}
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1.5rem',
					  
							}}


						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
                            Immatriculation de voiture
							</span>
							
						    <CustomInput
                             type='text'
                             placeholder='AA-123-AA'  
                             {...register('immatriculation', {
                              required: "Veuillez entrer l'immatriculation du véhicule",  
                              })}
                            />
							{errors?.immatriculation && (
								<div style={{ color: 'red', marginTop: '0.5rem' }}>
								 {errors?.immatriculation?.message}
							   </div>
							)}
						
						</div>
						
						<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '100%',
										fontFamily: 'Montserrat',
										marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Carte  grise valide (PDF)
										</span>
										
									</div>
									<Controller
                                     name='carte_grise'
                                     control={control}
                                     rules={{
										required: !watch('location') ? 'Veuillez télécharger la carte grise' : false, 
									}}
                                     render={({ field }) => (
									<>
                                    <UploadFile
                                    name={field.name}
                                    accept={'.pdf'}
                                    setValue={setValue}
                                     {...field}
                                    />
									{watch('carte_grise')?.name}
									{errors?.carte_grise && (
                                    <span style={{ color: 'red', fontSize:'14px'  }}>
                                    {errors?.carte_grise?.message}
                                   </span>
                                    )}
									</>
                                   )}
                                   />
								   
									
									
						</div>

						<div
                         style={{
                        
							marginBottom: '1.5rem',
							display: 'flex',
							alignItems: 'center',
							fontSize: '14px',                         }}
                        >
                       <input
                        type='checkbox'
                        style={{ marginRight: '1rem' }}
                        {...register('location',)}
						defaultChecked={false}
                         />
                        <span style={{ marginRight: '0.5rem' }}>Véhicule de location</span>
                       </div>
                      
					  
                        
					  
					 
					  
                       
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '1rem',
							}}
						>
							<div style={{display: 'flex',flexDirection: 'row',}}>
							<button
								type='button'
								onClick={handleSubmit(handleSubmitForm)}
								style={{
									background: '#50F5A9',
									width: '150px',
									height: '50px',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									borderRadius: 7,
									marginTop: 5,
									fontFamily: 'Montserrat',
									marginRight: 10,
									fontWeight: 'bold',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{editIndex !== null ? 'Modifier' : 'Ajouter'} 
							</button>
							<button 
							onClick={()=>{
								setEditIndex(null);
								reset({type: '',
									date_mise_circulation: '',
									immatriculation: '',
									carte_grise: null,
									location: false,})
								setModalIsOpen(false)
							}}
							style={{
								width: '150px',
								height: '50px',
								background: 'red',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								marginTop: 5,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}>
								Annuler
							</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</FormContentSection>
	)
}

export default AddVehicleRegister
