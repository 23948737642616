import React from "react";
import { useSelector,useDispatch,  } from 'react-redux';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LayoutSwitcher from "../../Components/LayoutSwitcher/LayoutSwitcher";
import { logoutAction } from "../../redux/auth";
import { useHistory } from "react-router";
function Unauthorized() {
    const dispatch = useDispatch()
    const history=useHistory()
    const user = useSelector((state) => state.auth.user);
    
    return(
        <LayoutSwitcher layout={user?.role}>
        <div 
           style={{
                minHeight: '60vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
               backgroundColor:' #f9f9f9', 
               fontFamily: 'Montserrat',

        }}>
        <div 
            style={{
             textAlign: 'center'
        }}>
            <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1rem'
            }}>
                <FaExclamationTriangle 
                        style={{
                            color: 'red', 
                            fontSize: '3rem', 
                            }} 
                />

            </div>
            <h2 style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#1f2937',
                  marginBottom: '0.5rem'
            }}>
                Accès non autorisé
            </h2>
            <p  style={{
                 color:'#4b5563' ,
                 marginBottom: '1.5rem'
            }}>
             { "Vous n'avez pas les permissions nécessaires pour accéder à cette page."}
            </p>
            <span
          onClick={() => {
            dispatch(logoutAction());
            history.push('/'); 
          }}
          style={{ 
            color: '#25A6E4', 
            fontWeight: '500', 
            textDecoration: 'none', 
            cursor: 'pointer' 
          }}
        >
          Retour à l'accueil
        </span>

        </div>
        </div>  
        </LayoutSwitcher>
    )
}

export default Unauthorized