import PoidsLours from  "../assets/img/poid_lourd@2x.png"
import PoidsLeger from '../assets/img/vehicule_leger@2x.png'

// Types de véhicules
export const VEHICLE_TYPES = {
    LIGHT: 'Véhicule léger',
    HEAVY: 'Poids lourds'
  };
 
export  const  IMAGES = {
    [VEHICLE_TYPES.LIGHT]: PoidsLeger,
    [VEHICLE_TYPES.HEAVY]: PoidsLours,
  };
  

  
  // Options communes
  export const COMMON_OPTIONS = ['Frigorifique', 'Tall', 'Hayon'];
  
  // Véhicules légers
  export const LIGHT_VEHICLES = [
    {
      id: 'break',
      name: 'Break',
      image: require('../assets/img/CaptureBreak.png'),
      specs: {
        volume: '6M3',
        length: '3750mm',
        width: '1956mm',
        height: '1320mm',
        payload: '1097kg',
        pallets: '2 pal europe'
      }
    },
    {
      id: 'fourgon12',
      name: 'Fourgon 12m³',
      image: require('../assets/img/captureF12.png'),
      specs: {
        volume: '12M3',
        length: '3030mm',
        width: '2070mm',
        height: '1320mm',
        payload: '1244kg',
        pallets: '5 pal europe'
      }
    },
    {
      id: 'fourgon14',
      name: 'Fourgon 14m³',
      image: require('../assets/img/fourgon12m.png'),
      specs: {
        volume: '14M3',
        length: '3680mm',
        width: '2070mm',
        height: '1820mm',
        payload: '1353kg',
        pallets: '6 pal europe'
      }
    },
    {
      id: 'camion20',
      name: 'Camion 20m³',
      image: require('../assets/img/vehicule_camion.png'),
      specs: {
        volume: '20M3',
        length: '4320mm',
        width: '2070mm',
        height: '2100mm',
        payload: '1200kg',
        pallets: '7 pal europe'
      }
    }
  ];
  
  // Poids lourds
  export const HEAVY_VEHICLES = [
    {
      id: '7.5t',
      name: '7,5 Tonnes',
      image: require('../assets/img/7.5.png'),
      specs: {
        volume: '30M3',
        length: '6575mm',
        width: '2045mm',
        height: '2400mm',
        payload: '2470kg',
        pallets: '16 pal europe'
      }
    },
    {
      id: '12t',
      name: '12 Tonnes',
      image: require('../assets/img/tonnes12.png'),
      specs: {
        volume: '45M3',
        length: '7540mm',
        width: '2480mm',
        height: '2500mm',
        payload: '5600kg',
        pallets: '19 pal europe'
      }
    },
    {
      id: '19t',
      name: '19 Tonnes',
      image: require('../assets/img/tonnes19.png'),
      specs: {
        volume: '58M3',
        length: '9100mm',
        width: '2470mm',
        height: '2490mm',
        payload: '9200kg',
        pallets: '22 pal europe'
      }
    },
    {
      id: 'semi',
      name: 'Semi',
      image: require('../assets/img/Capturesemi.png'),
      specs: {
        volume: '86M3',
        length: '13500mm',
        width: '2470mm',
        height: '2590mm',
        payload: '27000kg',
        pallets: '33 pal europe'
      }
    },
    {
        id: 'tracteur+semi',
        name: 'Tracteur+semi',
        image: require('../assets/img/semi.png'),
        specs: {
          volume: '6M3',
          length: '2.45M',
          width: '1.50M',
          height: '1.60M',
          payload: '1000kg',
          pallets: '32 pal europe'
        }
    },
    { 

        id: 'tracteur',
        name: 'Tracteur',
        image: require('../assets/img/tracteur-actos.png'),
        specs: {
          volume: '6M3',
          length: '2.45M',
          width: '1.50M',
          height: '1.60M',
          payload: '1000kg',
          pallets: 'Non spécifié'
        }
    }
  ];
  