import { atom } from 'jotai'
export const typeCommandeAtom = atom('course-a-course')
export const totalDistance = atom(0)
export const typeTransportAtom = atom(null)
export const temporisationAtom = atom(null)
export const typeEtageAtom = atom(null)
export const nombreEtagesAtom = atom(null)
export const rippeursAtom = atom(null)
export const dangerAtom=atom(null)
export const rippeurHoursAtom = atom(null)
export const optionsPoidsLourdsAtom = atom(null)
export const optionsVehiculeLegerAtom = atom(null)
export const priceMissionsAtom=atom([])





export function computePriceCourseACourse(
	tarif,
	typeCommande,
	typeTransport,
	distance,
	temporisation,
	nombreEtages,
	typeEtage,
	rippeurs,
	rippeurHours,
	optionsVehiculeLeger,
	optionsPoidsLourds,
	danger,
) {
	let _price = 0
		switch (typeTransport) {
			case 'Break': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.BREAK.PLANIFIE
						: tarif.BREAK.IMMEDIAT

				_price += distanceKM * perKM
				
                _price +=tarif.BREAK.Tarif_DE_PRISE_EN_CHARGE
				
				if (nombreEtages === "1-10") {
					_price += tarif?.BREAK?.TARIF_PAR_1_10_ETAGE;
				} else if (nombreEtages === "11-20") {
						_price += tarif?.BREAK?.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtages === "21-30") {
					_price += tarif?.BREAK?.TARIF_PAR_21_30_ETAGE;

				}
				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.BREAK.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsVehiculeLeger &&
					optionsVehiculeLeger.includes('Frigorifique')
				) {
					_price +=(tarif.BREAK.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM) )*
						(tarif.BREAK.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.BREAK.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.BREAK.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			case 'Fourgon 12m³': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.F12.PLANIFIE
						: tarif.F12.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.F12.Tarif_DE_PRISE_EN_CHARGE
				if (nombreEtages === "1-10") {
					_price += tarif?.F12?.TARIF_PAR_1_10_ETAGE;

				} else if (nombreEtages === "11-20") {
					_price += tarif?.F12?.TARIF_PAR_11_20_ETAGE;

				} else if (nombreEtages === "21-30") {
					_price += tarif?.F12?.TARIF_PAR_21_30_ETAGE;

				} 

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.F12.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsVehiculeLeger &&
					optionsVehiculeLeger.includes('Frigorifique')
				) {
					_price +=(tarif.F12.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						
						(tarif.F12.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.F12.FRIGORIFIQUE_EN_POURCENTAGE+(
						distanceKM *
						perKM ))*
						(tarif.F12.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			case 'Fourgon 14m³': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.F14.PLANIFIE
						: tarif.F14.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.F14.Tarif_DE_PRISE_EN_CHARGE
				if (nombreEtages === "1-10") {
					_price += tarif.F14.TARIF_PAR_1_10_ETAGE;

				} else if (nombreEtages === "11-20") {
					_price += tarif.F14.TARIF_PAR_11_20_ETAGE;

				} else if (nombreEtages === "21-30") {
					_price += tarif.F14.TARIF_PAR_21_30_ETAGE;

				} 

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.F14.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsVehiculeLeger &&
					optionsVehiculeLeger.includes('Frigorifique')
				) {
					_price +=(tarif.F14.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM)) *
						(tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger){
					_price +=(tarif.F14.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			case 'Camion 20m³': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.F20.PLANIFIE
						: tarif.F20.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.F20.Tarif_DE_PRISE_EN_CHARGE
				if (nombreEtages === "1-10") {
					_price += tarif.F20.TARIF_PAR_1_10_ETAGE;

				} else if (nombreEtages === "11-20") {
						_price += tarif.F20.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtages === "21-30") {
						_price += tarif.F20.TARIF_PAR_21_30_ETAGE;
					
				} 

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.F20.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsVehiculeLeger &&
					optionsVehiculeLeger.includes('Frigorifique')
				) {
					_price +=(tarif.F20.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM)) *
						(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.F20.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			case '7,5 Tonnes': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.V7_5T.PLANIFIE
						: tarif.V7_5T.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.V7_5T.Tarif_DE_PRISE_EN_CHARGE
				if (nombreEtages === "1-10") {
					_price += tarif.V7_5T.TARIF_PAR_1_10_ETAGE
				
			} else if (nombreEtages === "11-20") {
					_price += tarif.V7_5T.TARIF_PAR_11_20_ETAGE;
				
			} else if (nombreEtages === "21-30") {
				
					_price += tarif.V7_5T.TARIF_PAR_21_30_ETAGE;
				
			} 

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.V7_5T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsPoidsLourds &&
					optionsPoidsLourds.includes('Frigorifique')
				) {
					_price +=(tarif.V7_5T.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.V7_5T.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				

				break
			}

			case '12 Tonnes': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.V12T.PLANIFIE
						: tarif.V12T.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.V12T.Tarif_DE_PRISE_EN_CHARGE
               
				if (nombreEtages === "1-10") {
				
					_price += tarif.V12T.TARIF_PAR_1_10_ETAGE;
			
			} else if (nombreEtages === "11-20") {
				
					_price += tarif.V12T.TARIF_PAR_11_20_ETAGE;
				
			} else if (nombreEtages=== "21-30") {
			
					_price += tarif.V12T.TARIF_PAR_21_30_ETAGE;
				
			} 
				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.V12T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsPoidsLourds &&
					optionsPoidsLourds.includes('Frigorifique')
				) {
					_price +=(tarif.V12T.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.V12T.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM)) *
						(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			case '19 Tonnes': {
				const distanceKM = distance / 1000
			const perKM =
					temporisation === 'planifie'
						? tarif.V19T.PLANIFIE
						: tarif.V19T.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.V19T.Tarif_DE_PRISE_EN_CHARGE
                 
				if (nombreEtages === "1-10") {
					
					_price += tarif.V19T.TARIF_PAR_1_10_ETAGE;
				
			} else if (nombreEtages === "11-20") {
			
					_price += tarif.V19T.TARIF_PAR_11_20_ETAGE;
				
			} else if (nombreEtages === "21-30") {
				
					_price += tarif.V19T.TARIF_PAR_21_30_ETAGE;
				
			} 
		
				

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.V19T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsPoidsLourds &&
					optionsPoidsLourds.includes('Frigorifique')
				) {
					_price +=(tarif.V19T.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM)) *
						(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
                if(danger ){
					_price +=(tarif.V19T.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM)) *
						(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				break
			}

			case 'Tracteur': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.TRACTEUR.PLANIFIE
						: tarif.TRACTEUR.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.TRACTEUR.Tarif_DE_PRISE_EN_CHARGE
				if (nombreEtages === "1-10") {
					
					_price += tarif.TRACTEUR.TARIF_PAR_1_10_ETAGE;
				
			    } else if (nombreEtages === "11-20") {
				
					_price += tarif.TRACTEUR.TARIF_PAR_11_20_ETAGE;
				
			    } else if (nombreEtages === "21-30") {
					_price += tarif.TRACTEUR.TARIF_PAR_21_30_ETAGE;
				
			} 
				

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.TRACTEUR.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsPoidsLourds &&
					optionsPoidsLourds.includes('Frigorifique')
				) {
					_price +=(tarif.TRACTEUR.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM)) *
						(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.TRACTEUR.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			

			case 'Tracteur+semi': {
				const distanceKM = distance / 1000
				const perKM =
					temporisation === 'planifie'
						? tarif.TRACTEUR_SEMI.PLANIFIE
						: tarif.TRACTEUR_SEMI.IMMEDIAT

				_price += distanceKM * perKM
				_price +=tarif.TRACTEUR_SEMI.Tarif_DE_PRISE_EN_CHARGE
               
				if (nombreEtages === "1-10") {
					_price += tarif.TRACTEUR_SEMI.TARIF_PAR_1_10_ETAGE;
				
			} else if (nombreEtages === "11-20") {
				
					_price += tarif.TRACTEUR_SEMI.TARIF_PAR_11_20_ETAGE;
				
			} else if (nombreEtages === "21-30") {
					_price += tarif.TRACTEUR_SEMI.TARIF_PAR_21_30_ETAGE;
				
			} 
				

				if (rippeurs && rippeurHours) {
					_price +=
						Number(rippeurHours) *
						Number(rippeurs) *
						tarif.TRACTEUR_SEMI
							.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				if (
					optionsPoidsLourds &&
					optionsPoidsLourds.includes('Frigorifique')
				) {
					_price +=(tarif.TRACTEUR_SEMI.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}
				if(danger ){
					_price +=(tarif.TRACTEUR_SEMI.Tarif_DE_PRISE_EN_CHARGE+(
						distanceKM *
						perKM ))*
						(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				break
			}

			default:
				break
		}
		return _price
	

	
}
export function computePriceMiseADispo(
	tarif,
	totalMissions
) {     			
			let _price = 0
			const distanceKM =  Number(totalMissions?.kilo_jour)
			const heures=Number(totalMissions?.heures)
			const heureSupp=Number(totalMissions?.heures)-7
			const rippeursValue = totalMissions?.nbrRippeur === "" ? 0 : Number(totalMissions?.nbrRippeur);
            const nombreEtagesValue=totalMissions?.nombreEtage
            const typeTransportValue=totalMissions?.typeTransport	
            const optionsVehiculeLegerValue=totalMissions?.optionsVehiculeLeger
			const optionsPoidsLourdsValue=totalMissions?.optionsPoidsLourds
			const dangerValue=totalMissions?.danger
		   
		    switch (typeTransportValue) {
			case 'Break': {
				if (distanceKM === 200) {
					_price =tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true"){
						_price +=
						tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
						(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						
					}
				} else {
				
					_price =
						tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif?.BREAK?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.BREAK?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.BREAK?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}
				if (nombreEtagesValue === "1-10") {
					_price += tarif?.BREAK?.TARIF_PAR_1_10_ETAGE;
				} else if (nombreEtagesValue === "11-20") {
						_price += tarif?.BREAK?.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
					_price += tarif?.BREAK?.TARIF_PAR_21_30_ETAGE;

				}
				if (rippeursValue) {
					_price +=
						heures *
						rippeursValue *
						tarif?.BREAK?.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					_price +=
					heureSupp * tarif?.BREAK?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
					
				}
				
               
				break
			}

			case 'Fourgon 12m³': {
				if (distanceKM <= 200) {
					_price = tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue  && dangerValue==="true"){
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif?.F12?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.F12?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue  && dangerValue==="true"){
								_price +=
								(tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.F12?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}
                if (nombreEtagesValue === "1-10") {
					_price += tarif?.F12?.TARIF_PAR_1_10_ETAGE;

				} else if (nombreEtagesValue === "11-20") {
					_price += tarif?.F12?.TARIF_PAR_11_20_ETAGE;

				} else if (nombreEtagesValue === "21-30") {
					_price += tarif?.F12?.TARIF_PAR_21_30_ETAGE;

				} 



				

                if (rippeursValue ) {
					_price +=
					heures*
						rippeursValue *
						tarif?.F12?.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					 _price +=
					 heureSupp * tarif?.F12?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				break
			}

			case 'Fourgon 14m³': {

				if (distanceKM <= 200) {
					_price = tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true" ){
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				if (nombreEtagesValue === "1-10") {
					_price += tarif.F14.TARIF_PAR_1_10_ETAGE;

				} else if (nombreEtagesValue === "11-20") {
					_price += tarif.F14.TARIF_PAR_11_20_ETAGE;

				} else if (nombreEtagesValue === "21-30") {
					_price += tarif.F14.TARIF_PAR_21_30_ETAGE;

				} 
				
				if (rippeursValue ) {
					_price +=
					    heures *
						rippeursValue *
						tarif.F14.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					_price +=
					heureSupp * tarif.F14.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			case 'Camion 20m³': {

				if (distanceKM <= 200) {
					_price = tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true" ){
						_price +=
						tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				if (nombreEtagesValue === "1-10") {
					_price += tarif.F20.TARIF_PAR_1_10_ETAGE;

				} else if (nombreEtagesValue === "11-20") {
						_price += tarif.F20.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
						_price += tarif.F20.TARIF_PAR_21_30_ETAGE;
					
				} 

				if (rippeursValue ) {
					_price +=
						rippeursValue *
						heures *
						tarif.F20.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				
				if (heureSupp && heureSupp > 0) {
					 _price +=
					 heureSupp * tarif.F20.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			case '7,5 Tonnes': {

				if (distanceKM <= 200) {
					_price =
						tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
						if (
							optionsPoidsLourdsValue &&
							optionsPoidsLourdsValue.includes('Frigorifique')
						) {
							_price +=
							tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
						if(dangerValue && dangerValue==="true"){
							_price +=
							tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
				} else {
					_price =
						tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true" ){
								_price +=
								(tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				if (nombreEtagesValue === "1-10") {
						_price += tarif.V7_5T.TARIF_PAR_1_10_ETAGE
					
				} else if (nombreEtagesValue === "11-20") {
						_price += tarif.V7_5T.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
					
						_price += tarif.V7_5T.TARIF_PAR_21_30_ETAGE;
					
				} 


               
				if (rippeursValue ) {
					_price +=
					    heures*
						rippeursValue *
						tarif.V7_5T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					_price +=
					heureSupp * tarif.V7_5T.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}


				break
			}

			case '12 Tonnes': {

				if (distanceKM <= 200) {
					_price = tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsPoidsLourdsValue &&
						optionsPoidsLourdsValue.includes('Frigorifique')
					) {
						_price +=
						tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true" ){
						_price +=
						tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue  && dangerValue==="true"){
								_price +=
								(tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				
				if (nombreEtagesValue === "1-10") {
				
						_price += tarif.V12T.TARIF_PAR_1_10_ETAGE;
				
				} else if (nombreEtagesValue === "11-20") {
					
						_price += tarif.V12T.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
				
						_price += tarif.V12T.TARIF_PAR_21_30_ETAGE;
					
				} 
			
				if (rippeursValue) {
					_price +=
						rippeursValue *
						heures *
						tarif.V12T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					 _price +=
					 heureSupp * tarif.V12T.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			case '19 Tonnes': {

				if (distanceKM <= 200) {
					_price = tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsPoidsLourdsValue &&
						optionsPoidsLourdsValue.includes('Frigorifique')
					) {
						_price +=
						tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true" ){
						_price +=
						tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue  && dangerValue==="true"){
								_price +=
								(tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				
				if (nombreEtagesValue === "1-10") {
					
						_price += tarif.V19T.TARIF_PAR_1_10_ETAGE;
					
				} else if (nombreEtagesValue === "11-20") {
				
						_price += tarif.V19T.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
					
						_price += tarif.V19T.TARIF_PAR_21_30_ETAGE;
					
				} 
			
				
                if (rippeursValue ) {
					_price +=
						rippeursValue *
						heures *
						tarif.V19T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					 _price +=
					 heureSupp * tarif.V19T.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				break
			}

			case 'Tracteur': {

				if (distanceKM <= 200) {
					_price =
						tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
						if (
							optionsPoidsLourdsValue &&
							optionsPoidsLourdsValue.includes('Frigorifique')
						) {
							_price +=
							tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
						if(dangerValue && dangerValue==="true" ){
							_price +=
							tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
				} else {
					_price =
						tarif.TRACTEUR
							.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				if (nombreEtagesValue === "1-10") {
					
						_price += tarif.TRACTEUR.TARIF_PAR_1_10_ETAGE;
					
				} else if (nombreEtagesValue === "11-20") {
					
						_price += tarif.TRACTEUR.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
						_price += tarif.TRACTEUR.TARIF_PAR_21_30_ETAGE;
					
				} 

				

			
				if (rippeursValue ) {
					_price +=
						rippeursValue *
						heures*
						tarif.TRACTEUR.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					_price +=
					heureSupp *
						tarif.TRACTEUR.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}


				break
			}

			case 'Semi': {
				_price=tarif.SEMI.TARIF_SEMI_PAR_24H
                if (
					optionsPoidsLourdsValue &&
					optionsPoidsLourdsValue.includes('Frigorifique')
				) {
					_price += _price * (tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				if (rippeursValue ) {
					_price +=tarif.SEMI.TARIF_SEMI_PAR_24H*(
			        rippeursValue *heures*tarif.SEMI.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS)
				}

				break
			}

			case 'Tracteur+semi': {

				if (distanceKM <= 200) {
					_price =
						tarif.TRACTEUR_SEMI
							.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue  && dangerValue==="true"){
								_price +=
								tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				} else {
					_price =
						tarif.TRACTEUR_SEMI
							.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true" ){
								_price +=
								(tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}	
				}

				if (nombreEtagesValue === "1-10") {
						_price += tarif.TRACTEUR_SEMI.TARIF_PAR_1_10_ETAGE;
					
				} else if (nombreEtagesValue === "11-20") {
					
						_price += tarif.TRACTEUR_SEMI.TARIF_PAR_11_20_ETAGE;
					
				} else if (nombreEtagesValue === "21-30") {
						_price += tarif.TRACTEUR_SEMI.TARIF_PAR_21_30_ETAGE;
					
				} 
			
				if (rippeursValue ) {
					_price +=
						rippeursValue *
						heures *
						tarif.TRACTEUR_SEMI
							.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if (heureSupp && heureSupp > 0) {
					 _price +=
					 heureSupp *
						tarif.TRACTEUR_SEMI.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			default:
				break
		    }
		  
			return _price
}
export function computePriceForfait(
	tarif,
	mission,
	forfait,
	totalHours
) {     			
			let _price = 0
			const distanceKM =  Number(forfait)
			const existeForfaitKilometrage=mission?.forfaits?.some(forfait => forfait.type === 'kilometrage' || forfait.type==='kilometrage_etage');
			const existeForfaitHeure=mission?.forfaits?.some(forfait=> forfait.type==='heure' || forfait.type==='heure_etage')
			const rippeursValue =mission?.nombre_rippeur  ?  Number(mission?.nombre_rippeur): 0
			const rippeurHoursValue = existeForfaitKilometrage ? 0 :12-totalHours
			const typeTransportValue=mission?.type_transport_details || mission?.vehicule_poids_lourds	
            const optionsVehiculeLegerValue=mission?.options_vehicule_leger
			const optionsPoidsLourdsValue=mission?.options_poids_lourds
			const dangerValue=mission?.danger
		    switch (typeTransportValue) {
			case 'Break': {
				if (distanceKM === 200) {
					_price =tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true"){
						_price +=
						tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
						(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						
					}
				} else {
				
					_price =
						tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif?.BREAK?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.BREAK?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif?.BREAK?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.BREAK?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.BREAK?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}
				
				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeurHoursValue) *
						Number(rippeursValue) *
						tarif?.BREAK?.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
				const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
						if(['heure','heure_etage'].includes(forfait?.type)){
						const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
						  return total+(hours*60)+minutes
						}
						return total
					},0)/ 60 || 0;
					_price-=heureChaufffeur*tarif?.BREAK?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				
				
               
				break
			}

			case 'Fourgon 12m³': {
				if (distanceKM <= 200) {
					_price = tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue  && dangerValue==="true"){
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif?.F12?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.F12?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif?.F12?.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}
                



				

                if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeurHoursValue) *
						Number(rippeursValue) *
						tarif?.F12?.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.F12?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				
				break
			}

			case 'Fourgon 14m³': {

				if (distanceKM <= 200) {
					_price = tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true"){
						_price +=
						tarif?.F12?.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif?.F12?.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				
				
				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeurHoursValue) *
						Number(rippeursValue) *
						tarif.F14.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.F14?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			case 'Camion 20m³': {

				if (distanceKM <= 200) {
					_price = tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsVehiculeLegerValue &&
						optionsVehiculeLegerValue.includes('Frigorifique')
					) {
						_price +=
						tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue  && dangerValue==="true"){
						_price +=
						tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsVehiculeLegerValue &&
								optionsVehiculeLegerValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				

				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeursValue) *
						Number(rippeurHoursValue) *
						tarif.F20.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.F20?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				

				break
			}

			case '7,5 Tonnes': {

				if (distanceKM <= 200) {
					_price =
						tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
						if (
							optionsPoidsLourdsValue &&
							optionsPoidsLourdsValue.includes('Frigorifique')
						) {
							_price +=
							tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
						if(dangerValue && dangerValue==="true"){
							_price +=
							tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
				} else {
					_price =
						tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				


               
				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeurHoursValue) *
						Number(rippeursValue) *
						tarif.V7_5T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.V7_5T?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}


				break
			}

			case '12 Tonnes': {

				if (distanceKM <= 200) {
					_price = tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsPoidsLourdsValue &&
						optionsPoidsLourdsValue.includes('Frigorifique')
					) {
						_price +=
						tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true"){
						_price +=
						tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true" ){
								_price +=
								(tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}

				
				
			
				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeursValue) *
						Number(rippeurHoursValue) *
						tarif.V12T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.V12T?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				

				break
			}

			case '19 Tonnes': {

				if (distanceKM <= 200) {
					_price = tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
					if (
						optionsPoidsLourdsValue &&
						optionsPoidsLourdsValue.includes('Frigorifique')
					) {
						_price +=
						tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
					if(dangerValue && dangerValue==="true"){
						_price +=
						tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
							(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
					}
				} else {
					_price =
						tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}
	
                if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeursValue) *
						Number(rippeurHoursValue) *
						tarif.V19T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.V19T?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				
				break
			}

			case 'Tracteur': {

				if (distanceKM <= 200) {
					_price =
						tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
						if (
							optionsPoidsLourdsValue &&
							optionsPoidsLourdsValue.includes('Frigorifique')
						) {
							_price +=
							tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
						if(dangerValue && dangerValue==="true" ){
							_price +=
							tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
								(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
						}
				} else {
					_price =
						tarif.TRACTEUR
							.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true" ){
								_price +=
								(tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				}
			
				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeursValue) *
						Number(rippeurHoursValue) *
						tarif.TRACTEUR.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.TRACTEUR?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				


				break
			}

			case 'Semi': {
				_price=tarif.SEMI.TARIF_SEMI_PAR_24H
                if (
					optionsPoidsLourdsValue &&
					optionsPoidsLourdsValue.includes('Frigorifique')
				) {
					_price += _price * (tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE / 100)
				}

				if (rippeursValue && rippeurHoursValue) {
					_price +=tarif.SEMI.TARIF_SEMI_PAR_24H*(
			        Number(rippeursValue) *Number(rippeurHoursValue) *tarif.SEMI.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS)
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.SEMI?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			case 'Tracteur+semi': {

				if (distanceKM <= 200) {
					_price =
						tarif.TRACTEUR_SEMI
							.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue  && dangerValue==="true"){
								_price +=
								tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
				} else {
					_price =
						tarif.TRACTEUR_SEMI
							.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
						(distanceKM-200) *
							tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM
							if (
								optionsPoidsLourdsValue &&
								optionsPoidsLourdsValue.includes('Frigorifique')
							) {
								_price +=
								(tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}
							if(dangerValue && dangerValue==="true"){
								_price +=
								(tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H +
								(distanceKM-200) *
									tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM)*
									(tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE / 100)
							}	
				}

				
			
				if (rippeursValue && rippeurHoursValue) {
					_price +=
						Number(rippeursValue) *
						Number(rippeurHoursValue) *
						tarif.TRACTEUR_SEMI
							.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				if(existeForfaitHeure){
					const heureChaufffeur=	mission?.forfaits?.reduce((total,forfait)=>{
							if(['heure','heure_etage'].includes(forfait?.type)){
							const [hours,minutes]=forfait?.details?.heures.split(':').map(Number)
							  return total+(hours*60)+minutes
							}
							return total
						},0)/ 60 || 0;
						_price-=heureChaufffeur*tarif?.TRACTEUR_SEMI?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}

				break
			}

			default:
				break
		    }
		    
			return _price
}
export function computePriceEtage(
	tarif,
	mission,
	etage
	
) {     			
			let _price = 0
			const typeTransportValue=mission?.type_transport_details || mission?.vehicule_poids_lourds	
			switch (typeTransportValue) {
				case 'Break': {
					
					if (etage === "1-10") {
						_price = tarif?.BREAK?.TARIF_PAR_1_10_ETAGE;
					} else if (etage === "11-20") {
							_price = tarif?.BREAK?.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
						_price = tarif?.BREAK?.TARIF_PAR_21_30_ETAGE;
	
					}
					
					break
				}
	
				case 'Fourgon 12m³': {
					
					if (etage === "1-10") {
						_price = tarif?.F12?.TARIF_PAR_1_10_ETAGE;
	
					} else if (etage === "11-20") {
						_price = tarif?.F12?.TARIF_PAR_11_20_ETAGE;
	
					} else if (etage === "21-30") {
						_price = tarif?.F12?.TARIF_PAR_21_30_ETAGE;
	
					} 
					break
				}
	
				case 'Fourgon 14m³': {
	
	
					if (etage === "1-10") {
						_price = tarif.F14.TARIF_PAR_1_10_ETAGE;
	
					} else if (etage === "11-20") {
						_price = tarif.F14.TARIF_PAR_11_20_ETAGE;
	
					} else if (etage === "21-30") {
						_price = tarif.F14.TARIF_PAR_21_30_ETAGE;
	
					} 
					
					break
				}
	
				case 'Camion 20m³': {
	
					if (etage === "1-10") {
						_price = tarif.F20.TARIF_PAR_1_10_ETAGE;
	
					} else if (etage === "11-20") {
							_price = tarif.F20.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
							_price = tarif.F20.TARIF_PAR_21_30_ETAGE;
						
					} 
	
					break
				}
	
				case '7,5 Tonnes': {
					if (etage === "1-10") {
							_price = tarif.V7_5T.TARIF_PAR_1_10_ETAGE
						
					} else if (etage === "11-20") {
							_price = tarif.V7_5T.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
						
							_price = tarif.V7_5T.TARIF_PAR_21_30_ETAGE;
						
					} 
	
					break
				}
	
				case '12 Tonnes': {
	
					if (etage === "1-10") {
					
							_price = tarif.V12T.TARIF_PAR_1_10_ETAGE;
					
					} else if (etage === "11-20") {
						
							_price = tarif.V12T.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
					
							_price = tarif.V12T.TARIF_PAR_21_30_ETAGE;
						
					} 
					break
				}
	
				case '19 Tonnes': {
					if (etage === "1-10") {
						
							_price = tarif.V19T.TARIF_PAR_1_10_ETAGE;
						
					} else if (etage === "11-20") {
					
							_price = tarif.V19T.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
						
							_price = tarif.V19T.TARIF_PAR_21_30_ETAGE;
						
					} 
				
					break
				}
	
				case 'Tracteur': {
					if (etage === "1-10") {
						
							_price = tarif.TRACTEUR.TARIF_PAR_1_10_ETAGE;
						
					} else if (etage === "11-20") {
						
							_price = tarif.TRACTEUR.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
							_price = tarif.TRACTEUR.TARIF_PAR_21_30_ETAGE;
						
					} 
		
					break
				}
	
				case 'Semi': {
					
					break
				}
	
				case 'Tracteur+semi': {
					if (etage === "1-10") {
							_price = tarif.TRACTEUR_SEMI.TARIF_PAR_1_10_ETAGE;
						
					} else if (etage === "11-20") {
						
							_price = tarif.TRACTEUR_SEMI.TARIF_PAR_11_20_ETAGE;
						
					} else if (etage === "21-30") {
							_price = tarif.TRACTEUR_SEMI.TARIF_PAR_21_30_ETAGE;
						
					} 
				
					
	
					break
				}
	
				default:
					break
				}
			  
				return _price
		  
		  
}
export function computePriceHeure(
	tarif,
	mission,
	heure
) {     			
			let _price = 0
			const [heures, minutes] = heure?.split(':').map(Number);
            const heureSupp=heures + (minutes / 60);
			const typeTransportValue=mission?.type_transport_details || mission?.vehicule_poids_lourds	
			const rippeursValue =mission?.nombre_rippeur  ?  Number(mission?.nombre_rippeur): 0

		   
		    switch (typeTransportValue) {
			case 'Break': {
				if (heureSupp && heureSupp > 0) {
					_price =
					heureSupp * tarif?.BREAK?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
					
				}
				if (rippeursValue ) {
					_price +=
						heureSupp *
						rippeursValue *
						tarif?.BREAK?.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
			
					}
				break
			}

			case 'Fourgon 12m³': {
				if (heureSupp && heureSupp > 0) {
					 _price =
					 heureSupp * tarif?.F12?.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue) {
					_price +=
					    heureSupp *
						rippeursValue *
						tarif?.F12?.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				break
			}

			case 'Fourgon 14m³': {
				if (heureSupp && heureSupp > 0) {
					_price =
					heureSupp * tarif.F14.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue) {
					_price +=
					    heureSupp *
						rippeursValue *
						tarif.F14.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				break
			}

			case 'Camion 20m³': {
				
				if (heureSupp && heureSupp > 0) {
					 _price =
					 heureSupp * tarif.F20.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				
				if (rippeursValue) {
					_price +=
					    rippeursValue*
						heureSupp *
						tarif.F20.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				break
			}

			case '7,5 Tonnes': {
				
				if (heureSupp && heureSupp > 0) {
					_price =
					heureSupp * tarif.V7_5T.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue ) {
					_price +=
					    heureSupp*
						rippeursValue *
						tarif.V7_5T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				break
			}

			case '12 Tonnes': {
				if (heureSupp && heureSupp > 0) {
					 _price =
					 heureSupp * tarif.V12T.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue) {
					_price +=
					    rippeursValue *
						heureSupp *
						tarif.V12T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				break
			}

			case '19 Tonnes': {
				if (heureSupp && heureSupp > 0) {
					 _price =
					 heureSupp * tarif.V19T.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue ) {
					_price +=
						rippeursValue *
						heureSupp *
						tarif.V19T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}
				break
			}

			case 'Tracteur': {
				
				if (heureSupp && heureSupp > 0) {
					_price =
					heureSupp *
						tarif.TRACTEUR.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue ) {
					_price +=
						rippeursValue *
						heureSupp *
						tarif.TRACTEUR.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}


				break
			}

			case 'Semi': {
				break
			}

			case 'Tracteur+semi': {
			
				if (heureSupp && heureSupp > 0) {
					 _price =
					 heureSupp *
						tarif.TRACTEUR_SEMI.TARIF_PAR_HEURE_DE_MISE_À_DISPO
				}
				if (rippeursValue) {
					_price +=
						rippeursValue *
						heureSupp *
						tarif.TRACTEUR_SEMI
							.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS
				}

				break
			}

			default:
				break
		    }
		  
			return _price
}


	

