import { GradientText, Wrapper } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import {
	searchCommandesByDateForClient,
	searchCommandesByPrixMinMaxForClient,
	searchCommandesByIdForClient
} from '../../api/clients'

import {payTarif} from '../../api/tarif'
import Container from '../../Components/LayoutClient/container.component'
import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import Select from 'react-select'
import { getAllCommandesByClientId,cancelCommande } from '../../api/commande'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import Logo from '../../assets/img/logo-B2BGLOB.png'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'
import DetailCommandeModal from '../../Components/DetailCommande/DetailCommandeModal'
const SIZE = 10

function CommandesList() {
	const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const [commande,setCommande]=useState(null)

	const clientID = useSelector((state) => state.auth.user._id)
	const token = useSelector((state) => state.auth.token);
	
	const [page, setPage] = useState(1)
	const [totalPages,setTotalPages]=useState(1)
	
	const [isSearching,setIsSearching]=useState(false)

	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [modalIsOpenCanceled, setModalIsOpenCanceled] = useState(false);
	const [isOrderCanceled, setIsOrderCanceled ] = useState(false)
	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [searchQueryId, setSearchQueryId] = useState('')
	
	const [selectedCommande,setSelectedCommande]=useState(null)
	const [loading, setLoading] = useState(false)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });


	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };

	
	const fetchCommandes = async (page) => {
		setChargementEnCours(true);
		try {
			let res;
			if (isSearching) {
				if (searchCriteria === 'id') {
					res = await searchCommandesByIdForClient(clientID,searchQueryId,'commande', token,page,SIZE);
				} else if (searchCriteria === 'prix') {
					res = await searchCommandesByPrixMinMaxForClient(min, max, clientID, 'commande', token, page, SIZE);
				} else if (searchCriteria === 'date') {
					let minDate = moment(range[0]).format();
					let maxDate = moment(range[1]).format();
					let offsetUTC = moment().format("Z");
					res = await searchCommandesByDateForClient(minDate, maxDate,clientID, 'commande', token, page, SIZE,offsetUTC);
				}
			} else {
				res = await getAllCommandesByClientId(clientID, token, page, SIZE);
			}
			setCommandes(res.commandes);
			setTotalPages(res.totalPages);
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};

	const resetData = async () => {
		setIsSearching(false); 
		setSearchQueryId(''); 
		setSearchCriteria("id")
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setPage(1);
	};
	
	
	const handleSearch = () => {
		setIsSearching(true); 
		setPage(1); 
		if(isSearching){
			fetchCommandes(1); 

		}
	};

	
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const success = params.get('success');
		if (success === 'true') {
			setModalIsOpen(true)
			setIsOrderCanceled(false)
		}
		if (success === 'false') {
			setModalIsOpen(true)
			setIsOrderCanceled(true)
		}
        params.delete('success');
        history.replace({
        search: params.toString()
    });
	},[history])

	useEffect(() => {
		fetchCommandes(page)
	}, [page,isSearching])


	return (
		<Container>
			<Wrapper>
				<GradientText>{'commandes'.toUpperCase()}</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
						onClick={handleSearch}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={resetData}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginRight: '1rem',
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
                {chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:"3rem",
				  top: 0,
				  left: 0,
				  right: 0,
				  bottom: 0,
	   
				}}
				>
				<div className='customLoader'></div>	
				</div>
				:
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							//justifyContent: 'center',
						}}
					>
				
						<div
							style={{
								width: '10rem',
								height: 1,
								background: '#F2F2F2',
							}}
						/>
					</div>
					{ commandes.length === 0 ? 
					<span
					style={{
					
						
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					Il n'y a pas des commandes à afficher
				</span>
				:commandes.map((commande) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidefacture
									hideStatus
									onClickDetails={() => {
										history.push(
											`/commandes/${commande._id}`
										)
									}}
									
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
									onClickCancel={ () => {
										
											setCommande(commande)
											setModalIsOpenCanceled(true)
										
											
									}}
									onClickPayer={async ()=>{
										const response = await payTarif({ commandeId: commande._id },token);
		                            	window.location.href = response.url;
									}}
									
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidefacture
									hideStatus
									onClickDetails={() => {
										setSelectedCommande(commande._id)
										setModalIsOpenDetail(true)
									}}
									onClickSignaler={() => {
										history.push(
											`/transports/${commande._id}/single`
										)
									}}
									onClickMissions={()=>{
										history.push(
											`/client/transports/missions/${commande._id}`
										)
									}}
									onClickCancel={ () => {
								        setCommande(commande)
										setModalIsOpenCanceled(true)		
								    }}
								onClickPayer={async ()=>{
									const response = await payTarif({ commandeId: commande._id },token);
									window.location.href = response.url;
								}}
								/>
							)
						}
					})}
				</div>
                }
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom:"3rem",
							marginTop:"3rem"
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={totalPages}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>
			</Wrapper>
			<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 13,
						textAlign: 'center',
					}}
				>
					<div>
					<img
					style={{ width: '120px', marginBottom: '1rem' }}
					alt='logo'
					src={Logo}
				/>
						<h1>{isOrderCanceled ? "Erreur de paiement": "paiement effectué avec succès"}</h1>
					</div>

					<button
						onClick={() => setModalIsOpen(false)}
						style={{
							width: '150px',
							height: '50px',
							background: '#5BBCEA',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							transition: 'background-color 0.3s ease'
						}}
					>
						OK
					</button>
				</div>
			</Modal>
			
		  
		   <Modal
				isOpen={modalIsOpenCanceled}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Annuler Commande</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir annuler cette  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								const  res=await cancelCommande(
									commande._id,
									token
			
								)
								toast.success(
									res.message,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
								
								
							} catch (error) {
								
								if (error.response && error.response.status === 403) {
									toast.error(
										error.response.data.message,
										{
											style: {
												fontFamily: 'Montserrat',
											},
										}
									)	
								}else{
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}
							}finally {
								setLoading(false)
								setModalIsOpenCanceled(false)
								await fetchCommandes(page)
							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenCanceled(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		 
		</Container>
	)
}

export default CommandesList
