import React ,{useState} from 'react'
import Footer from '../../Components/Footer/footer.component'
import { useForm } from 'react-hook-form'
import { forgotPassword } from '../../api/auth'
import toast from 'react-hot-toast'
import CommonHeader from '../../Components/CommonHeader/common-header.component'

function ForgotPassword() {
    const { register, handleSubmit,formState: { errors }  } = useForm()
    const [loading,setLoading]=useState(false)
   
    const onSubmit = async (data) => {
        setLoading(true)
		try {
			const res = await forgotPassword(data.email)
            if(res.msg==="RESET_EMAIL_SENT"){
                toast.success(
                    `Un e-mail de réinitialisation a été envoyé à ${res.email}. Veuillez vérifier votre boîte de réception.`,
                    {
                        style: {
                            fontFamily: 'Montserrat',
                        },
                    }
                );
            }
                
                
		} catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 
            "Une erreur est survenue. Veuillez réessayer.";
           
            toast.error(errorMessage, {
            style: {
                fontFamily: 'Montserrat',
            },
            });
		}finally{
            setLoading(false)
        }
	}
  return (
    <div>
    <CommonHeader />

    <div
        style={{
            height: '95vh',
            width: '100%',
            backgroundColor:'#f7f8f8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
     

        <div
            style={{
                width: '100%',
                maxWidth: "600px",
                background:'white' ,
                borderRadius: "8px",
                padding: '2rem',
                boxShadow:"0 1px 3px rgba(0, 0, 0, 0.1)" ,
                fontFamily: 'Montserrat',
            }}
        >
            	<h1 style={{
						 fontSize: "1.5rem",
						 fontWeight: 600,
						 textAlign: "center",
						 marginBottom: '2rem'
					}}>
                        Mot de passe oublié
                    </h1>
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'

                }}
            >
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            marginBottom: 7,
                            fontSize: "14px",
                            color:'#606060',
                            fontWeight:500,
                            lineHeight:"17px"
                        }}
                    >
                        Adresse email 
                    </span>
                    <input
                        {...register('email', {
                            required: "Veuillez indiquer l'adresse e-mail.",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Veuillez entrer une adresse e-mail valide',
                              },
                        })}
                        style={{
                            padding: '1rem',
                            fontFamily: 'Montserrat',
                            color: '#333',
                            borderRadius: "8px",
                            background: 'white',
                            padding: '0.75rem',
                            border: '1px solid #D9D9D9',
                            width: '100%',
                            ':focus': {
                                outline: 'none',
                                border: '1px solid #5BBCEA',
                              }
                            
                        }}
                        name='email'
                        placeholder='Email'
                        type='text'
                    />
                    {errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
                </label>

               

                <button
                    style={{
                        color: 'white',
                        fontFamily: 'Montserrat',
                        background: '#5BBCEA',
                        border: 'none',
                        padding: '1rem',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        borderRadius: "8px",
                        display: 'flex',
						justifyContent: 'center',
					    alignItems: 'center'
                    }}
                    type='submit'
                    disabled={loading}
                >
                    
                    {loading ? (<div className='loader'></div>) : ('Envoyer')}

                </button>

            
            </form>
        </div>
    </div>
    <Footer />
</div>
  )
}

export default ForgotPassword