import {
	Header,
	RootContainer,
	SideBar,
	SideBarContainer,
} from '../LayoutClient/container.styles'
import { useDispatch, useSelector } from 'react-redux'

import Footer from '../Footer/footer.component'
import Logo from '../../../src/assets/img/FullLogo_Transparent.png'
import MenuItem from '../LayoutClient/menu-item.component'
import { PUBLIC } from '../../api/base'
import React from 'react'
import { logoutAction } from '../../redux/auth'
import { useHistory } from 'react-router'
import { logout } from '../../api/auth'

function LayoutCommercant({ children }) {
    const history = useHistory()
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth.user)
    const token=useSelector((state) => state.auth.token)
    const refreshToken=useSelector((state)=>state.auth.refreshToken)
  return (
    <RootContainer>
    <Header>
        <img
            style={{
                width: '12rem',
            }}
            alt='logo'
            src={Logo}
        />

        <div
            style={{
                background: '#50F5A9',
                paddingRight: '2rem',
                paddingLeft: '2rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                borderRadius: '0.9rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                fontFamily: 'Montserrat',
                color: '#fff',
                fontWeight: 'bold',
            }}
        >
            <span>
                Bienvenue{' '}
                {user && (
                    <>
                        {user.first_name} {user.last_name}
                    </>
                )}
            </span>

            <span style={{ width: '10px' }} />

            <img
                style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '1rem',
                }}
                alt='user'
                src={
                    user && user.logo
                        ? `${PUBLIC}/${user.logo.name}`
                        : 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
                }
            />
        </div>
    </Header>
    <div
        style={{
            background: 'red',
            height: 'calc(100vh - 7rem)',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        }}
    >
        <SideBar>
            <SideBarContainer>
            <MenuItem
                    title='Ajouter Client'
                    onClick={() =>
                        history.push('/commercial/client/new')
                    }
                    icon={
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15" >
                   <path  d="M0 0h24v24H0z" fill="none" />
                   <path fill="#ffffff" d="M20 11H13V4c0-.55-.45-1-1-1s-1 .45-1 1v7H4c-.55 0-1 .45-1 1s.45 1 1 1h7v7c0 .55.45 1 1 1s1-.45 1-1v-7h7c.55 0 1-.45 1-1s-.45-1-1-1z"/>
                   </svg>
                    }
                />
                <MenuItem
                    title='Mes clients'
                    onClick={() =>
                        history.push('/commercial/clients')
                    }
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='8.748'
                            height='17.497'
                            viewBox='0 0 8.748 17.497'
                        >
                            <g id='user' transform='translate(0.5 0.5)'>
                                <path
                                    id='Path_11624'
                                    data-name='Path 11624'
                                    d='M30.173,6.186a2.1,2.1,0,1,1,2.1-2.1A2.1,2.1,0,0,1,30.173,6.186Zm0-3.721A1.626,1.626,0,1,0,31.8,4.091,1.626,1.626,0,0,0,30.173,2.465Z'
                                    transform='translate(-26.305 -1.992)'
                                    fill='#fff'
                                    stroke='#fff'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_11625'
                                    data-name='Path 11625'
                                    d='M26.926,28.967h-.462a.236.236,0,1,1,0-.471h.462a.912.912,0,0,0,.911-.911V23.878a1.048,1.048,0,0,0-1.046-1.046H22.079a1.048,1.048,0,0,0-1.046,1.046v3.708a.912.912,0,0,0,.912.91h.445a.236.236,0,1,1,0,.471h-.445a1.384,1.384,0,0,1-1.383-1.382V23.878a1.519,1.519,0,0,1,1.517-1.518h4.713a1.519,1.519,0,0,1,1.518,1.518v3.708A1.384,1.384,0,0,1,26.926,28.967Z'
                                    transform='translate(-20.562 -17.561)'
                                    fill='#fff'
                                    stroke='#fff'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_11626'
                                    data-name='Path 11626'
                                    d='M37.246,43.051A1.247,1.247,0,0,1,36,41.8V37.878a.236.236,0,0,1,.471,0V41.8a.775.775,0,0,0,1.549,0V34.362a.236.236,0,0,1,.471,0V41.8A1.247,1.247,0,0,1,37.246,43.051Z'
                                    transform='translate(-32.362 -26.554)'
                                    fill='#fff'
                                    stroke='#fff'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_11627'
                                    data-name='Path 11627'
                                    d='M28.672,43.051A1.247,1.247,0,0,1,27.426,41.8V34.362a.236.236,0,0,1,.471,0V41.8a.775.775,0,0,0,1.549,0V37.878a.236.236,0,0,1,.471,0V41.8A1.247,1.247,0,0,1,28.672,43.051Z'
                                    transform='translate(-25.809 -26.554)'
                                    fill='#fff'
                                    stroke='#fff'
                                    strokeWidth='1'
                                />
                            </g>
                        </svg>
                    }
                />

                            <MenuItem
							onClick={() =>
								history.push('/stats/commercial')
							}
							title='Statistiques'
							icon={
								<svg
									id='icon_dashboard'
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
								>
									<path
										id='Union_8'
										data-name='Union 8'
										d='M13,16a.945.945,0,0,1-1-1V6a.945.945,0,0,1,1-1h2a.945.945,0,0,1,1,1v9a.945.945,0,0,1-1,1ZM7,16a.944.944,0,0,1-1-1V1A.945.945,0,0,1,7,0H9a.945.945,0,0,1,1,1V15a.944.944,0,0,1-1,1ZM1,16a.945.945,0,0,1-1-1V11a.945.945,0,0,1,1-1H3a.946.946,0,0,1,1,1v4a.945.945,0,0,1-1,1Z'
										transform='translate(0 0)'
										fill='#f5f6f7'
									/>
								</svg>
							}
						/>
          
                <MenuItem
                    onClick={() => history.push('/commercial/account')}
                    title='Mon compte'
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='14'
                            height='16'
                            viewBox='0 0 14 16'
                        >
                            <g
                                id='icon_customers'
                                transform='translate(0 0)'
                            >
                                <path
                                    id='Union_10'
                                    data-name='Union 10'
                                    d='M0,16a6.018,6.018,0,0,1,6-6H8a6.018,6.018,0,0,1,6,6Zm2.6-2h8.9A4.033,4.033,0,0,0,8,12H6.1A4.035,4.035,0,0,0,2.6,14ZM3,5V4a4,4,0,0,1,8,0V5A4,4,0,1,1,3,5ZM5,4V5A2,2,0,1,0,9,5V4A2,2,0,0,0,5,4Z'
                                    transform='translate(0 0)'
                                    fill='#f5f6f7'
                                />
                            </g>
                        </svg>
                    }
                />

                <MenuItem
                 onClick={async () => {
                    try {
                         const data = { userId: user._id, refreshToken };
                         await logout(data, token);
                         dispatch(logoutAction());
                         history.push('/login');
                    } catch (error) {
                        console.error("Logout error:", error);
                    }
                    }}	
                    title='Déconnexion'
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='12'
                            height='15'
                            viewBox='0 0 12 15'
                        >
                            <g
                                id='Group_11287'
                                data-name='Group 11287'
                                transform='translate(-27 -511)'
                            >
                                <g
                                    id='Ellipse_111'
                                    data-name='Ellipse 111'
                                    transform='translate(27 511)'
                                    fill='none'
                                    stroke='#e4eaf0'
                                    strokeWidth='2'
                                >
                                    <circle
                                        cx='6'
                                        cy='6'
                                        r='6'
                                        stroke='none'
                                    />
                                    <circle
                                        cx='6'
                                        cy='6'
                                        r='5'
                                        fill='none'
                                    />
                                </g>
                                <g
                                    id='Rectangle_4256'
                                    data-name='Rectangle 4256'
                                    transform='translate(31 518)'
                                    fill='#e4eaf0'
                                    stroke='#413f4d'
                                    strokeWidth='1'
                                >
                                    <rect
                                        width='4'
                                        height='8'
                                        stroke='none'
                                    />
                                    <rect
                                        x='0.5'
                                        y='0.5'
                                        width='3'
                                        height='7'
                                        fill='none'
                                    />
                                </g>
                            </g>
                        </svg>
                    }
                />
            </SideBarContainer>
        </SideBar>

        <div
            style={{
                width: '100%',
                background: 'white',
                position: 'relative',
                overflowY: user.status==="suspendu" ? 'hidden' : 'auto',
            }}
        >
            {children}
            {user.status==="suspendu" && (
						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								background: 'rgba(0, 0, 0, 0.5)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									width: '50%',
									// height: '50%',
									background: 'white',
									borderRadius: 5,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '2rem 2rem',
									textAlign: 'center',
								}}
							>
								<span
									style={{
										fontFamily: 'Montserrat',
										fontSize: 20,
										marginBottom: '2rem',
									}}
								>
									Compte suspendu
								</span>
								<span
									style={{
										fontFamily: 'Montserrat',
									}}
								>
									Votre compte est suspendu jusqu'à nouvelle
									ordre, veuillez contacter la Plateforme du
									Transport. Merci
								</span>
							</div>
						</div>
					)}
        </div>
    </div>

    <Footer />
</RootContainer>
  )
}

export default LayoutCommercant