import React from 'react';

export const VehicleCard = ({
  vehicle,
  selected,
  onSelect,
  register,
  name
}) => {
    const { volume = 'Non spécifié', length, width, height, payload, pallets = 'Non spécifié' } = vehicle.specs;

  return (
    <div style={styles.cardContainer}>
      <div style={styles.imageContainer}>
        <img
          src={vehicle.image}
          style={styles.image}
          alt={vehicle.name}
        />
      </div>

      <div style={styles.selectionContainer}>
        <input
          type="radio"
          style={styles.radioInput}
          {...register(name ,
            { required: "Veuillez choisir le type de véhicule détaillé" }

          )}
          value={vehicle.name}
          checked={selected}
          onChange={onSelect}
        />
        <span style={styles.vehicleName}>{vehicle.name}</span>
      </div>

      <div style={styles.payloadContainer}>
        <span style={styles.payload}>{vehicle.specs.payload}</span>
      </div>

      <div style={styles.detailsContainer}>
        <span style={styles.detailsText}>Dimension en M3 : {volume}</span>
        <span style={styles.detailsText}>Longueur utile : {length}</span>
        <span style={styles.detailsText}>Largeur utile : {width}</span>
        <span style={styles.detailsText}>Hauteur utile : {height}</span>
        <span style={styles.detailsText}>Charge utile : {payload}</span>
        <span style={styles.detailsText}>Nombre de palettes : {pallets}</span>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom:'.3rem'
   
  },
  imageContainer: {
    width: '100%',
    height: 'auto',
    marginBottom: '8px',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '120px',
    maxHeight: '80px',
    objectFit: 'cover',
  },
  selectionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  radioInput: {
    marginRight: '16px',
  },
  vehicleName: {
    fontSize: '16px',
  },
  payloadContainer: {
    marginLeft: '36px',
  },
  payload: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f2f3f4',
    padding: '12px',
    width: '70%',
    borderRadius: '8px',
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  detailsText: {
    marginBottom: '8px',
  }
};
