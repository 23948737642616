import React, { useState,useEffect } from 'react'
import Modal from "react-modal"
import {getFilesByMissionId} from '../../api/commande'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import UpdateUploadFileCommande from '../UploadFileCommande/UpdateUploadFileCommande';
import { useForm } from 'react-hook-form';
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '80vh',
        overflow: 'hidden',
        width: "70%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#5BBCEA',
    padding: '20px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const closeButtonStyle = {
    position: 'absolute',
    right: '15px',
    backgroundColor: 'transparent',
    padding: '0',
    color: '#FF5A5F',
    border: 'none',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
};

const contentStyle = {
    padding: '20px',
    background: '#F7F8F8',
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 60px)',
};


const MissionFilesModal=({ isOpen, onClose, missionId,index,hideAddFile=false,hideRemoveFile=false})=>{
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const token = useSelector((state) => state.auth.token);
    const [data,setData]=useState(null)    
     const {setValue } = useForm()         

    const fetchFiles=async(missionId)=>{
        setChargementEnCours(true)         
        try {
           const res=await  getFilesByMissionId(missionId,token)
           setData(res)
           console.log("files",res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });  
        }finally{
         setChargementEnCours(false)
        }
    }
 
    useEffect(()=>{
        if(missionId && isOpen){
            fetchFiles(missionId) 
        }
    },[missionId,isOpen])
    
  return (
   <Modal
   isOpen={isOpen} 
   onRequestClose={onClose} 
   ariaHideApp={false}
   style={modalStyles}>
  <div style={headerStyle}>
    <h3 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>Gérer les Documents</h3>
    <button 
    onClick={onClose}  
    style={closeButtonStyle}
    >
    X
    </button>
  </div>
  {chargementEnCours ?
  <div
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginTop:"2rem",
    marginBottom:"2rem"
  }}
  >
  <div className='customLoader'></div>	
  </div>
   :
  <div style={contentStyle}>
    <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '2.5rem',
                    width: '100%',
                }}
                >
                    <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span
                                    style={{
                                        marginBottom: '1rem',
                                        color: 'black',
                                        fontSize: 14,
                                    }}
                                >
                                    Joindre Documents (PDF)
                                </span>
                                
                     </div>
                    <UpdateUploadFileCommande
                    initialFiles={data?.files}
                    name={`file_${index}`}
                    onChange={(files) => {
                                setValue(
                                `missions[${index}].files`,
                                files.map((file) => file)                                                )
                                            
                    }}
                    commandeId={data?.commandeId}
                    missionId={data?.missionId}
                    hideAddFile={hideAddFile}
                    hideRemoveFile={hideRemoveFile}
                    />
    </div>
    {data?.files?.length === 0 &&
    <div style={{
        background: '#fff',
        padding: '15px',
        borderRadius: '8px',
        marginBottom: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s',
        textAlign:'center'
        }}>
        Il n'y a pas des documents à afficher
    </div>
    }
  </div>

     

  }
   </Modal>
  )
}
export default MissionFilesModal