import React from 'react';
import Modal from 'react-modal';
 
const ActionModal = ({
  isOpen,
  onRequestClose,
  title,
  message,
  onConfirm,
  onCancel,
  isLoading = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 19,
          padding: '2rem',
        },
      }}
    >
      <div
        style={{
          width: '20rem',
          fontFamily: 'Montserrat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 15,
          textAlign: 'center',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            fontSize: 20,
            color: '#51C7F2',
          }}
        >
          {title}
        </span>
        <div
          style={{
            marginTop: 4,
            width: '100%',
          }}
        >
          <p>{message}</p>
        </div>
        <div
          style={{
            width: '100%',
            fontFamily: 'Montserrat',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            marginBottom: '5px',
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={onConfirm}  
            disabled={isLoading}
            style={{
              width: '150px',
              height: '50px',
              background: '#5BBCEA',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              marginTop: 5,
              fontFamily: 'Montserrat',
              marginRight: 10,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              'Confirmer'
            )}
          </button>
          <button
            onClick={onCancel}
            style={{
              width: '150px',
              height: '50px',
              background: 'red',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              marginTop: 5,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >
            Annuler
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionModal;
