import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Cookie } from 'lucide-react';

const CookieConsent = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        // Vérifier si l'utilisateur a déjà donné son consentement
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setVisible(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 }); // Cookie valide pendant 1 an
        setVisible(false);
    };

    const handleDecline = () => {
        Cookies.set('cookieConsent', 'declined', { expires: 365 });
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            left: '20%',
            transform: 'translateX(-50%)',
            background: 'white',
            padding: '20px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            maxWidth: '400px',
            textAlign: 'center',
            zIndex: '1000',
            fontFamily: 'Montserrat',
            animation: 'fadeIn 0.5s ease'
        }}>
            <h2 style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: '#25A6E4',
                fontWeight: 'bold',
                fontSize: '1.25rem'
            }}>
                <Cookie size={22} /> Ce site web utilise des cookies.
            </h2>
            <p
                style={{
                    color: '#333',
                    fontSize: '1rem',
                    fontWeight: '500',
                    paddingTop: '10px'
                }}>
                Nous utilisons des cookies pour vous offrir la meilleure expérience possible tout en conservant les informations relatives à votre navigation.
            </p>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                paddingTop: '15px',
            }}>
                <button
                    onClick={handleAccept}
                    style={{
                        width: '100px',
                        height: '40px',
                        background: '#5BBCEA',
                        color: 'white',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '1rem',
                        fontWeight: '500',
                        cursor: 'pointer',
                        transition: 'background 0.3s ease'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.background = '#4AA6D4'}
                    onMouseOut={(e) => e.currentTarget.style.background = '#5BBCEA'}
                >
                    Accepter
                </button>
                <button
                    onClick={handleDecline}
                    style={{
                        width: '100px',
                        height: '40px',
                        background: 'white',
                        border: '1px solid #5BBCEA',
                        color: '#5BBCEA',
                        borderRadius: '8px',
                        fontSize: '1rem',
                        fontWeight: '500',
                        cursor: 'pointer',
                        transition: 'background 0.3s ease'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.background = '#F0F8FF'}
                    onMouseOut={(e) => e.currentTarget.style.background = 'white'}
                >
                    Refuser
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;