import React ,{useState} from 'react'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { GradientText ,FormSectionCard,Select,	SubmitButtonContainer,SubmitButton} from '../../PasserCommande/PasserCommande.styles'
import { useForm } from 'react-hook-form';
import { CustomInput } from '../../RegisterTransporter/style';
import { CreateUser } from '../../../api/auth';
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import { useHistory } from 'react-router'
function NouveauxAdministrateur () {
	const history=useHistory()
    const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
    const { handleSubmit,formState: { errors },register,setValue } = useForm();
    const onSubmit = async (data) => { 
		if (data.password !== data.password_verification) {
			toast.error('Veuillez vérifier votre mot de passe', {
			  style: {
				fontFamily: 'Montserrat',
			  },
			});
			return;
		  }
	   setLoading(true)
       try {
        delete data.password_verification
       
        await CreateUser(data)
        setModalIsOpen(true)

       } catch (error) {
        const data = error?.response?.data?.errors
        if (data.msg === 'EMAIL_ALREADY_EXISTS') {
            toast.error(
                'Ce mail est déjà utilisé par un autre utilisateur.',
                {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                }
            )
        }else{
			toast.error("Une erreur s'est produite", {
				style: {
				  fontFamily: 'Montserrat',
				},
			});	
		}
       }finally{
		setLoading(false)

	   }
    }
  return (
    <LayoutAdmin>
       <div
		style={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			paddingTop: '2rem',
			paddingBottom: '4rem',
			}}
			>
        <GradientText>
		{'Créer un compte Administrateur'.toUpperCase()}
		</GradientText>
        <div
		    style={{
			width: '60%',
			fontFamily: 'Montserrat',
			marginTop:'1rem'
			}}
		>
       <form onSubmit={handleSubmit(onSubmit)}>
        <FormSectionCard>
        <div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom <span style={{color:'red'}}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('last_name', {
											required: 'Veuillez indiquer le nom',
											minLength: {
												value: 3,
												message: 'Le nom  doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },	
										})}
									/>
									{errors.last_name && (
                                    <div style={{ color: 'red', marginTop: '0.5rem' ,fontSize:'14px'}}>
                                     {errors.last_name.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom <span style={{color:'red'}}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									  {errors.first_name && (
                                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                       {errors.first_name.message}
                                       </div>
                                       )}
								</div>
	    </div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email <span style={{color:'red'}}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
									{errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
	       
        </div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Mot de passe <span style={{color:'red'}}>*</span>
									</span>
									<CustomInput
										type='password'
										{...register('password', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Confirmer mot de passe  <span style={{color:'red'}}>*</span>
									</span>
									<CustomInput
										type='password'
										{...register('password_verification', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password_verification && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password_verification.message}
                                    </div>
                               )}
								</div>
		</div>
                            <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Service  <span style={{color:'red'}}>*</span>
									</span>
									<Select
                                    {...register('role', {
										required: 'Veuillez sélectionner le rôle',
									})}
									onChange={(e) => {
										setValue('role', e.target.value)
									}}

                                    >
									  <option value="" disabled selected >Sélectionnez le service</option>
                                      <option value="admin service après vente">Service après vente</option> 
                                      <option  value="admin comptabilité">Service comptabilité</option> 
                                      <option value="admin distribution">Service distribution</option>  
									  <option value="admin">Service administratif</option>  

                                    </Select>
									{errors.role && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.role.message}
                                    </div>
                               )}						
	       
        </div>
        </FormSectionCard>
        <div style={{ height: '1rem' }} />
        <SubmitButtonContainer>
		<SubmitButton type='submit' disabled={loading}>
		{loading ? (
									<div className='loader'></div>
								) : (
									'Créer un compte'
		)}	
       
		</SubmitButton>
		</SubmitButtonContainer>
       </form>
        </div>
    </div>
    <Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<GradientText>{'Félicitation'.toUpperCase()}</GradientText>

					<div
						style={{
							marginTop: 20,
						}}
					>
                    Votre administrateur a été ajouté avec succès! 😃	
					<br />
					Un e-mail de confirmation a été envoyé à son adresse e-mail.
					 <br />
					 Veuillez informer votre administrateur de vérifier sa boîte de réception et de cliquer sur le lien fourni pour finaliser la création de son compte.
					</div>

					<button
						onClick={() =>{
							setModalIsOpen(false)
							history.push("/admin/administrateurs")

						}
						}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
     </LayoutAdmin>
  )
}

export default NouveauxAdministrateur 