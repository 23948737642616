import React, { useEffect, useState } from 'react'
import {
	getAllCommandesByClientIdForFacturation,
	getAllCommandeByClientForFacturation,
	getCommandeByClientByDateForFacturation,
	getCommandeByClientByPriceForFacturation

} from '../../../api/commande'
import Select from 'react-select'
import { getStatsForSingleClient } from '../../../api/stats'
import { useParams } from 'react-router'
import { FaRegFilePdf } from "react-icons/fa";
import DataTable from 'react-data-table-component'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { BASE_URL} from '../../../api/base'
import { mergeInvoiceForCommande,mergeAvoirForCommande,exportInvoices } from '../../../api/invoice'
import moment from 'moment'
import { useSelector } from 'react-redux'
import DetailCommandeModal from '../../../Components/DetailCommande/DetailCommandeAdminModal'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')



function SingleClientFacturation(props) {

	const { id } = useParams()
	const [selectedDate, setSelectedDate] = useState(null);

	const [data, setData] = useState([])
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const token = useSelector((state) => state.auth.token);
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [montantTotal, setMontantTotal] = useState(0)
    const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [selectedCommande,setSelectedCommande]=useState(null)

	const [currentPage, setCurrentPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [selectedOption, setSelectedOption] = useState({
			value: 'id', label: "Recherche par l'identifiant " 
		 });
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
    const handleChange = (selectedOption) => {
		   setSelectedOption(selectedOption);
		   setSearchCriteria(selectedOption.value)
		 };
	const handleViewInvoices = async (row) => {
				try {
					const res=await mergeInvoiceForCommande(row._id,token)
					window.open(`${BASE_URL}/${res.mergedPdfPath}`)
				} catch (error) {
					console.log(error)
				}
		};
	
	const handleViewAvoir = async (row) => {
			try {
				const res=await mergeAvoirForCommande(row._id,token)
				window.open(`${BASE_URL}/${res.mergedPdfPath}`)
			} catch (error) {
				console.log(error)
			}
	};

	
	const fetchData = async (id, page, perPage) => {
		setChargementEnCours(true);
		try {
		  let res;
		  if (searchCriteria === 'id' && searchQuery) {
			res = await getAllCommandeByClientForFacturation(id, searchQuery, token, page, perPage);
		  } else if (searchCriteria === 'prix') {
			res = await getCommandeByClientByPriceForFacturation(id, min, max, token, page, perPage);
		  } else if (searchCriteria === 'date' && selectedDate) {
				const offsetUTC = moment().format('Z');
			res = await getCommandeByClientByDateForFacturation(id, selectedDate, token, page, perPage,offsetUTC);
		  } else {
			res = await getAllCommandesByClientIdForFacturation(id, token, page, perPage);
		  }
		  setData(res.commandes);
		  setTotalRows(res.totalCommandes);
		  const stats = await getStatsForSingleClient(id, token);
		  setMontantTotal(stats.totalDepense);
		} catch (error) {
		  console.error(error);
		} finally {
		  setChargementEnCours(false);
		}
	  };




	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchData(id,page,perPage)
	}

	const handlePerRowsChange = async (newPerPage,page) => {
		
		fetchData(id,page,perPage)
		setPerPage(newPerPage)
	
	}

	
	const handleDownload = async () => {
		try{
			const response = await exportInvoices(data,token)
			const url = window.URL.createObjectURL(new Blob([response], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			const currentDate = new Date().toISOString().slice(0, 10);
            link.setAttribute('download', `factures_${currentDate}.zip`); 
			document.body.appendChild(link);
			link.click();
			link.remove();
		}catch(error){
        console.log("error",error)
		}
	}

	useEffect(() => {
		fetchData(id,currentPage,perPage)
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
		},
		{
			name: 'Date du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							marginBottom: 3,
						}}
					>
					{moment(row?.date_transport).format('DD/MM/YYYY HH:mm')}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		{
			name: 'Montant du transport',
			selector: (row) => {
				const totalForfaitsSupp = row?.missions
                .reduce((total, mission) => {
                const totalForfaitsMission = mission.forfaits
                ?.reduce((totalForfait, forfait) => totalForfait + (forfait.prix || 0), 0) || 0;
        
               return total + totalForfaitsMission;
               }, 0);

              const prix = row?.missions
              .reduce((total, mission) => total + (mission?.prix || 0), 0);	
				return (prix + totalForfaitsSupp).toFixed(2);
			  },
			sortable: false,
			width:'170px'
		},	
		{
			name: 'Montant du remboursement',
			selector: (row) => {
				return (row.prixRembourse).toFixed(2);
			},
			sortable: false,
			width:'210px'
		},
		{
			name: 'Statut commande',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						
						style={{
							
							fontWeight: 'bold',
							color: '#51C7F2',
							
						}}
					>
						{row.statut ==="en-cours" ? 'En cours' : (row.statut==="terminer" ? "Terminée" : row.statut==="init" ? "Non commencée" :"Annulée" )}
					</span>	
				</div>
			),
			sortable: false,
			width: '160px'
		},
		
		{
			name: 'Statut du paiement',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						
						style={{
							
							fontWeight: 'bold',
							color: '#51C7F2',
							
						}}
					>
						{row.paymentStatus==="payé" ? 'Payée' : 'Non payée'}
					</span>	
				</div>
			),
			sortable: false,
			width:'160px'
		},
		{
			name: 'Statut de remboursement',
			cell: (row) => {
			  // Vérification si le remboursement est complet : seulement si le statut de la commande est "annuler"
			  const isRemboursementComplet = row.statut === 'annuler';
		  
			  // Vérification si le remboursement est partiel : si au moins une mission est annulée mais que le statut de la commande n'est pas "annuler"
			  const isRemboursementPartiel = row.statut !== 'annuler' && row.missions.some(mission => mission.statut === 'annuler');
		  
			  const allMissionsRemboursees = row.missions.every(mission => mission.statut_remboursement === 'remboursé');
			  const hasMissionEchouee = row.missions.some(mission => mission.statut_remboursement === 'échoué');
			  const hasMissionEnCours = row.missions.some(mission => mission.statut_remboursement === 'en-cours');
			 
			  const allMissionsAnnulees = row.missions.every(mission => mission.statut === 'annuler');
			  const allMissionsAnnuleesRemboursees = row.missions.filter(mission => mission.statut === 'annuler').every(mission => mission.statut_remboursement === 'remboursé');
			  const hasMissionAnnuleesEchouee = row.missions.filter(mission => mission.statut === 'annuler').some(mission => mission.statut_remboursement === 'échoué');
			  const hasMissionAnnuleesEnCours = row.missions.filter(mission => mission.statut === 'annuler').some(mission => mission.statut_remboursement === 'en-cours');

			  let typeRemboursement = isRemboursementComplet ? 'Complet' : (isRemboursementPartiel ? 'Partiel' : 'Non applicable');
			  let statutRemboursement;
		  
			  // Gestion du cas de remboursement complet
			  if (isRemboursementComplet) {
				if (!allMissionsAnnulees) {
				  // Si toutes les missions ne sont pas annulées, afficher le statut de remboursement de la commande
				  statutRemboursement = row.statut_remboursement === 'remboursé' ? 'Remboursé' : (row.statut_remboursement === 'échoué' ? 'Échouée' : (row.statut_remboursement === 'en-cours' ? 'En cours' : 'Non remboursée'));
				} else {
				  // Si toutes les missions sont annulées, afficher le statut de remboursement en fonction des missions
				  statutRemboursement = (allMissionsRemboursees && row?.statut_remboursement === 'remboursé' ) ? 'Remboursée' : ((hasMissionEchouee && row.statut_remboursement === 'échoué') ? 'Échouée' : ((hasMissionEnCours && row.statut_remboursement === 'en-cours') ? 'En cours' : 'Non remboursée'));
				}
			  } else if(isRemboursementPartiel) {
				// Gestion du cas de remboursement partiel
				if (allMissionsAnnuleesRemboursees) {
				  statutRemboursement = 'Remboursée';
				} else if (hasMissionAnnuleesEchouee) {
				  statutRemboursement = 'Échouée';
				} else if (hasMissionAnnuleesEnCours) {
				  statutRemboursement = 'En cours';
				} else {
				  statutRemboursement = 'Non remboursée';
				}
			  }else{
				statutRemboursement = 'Non remboursée'

			  }
		  
			  return (
				<div
				  style={{
					display: 'flex',
					flexDirection: 'column',
					color: '#838EAB',
					fontSize: 13,
					borderRadius: 5,
					margin: '1rem 0 1rem 0',
				  }}
				>
				  <span style={{ fontWeight: 'bold', color: '#51C7F2' }}>
					{typeRemboursement}
				  </span>
				  <span
					style={{
					  fontWeight: 'bold',
					  color: statutRemboursement === 'Remboursé' ? '#51C7F2' : statutRemboursement === 'Échoué' ? '#F25151' : '#838EAB',
					}}
				  >
					{statutRemboursement}
				  </span>
				</div>
			  );
			},
			sortable: false,
			width: '200px',
		  },
		  
		  
		  
		{
			name: 'Date de paiement',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							marginBottom: 3,
						}}
					>
					{row.datePaiement? moment(row.datePaiement).format('DD/MM/YYYY') : '__'}
					</span>
					
				</div>
			),
			sortable: false,
			width:'160px'
			
		},
		{
			name: 'Date  de remboursement ',
			cell: (row) => {
				const isRemboursementComplet = row.statut === 'annuler';
			
				const isRemboursementPartiel = row.statut !== 'annuler' && row.missions.some(mission => mission.statut === 'annuler');
			    let dateRemboursement
				if(isRemboursementComplet){
					dateRemboursement=row.date_remboursement
				}
				if (isRemboursementPartiel) {
					const missionsAnnulees = row.missions.filter(mission => mission.statut === 'annuler');
					
					// Trouver la mission avec la date de remboursement la plus récente
					const missionAvecDateLaPlusProche = missionsAnnulees.reduce((closest, mission) => {
						if (!closest || moment(mission?.date_remboursement).isBefore(closest?.date_remboursement)) {
							return mission;
						}
						return closest;
					}, null);
					
					dateRemboursement = missionAvecDateLaPlusProche?.date_remboursement;
				}
			
				
			
				return (
					<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							marginBottom: 3,
						}}
					>
					{dateRemboursement? moment(dateRemboursement).format('DD/MM/YYYY') : '__'}
					</span>
					
				</div>
				);
			},
			sortable: false,
			width:'190px'
			
		},
		{
			name: 'Date de la création',
			selector: (row) => `${moment(row.createdAt).format('DD/MM/YYYY')}`,
			sortable: false,
			width:'150px'
			
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>

					
					<div
						
						onClick={() => handleViewInvoices(row)}
						style={{
							cursor: 'pointer',
						}}
					>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'#51C7F2'}} />


					</div>
					{row.avoir.length!==0 &&
					<div
						
						onClick={() => handleViewAvoir(row)}
						style={{
							cursor: 'pointer',
							marginLeft:'2px'
						}}
					>
                    <FaRegFilePdf style={{ fontSize: "25px" ,color:'red'}} />


					</div>
		            }	
					<div
						onClick={() => {
						  setSelectedCommande(row?._id)
						  setModalIsOpenDetail(true)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
					 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>
				</div>
				
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Facturation'.toUpperCase()}
				</GradientText>
				<div style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						justifyContent: 'flex-end',
					}}>
							<div
						style={{
							//background: 'red',
							width: '30%',
							padding: '1rem',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							border: 'solid .1rem black',
							borderRadius: '1rem',
							marginRight: '0.5rem',
						}}
					>

						<div
							style={{
								display: 'flex',
								alignItems:'center'
							}}
						>
							<span>Montant total:</span>
							<span style={{ width: '.5rem' }} />
							<span style={{ fontWeight: 'bold' }}>
								{montantTotal?.toFixed(2)}€
							</span>
						</div>
					</div>
                   </div>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
						<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{ value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
				
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
						{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}
						{searchCriteria === 'date' ? (
						<input
						    type='date'
							value={selectedDate}
							onChange={(e) => setSelectedDate(e.target.value)}
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							placeholder='Recherche'
						/>
					) : null}

					<button
						onClick={async () => {
							setCurrentPage(1);
							fetchData(id, 1, perPage);						 
						}}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginLeft:'1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							marginRight: '0.5rem',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={async () => {
							setSelectedOption({
							  value: 'id', label: "Recherche par l'identifiant " 
							})
							setSearchCriteria('id')
							setSearchQuery('');
							setMin(0);
							setMax(300);
							setSelectedDate(null);
							setCurrentPage(1);
							fetchData(id, 1, perPage);
						  }}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginRight: '0.5rem',							
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					<button
						onClick={async () => {
							await handleDownload()
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginRight: '0.5rem',
							
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
					 Exporter factures
					</button>

					
{/* 
					<button
						onClick={() => {
							setModalIsOpen(true)
						}}
						style={{
							padding: '1rem 2rem',
							background: '#60d2f8',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							marginRight: '0;5rem',
						}}
					>
						Facturer
					</button> */}
				</div>

				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent='pas de commandes à afficher'
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>

				

			
				

				
			</div>
			<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
		</LayoutAdmin>
	)
}

export default SingleClientFacturation
