import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getCommande } from '../../api/commande';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FaCheck } from 'react-icons/fa';
import { BASE_URL } from '../../api/base';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '16px',
        border: 'none',
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '85vh',
        overflow: 'hidden',
        width: "75%",
        maxWidth: '1200px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        backdropFilter: 'blur(5px)',
    },
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'linear-gradient(135deg, #5BBCEA 0%, #2196F3 100%)',
    padding: '24px 32px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
};

const closeButtonStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s ease',
};

const contentStyle = {
    padding: '32px',
    background: '#FFFFFF',
    overflowY: 'auto',
    maxHeight: 'calc(85vh - 80px)',
    fontFamily: 'Montserrat',
};

const sectionStyle = {
    padding: '24px',
    marginBottom: '24px',
    backgroundColor: '#F8FAFC',
    borderRadius: '12px',
    border: '1px solid #E2E8F0',
};

const title = {
    color: '#2196F3',
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
};

const detailsTitle = {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '8px',
};

const detailsSpan = {
    color: '#64748B',
    fontSize: '14px',
    fontWeight: 400,
};

const priceTag = {
    padding: '12px 24px',
    background: 'linear-gradient(135deg, #5BBCEA 0%, #2196F3 100%)',
    color: 'white',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    boxShadow: '0 2px 10px rgba(33, 150, 243, 0.2)',
};

const listItem = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 0',
    color: '#64748B',
};

const icon = {
    color: '#10B981',
    fontSize: '16px',
};

const DetailCommandeModal = ({ isOpen, onClose, commandeId }) => {
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const [commande, setCommande] = useState(null);

    const fetchCommande = async (commandeId) => {
        setChargementEnCours(true);
        try {
            const res = await getCommande(commandeId, token);
            setCommande(res);
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        } finally {
            setChargementEnCours(false);
        }
    };

    const renderStatutMission = (statut) => {
        switch (statut) {
            case "init": return "Non commencée";
            case "en-cours": return "En cours";
            case "terminer": return "Terminée";
            case "annuler": return "Annulée";
            default: return "Inconnu";
        }
    };

    const formatHeure = (heure) => {
        if (!heure || !heure.includes(':')) return "";
        const [hours, minutes] = heure.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) return "";
        return hours === 0 ? `${minutes}min` : `${hours}h${minutes > 0 ? minutes + "min" : ""}`;
    };

    const getForfaitDetails = (forfait) => {
        switch (forfait.type) {
            case 'kilometrage':
            case 'kilometrage_etage':
                return (
                    <>
                        <strong >Kilométrage :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.details?.kilometrage ?? 'N/A'} km</span>
                        <strong style={{ marginLeft: "1rem" }}>Étages :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.details?.etages ?? '0'}</span>
                        <strong style={{ marginLeft: "1rem" }}>Prix :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.prix}€ HT</span>
                    </>
                );
            case 'heure':
            case 'heure_etage':
                return (
                    <>
                        <strong >Heures :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.details?.heures ?? 'N/A'}</span>
                        <strong style={{ marginLeft: "1rem" }}>Étages :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.details?.etages ?? '0'}</span>
                        <strong style={{ marginLeft: "1rem" }}>Prix :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.prix}€ HT</span>

                    </>
                );
            case 'etage':
                return (
                    <>
                        <strong >Étages : {' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.details?.etages ?? '0'}</span>
                        <strong style={{ marginLeft: "1rem" }}>Prix :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.prix}€ HT</span>

                    </>
                );
            case 'forfait_gratuit':
                return (
                    <>
                        <strong>Heures :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.details?.heures ?? 'N/A'}</span>
                        <strong style={{ marginLeft: "1rem" }}>Prix :{' '}</strong> 
                        <span style={{ color: '#FF5A5F' }}>{forfait.prix}€ HT</span>

                    </>
                );
            default:
                return <span>Aucun détail disponible</span>;
        }
    };

    const getForfaitLabel = (type) => {
        switch (type) {
            case 'kilometrage': return 'Kilométrage';
            case 'kilometrage_etage': return 'Kilométrage et Étages';
            case 'heure': return 'Heures Supplémentaires';
            case 'heure_etage': return 'Heures et Étages';
            case 'etage': return 'Étages';
            case 'forfait_gratuit': return 'Forfait Gratuit';
            default: return type;
        }
    };

    const renderMissionDetails = (mission, index) => (
        <div key={index} style={sectionStyle}>
            <span style={title}>Détails de mission N° {index + 1}</span>
            <p style={detailsTitle}>Date de début : <span style={detailsSpan}>{mission?.date_debut} {mission?.heure_debut}</span></p>
            <p style={detailsTitle}>Date de fin : <span style={detailsSpan}>{mission?.date_fin} {mission?.heure_fin}</span></p>
            <p style={detailsTitle}>Adresse de début : <span style={detailsSpan}>{mission?.adresse_debut?.label}</span></p>
            <p style={detailsTitle}>Adresse de fin : <span style={detailsSpan}>{mission?.adresse_fin?.label}</span></p>
            <p style={detailsTitle}>Statut de la mission : <span style={detailsSpan}>{renderStatutMission(mission?.statut)}</span></p>
            <p style={detailsTitle}>Nombre de kilomètres : <span style={detailsSpan}>{mission?.kilometrage} KM</span></p>
            <p style={detailsTitle}>Nature du chargement : <span style={detailsSpan}>{mission?.nature_chargement}</span></p>
            
            {mission?.nature_chargement === "vrac" ? (
                <p style={detailsTitle}>Nombre de dimension : <span style={detailsSpan}>{mission?.nombre_dimension}</span></p>
            ) : (
                <p style={detailsTitle}>Nombre de palette : <span style={detailsSpan}>{mission?.nombre_palettes}</span></p>
            )}

            <p style={detailsTitle}>Lieu de livraison : <span style={detailsSpan}>{mission?.livraison}</span></p>
            
            {mission?.livraison === "Domicile/Batiment/Maison" && (
                <>
                    <p style={detailsTitle}>Nombre d'étage : <span style={detailsSpan}>{mission?.etage}</span></p>
                    <p style={detailsTitle}>Ascenseur : <span style={detailsSpan}>{mission?.assenceur ? "Oui" : "Non"}</span></p>
                    <p style={detailsTitle}>Escalier : <span style={detailsSpan}>{mission?.escalier ? "Oui" : "Non"}</span></p>
                </>
            )}

            <p style={detailsTitle}>Type de transport : 
                <span style={detailsSpan}>
                    {mission?.type_transport} {mission?.type_transport === "Poids lourds" ? mission?.vehicule_poids_lourds : mission?.type_transport_details}
                </span>
            </p>

            {mission?.type_transport === "Poids lourds" ? (
                <p style={detailsTitle}>Options poids lourd : <span style={detailsSpan}>{mission?.options_poids_lourds.join(', ')}</span></p>
            ) : (
                <p style={detailsTitle}>Options véhicules léger : <span style={detailsSpan}>{mission?.options_vehicule_leger.join(', ')}</span></p>
            )}

            <p style={detailsTitle}>Manutention : <span style={detailsSpan}>{mission?.manutention_chauffeur ? "Oui" :"Non"}</span></p>
            <p style={detailsTitle}>Nombre de rippeurs : <span style={detailsSpan}>{mission?.nombre_rippeur || 0}</span></p>
            <p style={detailsTitle}>Matières dangereuses : <span style={detailsSpan}>{mission?.danger ? "Oui" :"Non"}</span></p>
            <p style={detailsTitle}>Assurance : 
            <span style={detailsSpan}>{mission?.assurance ? "Oui" : "Non"}</span>
            </p>
            {mission?.assurance === "Oui" && (
            <p style={detailsTitle}>Montant de l'assurance : 
            <span style={detailsSpan}>{mission?.assurance_amount} €</span>
           </p>
            )}

            <p style={detailsTitle}>Notes utiles : <span style={detailsSpan}>{mission?.notes}</span></p>

            <div style={sectionStyle}>
                <span style={title}>Points de passage</span>
                {mission?.points_passage?.length > 0 ? (
                    mission.points_passage.map((point, idx) => (
                        <div key={idx} style={{ ...(idx !== 0 && { borderTop: '1px solid #D9D9D9A8', marginTop: '16px', paddingTop: '16px' })}}>
                            <p style={detailsTitle}>Adresse : <span style={detailsSpan}>{point.adresse.label}</span></p>
                            <div style={{ display: 'flex', gap: '24px' }}>
                            <p style={detailsTitle}>Distance : <span style={detailsSpan}>{point.distance}</span></p>
                            <p style={detailsTitle}>Heure : <span style={detailsSpan}>{formatHeure(point.heure) || '00min'}</span></p>
                            </div>
                            {idx!==0 &&(
                                <>
                                {point?.nom_destinataire && (
                                 <p style={detailsTitle}>Nom & Prénom du destinataire : <span style={detailsSpan}>{point.nom_destinataire}{' '}{point.prénom_destinataire}</span></p>
                                )}
    
                                 {point?.adresse_destinataire && (
                                 <p style={detailsTitle}>
                                  Adresse du destinataire : <span style={detailsSpan}>{point.adresse_destinataire}</span>
                                 {point?.téléphone_destinataire && (
                                  <span style={detailsSpan}>, Tél: {point.téléphone_destinataire}</span>
                                  )}
                                </p>
                                )}
    
                              {point?.email_destinateur && (
                              <p style={detailsTitle}>Email du destinataire : <span style={detailsSpan}>{point.email_destinateur}</span></p>
                              )}
    
                             <div style={{ display: 'flex', gap: '24px', marginTop: '8px' }}>
                              <p style={detailsTitle}>Signé : <span style={detailsSpan}>{point?.signé ? 'Oui' : 'Non'}</span></p>
                              <p style={detailsTitle}>Signalé : <span style={detailsSpan}>{point?.signaler ? 'Oui' : 'Non'}</span></p>
                              <p style={detailsTitle}>Activé : <span style={detailsSpan}>{point?.active ? 'Oui' : 'Non'}</span></p>
                               </div>
                                </>
                            )
                              
                            }
                        </div>
                    ))
                ) : (
                    <span style={detailsSpan}>Aucun point de passage disponible.</span>
                )}
            </div>

            <div style={sectionStyle}>
                <span style={title}>Forfaits supplémentaires</span>
                {mission?.forfaits?.length > 0 ? (
                    mission.forfaits.map((forfait, idx) => (
                        <div key={idx} style={{ ...(idx !== 0 && { borderTop: '1px solid #D9D9D9A8', marginTop: '16px', paddingTop: '16px' })}}>
                            <p style={detailsTitle}>Type : <span style={detailsSpan}>{getForfaitLabel(forfait.type)}</span></p>
                            <p style={detailsTitle}> <span style={detailsSpan}>{getForfaitDetails(forfait)}</span>
                            </p>
                        </div>
                    ))
                ) : (
                    <span style={detailsSpan}>Aucun forfait disponible.</span>
                )}
            </div>
        </div>
    );

    useEffect(() => {
        if (commandeId && isOpen) {
            fetchCommande(commandeId);
        }
    }, [commandeId, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            ariaHideApp={false}
            style={modalStyles}
        >
            <div style={headerStyle}>
                <h3 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>
                    {`Détail Commande N°${commande ? commande?.__id : ''}`}
                </h3>
                <button onClick={onClose} style={closeButtonStyle}>×</button>
            </div>

            {chargementEnCours ? (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    marginTop:"2rem",
                    marginBottom:"2rem"
                  }} >
                    <div className='customLoader'></div>
                </div>
            ) : (
                <div style={contentStyle}>
                    <div style={{ ...sectionStyle, marginTop: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={title}>Détails transport</span>
                            <div style={priceTag}>
                                <span>Prix : {commande?.prix}€ HT</span>
                            </div>
                        </div>

                        <p style={detailsTitle}>Type de transport : 
                            <span style={detailsSpan}>
                                {commande?.type_commande === "mise-a-disposition" ? "Mise à disposition" : "Course à course"}
                            </span>
                        </p>
                        <p style={detailsTitle}>Type de temporisation : 
                            <span style={detailsSpan}>
                                {commande?.temporisation === "immediat" ? "Immédiat" : "Planifié"}
                            </span>
                        </p>
                        <p style={detailsTitle}>Date du transport : 
                            <span style={detailsSpan}>
                                {moment(commande?.date_transport).format('DD/MM/YYYY HH:mm')}
                            </span>
                        </p>
                        <p style={detailsTitle}>Statut de la commande : 
                            <span style={detailsSpan}>
                                {renderStatutMission(commande?.statut)}
                            </span>
                        </p>
                        <p style={detailsTitle}>Statut de paiement : 
                            <span style={detailsSpan}>
                                {commande?.paymentStatus === 'payé' ? 'Payé' : 'Non Payé'}
                            </span>
                        </p>
                    </div>

                    {commande?.type_commande === "mise-a-disposition" && commande?.missions?.length > 0 && (
                        commande.missions.map((mission, index) => renderMissionDetails(mission, index))
                    )}

                    <div style={sectionStyle}>
                        <span style={title}>Informations sur le signataire</span>
                        <p style={detailsTitle}>Nom & Prénom : 
                            <span style={detailsSpan}>
                                {commande?.nom_signataire} {commande?.prénom_signataire}
                            </span>
                        </p>
                        <p style={detailsTitle}>Téléphone 1 : 
                            <span style={detailsSpan}>
                                {commande?.téléphone1_signataire}
                            </span>
                        </p>
                        <p style={detailsTitle}>Téléphone 2 : 
                            <span style={detailsSpan}>
                                {commande?.téléphone2_signataire}
                            </span>
                        </p>
                        <p style={detailsTitle}>Email : 
                            <span style={detailsSpan}>
                                {commande?.email_signataire}
                            </span>
                        </p>
                    </div>

                    <div style={sectionStyle}>
                        <span style={title}>Matériels supplémentaires</span>
                        <div>
                            <p style={detailsTitle}>Matériels de manutention :</p>
                            {commande?.manutention.map((item, index) => (
                                <div style={listItem} key={index}>
                                    <FaCheck style={icon} />
                                    <span style={detailsSpan}>{item}</span>
                                </div>
                            ))}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <p style={detailsTitle}>EPI :</p>
                            {commande?.epi.map((item, index) => (
                                <div style={listItem} key={index}>
                                    <FaCheck style={icon} />
                                    <span style={detailsSpan}>{item}</span>
                                </div>
                            ))}
                        </div>
                        {commande?.transpalette_électrique && (
                            <div style={listItem}>
                                <FaCheck style={icon} />
                                <span style={detailsSpan}>Transpalette électrique</span>
                            </div>
                        )}
                    </div>

                    <div style={sectionStyle}>
                        <span style={title}>Informations supplémentaires</span>
                        <p style={detailsTitle}>Référence interne client : 
                            <span style={detailsSpan}>
                                {commande?.ref_client}
                            </span>
                        </p>
                        <p style={detailsTitle}>Documents associés : 
                            {commande?.files?.length > 0 ? (
                                commande.files.map((file, index) => (
                                    <span key={index} style={{ marginLeft: '8px' }}>
                                        <a
                                            href={`${BASE_URL}/${file?.path}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: '#2196F3', textDecoration: 'none' }}
                                        >
                                            {file?.name}
                                        </a>
                                        {index < commande.files.length - 1 && ', '}
                                    </span>
                                ))
                            ) : (
                                <span style={detailsSpan}>Aucun document associé</span>
                            )}
                        </p>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default DetailCommandeModal;