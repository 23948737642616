import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
} from '../PasserCommande/PasserCommande.styles'
import Select from 'react-select'
import countryList from 'react-select-country-list'

import React, { useEffect, useState,useMemo  } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form';
import CommonHeader from '../../Components/CommonHeader/common-header.component'
import { CustomInput } from '../RegisterTransporter/style'
import Footer from '../../Components/Footer/footer.component'
import Modal from 'react-modal'
import UploadFile from '../../Components/UploadSingleFile/upload.component'
import { actions } from '../../redux/user'
import { loginAction } from '../../redux/auth'
import { signup } from '../../api/auth'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
Modal.setAppElement('#root')






function SignUp() {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const [modalIsOpen, setModalIsOpen] = useState(false)
	
    const [valueCountry, setValueCountry] = useState('')
     const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
    setValueCountry(value)
    }
	
	
	
    const onSubmit = async (data) => {
		try {		
		  if (data.password !== data.password_verification) {
			toast.error('Veuillez vérifier votre mot de passe', {
			  style: {
				fontFamily: 'Montserrat',
			  },
			});
			return;
		  }
		const files = [];
		const fileFields = [
		  'kbis', 
		  'piece_identite', 
		  'logo'
		];
	  
		fileFields.forEach(field => {
		  if (data[field]) {
			files.push({
			  [field]: data[field] 
			});
			delete data[field];  
		  }
		});
		delete data.logo
		delete data.kbis
		delete data.password_verification
		  data.role = 'client';
		  let _values = { ...data }
		  if(_values.pays.value==="FR"){
            _values.tva=true
		  }

		  setLoading(true)

		   const res=await signup(_values,files)
		
		  dispatch(loginAction(res.user, res.token))

		  setModalIsOpen(true)
		} catch (error) {
		  const data = error?.response?.data?.errors
		  if (data?.msg === 'EMAIL_ALREADY_EXISTS') {
			  toast.error(
				  'Ce mail est déjà utilisé par un autre utilisateur.',
				  {
					  style: {
						  fontFamily: 'Montserrat',
					  },
				  }
			  )
		  }else{
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}
		}finally{
			setLoading(false)

		}
	  };
	useEffect(() => {
		dispatch(actions.logOut())
	}, [dispatch])

	

	const { handleSubmit, control,formState: { errors},register,setValue } = useForm();
	return (
	<>
    <CommonHeader />
    <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>

                <GradientText>
					{'Créer un compte Client'.toUpperCase()}
				</GradientText>
	        
				<div
					style={{
						width: '60%',
						fontFamily: 'Montserrat',
					}}
				>
			<form onSubmit={handleSubmit(onSubmit)}>
			<FormSectionCard>
			                <div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('societe', {
											required: 'Veuillez indiquer le nom de société.',
											minLength: {
												value: 3,
												message: 'Le nom de la société doit contenir au moins 3 caractères.',
											  },

										})}
									/>
									{errors.societe  && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
									{errors.societe.message}
                                    </div>
                                   )}
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										SIRET <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('siret', {
											required: 'Veuillez indique le siret.',
											pattern: {
												value: /^[0-9]{14}$/,
												message: 'Le champ SIRET doit contenir 14 chiffres.',
											},
										})}
									/>
									  {errors.siret && (
                                       <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                       {errors.siret.message}
                                      </div>
                                      )}

								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('last_name', {
											required: 'Veuillez indiquer le nom',
											minLength: {
												value: 3,
												message: 'Le nom  doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },	
										})}
									/>
									{errors.last_name && (
                                    <div style={{ color: 'red', marginTop: '0.5rem' ,fontSize:'14px'}}>
                                     {errors.last_name.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									  {errors.first_name && (
                                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                       {errors.first_name.message}
                                       </div>
                                       )}
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('quality', {
											required: 'Veuillez indiquer la qualité du poste.',
											minLength: {
												value: 3,
												message: 'La qualité doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'La qualité doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.quality && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                      {errors.quality.message}
                                    </div>
                                     )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Domaine d’activité <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('domain', {
											required: 'Veuillez indiquer le domaine d\'activité.',
											minLength: {
												value: 2,
												message: 'Le domaine doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le domaine doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.domain && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                     {errors.domain.message}
                                    </div>
                                    )}
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Pays <span style={{ color: 'red' }}>*</span>
								</span>
								<Controller
						       name='pays'
						       rules={{ required: 'Veuillez sélectionner un pays.' }}
						      control={control}
	
						      render={({ field: { onChange, value } }) => (
						 	   <Select 
								
								placeholder=''
								options={options} 
								value={valueCountry}
								onChange={(selectedOption) => {
								  onChange(selectedOption);
								  changeHandler(selectedOption);
								}}
								styles={{
									control: (base,state) => ({
										...base,
										height: 50,
										minHeight: 50,
										border: 'none',
										borderRadius: '0.5rem',
										fontFamily: 'Montserrat',
									}),
								}} 
								/>
						)}
                        />
								
								{errors.pays && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.pays.message}
                                 </div>
                                )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									{...register('address', {
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^.+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
									})}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									{...register('city', {
									 	required: 'Veuillez indiquer la ville.',
										 minLength: {
											value: 3,
											message: 'La ville doit contenir au moins 3 caractères.',
										  },
										  pattern: {
											value: /^.+$/,
											message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										  },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},

										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                               )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
									{errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Mot de passe <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='password'
										{...register('password', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Confirmer mot de passe <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='password'
										{...register('password_verification', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password_verification && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password_verification.message}
                                    </div>
                               )}
								</div>
							</div>
							<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
								width: '100%',
								fontFamily: 'Montserrat',
								marginLeft: 10,
								marginTop: 10,
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Logo(PNG ou JPG)
								</span>
								<span
									style={{
										marginBottom: '1rem',
										color: '#838EAB',
										fontSize: 14,
									}}
								>
									(Facultatif)
								</span>
							</div>
							<Controller
                                    name="logo"
                                    control={control}
                                    render={({ field }) => (
                                    <UploadFile
                                     name={field.name}
                                     accept={".jpg, .png"}
                                     setValue={setValue}
                                     {...field}
                                    />
                                    )}
                                    />

							
						    </div>
							<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								fontFamily: 'Montserrat',
								marginLeft: 10,
								marginTop: 10,
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									KBIS (PDF)
								</span>
								<span
									style={{
										marginBottom: '1rem',
										color: '#838EAB',
										fontSize: 14,
									}}
								>
									(Facultatif)
								</span>
								
							</div>

							<Controller
                               name='kbis'
                               control={control}
                               render={({ field }) => (
                            <UploadFile
                            name={field.name}
                            accept={'.pdf'}
                            setValue={setValue}
                            {...field}
                            />
                            )}
                            />
						    </div>
							<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								fontFamily: 'Montserrat',
								marginLeft: 10,
								marginTop: 10,
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Pièce d'identité (PDF)
								</span>
								
							</div>
							
							<Controller
                               name='piece_identite'
                               control={control}
                               rules={{ required: "Veuillez télécharger la pièce d'identité "}}
                               render={({ field }) => (
						    <>
                            <UploadFile
                            name={field.name}
                            accept={'.pdf'}
                            setValue={setValue}
                            {...field}
                            />
						   {errors?.piece_identite && (
                           <span style={{ color: 'red', fontSize:'14px'  }}>
                            {errors?.piece_identite.message}
                           </span>
                           )}
						   </>
                            )}
                            />
						    </div>

			</FormSectionCard>
			<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
							{loading ? (
									<div className='loader'></div>
								) : (
									'S’inscrire'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
			</form>
			</div>
			</div>
	
	<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<GradientText>{'Félicitation'.toUpperCase()}</GradientText>

					<div
						style={{
							marginTop: 20,
						}}
					>
					Votre inscription a été enregistrée avec succès! 😃
					<br />
					 Un e-mail de confirmation a été envoyé à votre adresse e-mail 
					 <br />
					 Veuillez vérifier votre boîte de réception et cliquer sur le lien fourni pour finaliser la création de votre compte. 
					</div>

					<button
						onClick={() => {
							history.push('/')
						}}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
	</Modal>
	<Footer />		
	</>
	
	
	
	)
}

export default SignUp
