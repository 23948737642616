
import React, { useEffect, useState } from 'react'

import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import { GradientText,SubText } from '../PasserCommande/PasserCommande.styles'
import LayoutChauffeur from '../../Components/LayoutChauffeur/LayoutChauffeur'
import MiseADispoHorizontal from '../../Components/SingleMiseADispoHorizontalForChauffeur/MiseADispoHorizontal'
import { getCommandesAnnuleeByChauffeur,getCommandeByCityForChauffeur,getCommandeByIdForChauffeur,getCommandeBydateForChauffeur } from '../../api/commande'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import GooglePlacesAutoComplete from '../../Components/GooglePlacesAutoComplete/GooglePlacesAutoComplete'
import AutocompleteAddress from '../../Components/GooglePlacesAutoComplete/AutocompleteAddress'
import Select from 'react-select'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'
import toast from 'react-hot-toast'
import DetailCommandeModal from '../../Components/DetailCommande/DetailCommandeChauffeurModal'

const SIZE = 10
function CommandeAnnuléesChauffeur() {
	const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user)
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [adresseValue, setAdresseValue] = useState('');
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedDate, setSelectedDate] = useState(null);
	const [type,setType]=useState("annulees")
	const [total, setTotal] = useState(1)
	const [page, setPage] = useState(1)
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [selectedCommande,setSelectedCommande]=useState(null)
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 }
	 async function fetchCommandes(page) {
		setChargementEnCours(true)
		try {
		const res = await getCommandesAnnuleeByChauffeur(user._id,token,page,SIZE)
		setCommandes(res.commandes.docs);
		setTotal(res.totalPages);
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
		
	}


	useEffect(()=>{
			fetchCommandes(page)	
	},[page])

	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>{'ANNULÉES'.toUpperCase()}</GradientText>
				<SubText>
					Ici retrouver la totalité des transports annulés
				</SubText>

				<div
					style={{
						width: '90%',
						fontFamily: 'Montserrat',
						minHeight: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '1rem',
					}}
				>
					<div
						style={{
							width: '100%',
							fontFamily: 'Montserrat',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							marginBottom: '2rem',
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					
					>
						<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							// paddingLeft: '1rem',
							// paddingRight: '1rem',
							// marginBottom: '2rem',
							// marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'city', label: "Recherche par ville " },
								{ value: 'date', label: 'Date du transport' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>	
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
						
						/>
					) : null}
					{searchCriteria==="city" ?(
                        <div style={{ width: '20rem',}}> 
						<AutocompleteAddress 
						onPlaceSelect={(placeDetails) => {
						 }}
						 onValueChange={async (selectedValue) => {
						   if (selectedValue) {
							   setAdresseValue(selectedValue);
						   }}}
						   value={adresseValue}
	
						 />
						</div>
                    ): null}
                    
					
					{searchCriteria === 'date' ? (
							<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={selectedDate}
							type="date"
							onChange={(e) => setSelectedDate(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					<button
					onClick={async () => {
						if (
							searchCriteria === 'id' &&
							searchQueryId.length === 0
						) {
							return
						}
						let res
						if (searchCriteria === 'id') {
							res =
								await getCommandeByIdForChauffeur(
									user._id,
									searchQueryId,
									type,
									token
								)
						}
						if (searchCriteria === 'city') {
							res =
								await getCommandeByCityForChauffeur(
									user._id,
									adresseValue.label,
									type,
									token
									
								)
						}
						if (searchCriteria === 'date') {
							res =
								await getCommandeBydateForChauffeur(
									user._id,
									selectedDate,
									type,
									token
									
								)
								
						}
						setCommandes(res)
					} }
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
					onClick={async () => {
						await fetchCommandes(page)
					}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					</div>
                      {chargementEnCours ?
					    <div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  marginTop:"10rem",
						  marginLeft:"28rem"
						}}
						>
						<div className='customLoader'></div>	
						</div>
						:
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							width: '100%',
							height: '100%',
							alignItems: 'flex-start',
							marginBottom: '2rem',
						}}
					>
						{commandes.length === 0 ? 
						<span
						style={{
						
							
							display: 'flex',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'center',
							paddingLeft:'300px'
						}}
					>
						Il n'y a pas des commandes à afficher
					</span>
						:commandes.map((commande) => {
							if (commande.type_commande === 'course-a-course') {
								return (
									<CourseACourseHorizontal
										key={commande._id}
										commande={commande}
										hideAttribuer
										hideTarif
										hideRecu
										hidecancel
										hidefacture
										hideStatus
										hideUpdate
										enableSaveStatus
										onClickDetails={() => {
											history.push(
												`/chauffeur/transports-details/${commande._id}`
											)
										}}
										onClickSignaler={() => {
											history.push(
												`/chauffeur/transports/${commande._id}/signaler`
											)
										}}
									/>
								)
							} else {
								return (
									<MiseADispoHorizontal
										key={commande._id}
										commande={commande}
										hideStatus
										hideSignalement
										onClickDetails={() => {
											setSelectedCommande(commande?._id)
											setModalIsOpenDetail(true)
										}}
										onClickSignaler={() => {
											history.push(
												`/chauffeur/transports/${commande._id}/signaler`
											)
										}}
									/>
								)
							}
						})}
					</div>
                     }
				</div>
				<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={total}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>
			</div>
			<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
		</LayoutChauffeur>
	)
}

export default CommandeAnnuléesChauffeur

