import React, {  useState } from 'react'
import { verifyCodeSMSMission } from '../../api/commande'
import { StyledInput } from '../../Containers/PasserCommande/PasserCommande.styles'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import moment from 'moment'



function MissionCard({
  hideAssign  = false,
  hideHistoryChauffeur=false,
  hidecancel=false,
  hideFiles=false,
  hideAddForfait=false,
  hideValidateMission=false,
  hideChauffeur=false,
	mission,
	index,
	setChargement,
	onClickPlaces=()=>{},
  onClickForfaits=()=>{},
	onClickAddForfait=()=>{},
  onClickCancelMission=()=>{},
  onClickFiles=()=>{},
  onClickAttribuer = () => {},
  onClickTransferer = () => {},
  onClickHistoriqueChauffeur=()=>{}
   }) {
  const [loading,setLoading]=useState(false)
	const [enteredCode, setEnteredCode] = useState('');
  const token = useSelector((state) => state.auth.token);
	const [modalIsOpenSignature, setModalIsOpenSignature,] = useState(false)
  return (
	<div
	style={{
	  width: '100%',
	  fontFamily: 'Montserrat',
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'flex-start',
	  marginTop: '1rem',
	  paddingLeft: '3rem',
	  paddingRight: '3rem',
	}}
  >
	<div
	  style={{
		width: '100%',
		background: '#E4EAF0',
		display: 'flex',
		flexDirection: 'row',
		padding: '1rem',
		borderRadius: 10,
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginBottom: 10,
	  }}
	>
	  <div
		style={{
		  display: 'flex',
		  flexDirection: 'column',
		  fontSize: 13,
		  height: '100%',
		}}
	  >
		<span
		  style={{
			fontWeight: 'bold',
			color: '#51C7F2',
			marginBottom: 3,
		  }}
		>
		  Mission N° {index + 1} pour la commande {!!mission ? 'N°' + mission?.commande?.__id : ''}
		</span>
		<span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		  Adresse de début :{' '}
		  <span style={{ color: 'red' }}>{mission?.adresse_debut?.label}</span>
		</span>
		<span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		  Date de début :{' '}
		  <span style={{ color: 'red' }}>
			{mission?.date_debut} {mission?.heure_debut}
		  </span>
		</span>
		<span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		  Adresse de fin :{' '}
		  <span style={{ color: 'red' }}>{mission?.adresse_fin?.label}</span>
		</span>
		<span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		  Date de fin :{' '}
		  <span style={{ color: 'red' }}>
			{mission?.date_fin} {mission?.heure_fin}
		  </span>
		</span>
		{mission?.date_debut_reel &&
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Date de début réelle :{' '}
            <span style={{ color: 'red' }}>
              {moment(mission?.date_debut_reel).format('DD/MM/YYYY HH:mm') || 'Non spécifiée'}
            </span>
          </span>
          }
          {mission?.date_fin_reel && 
          <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
            Date de fin réelle :{' '}
            <span style={{ color: 'red' }}>
              {moment(mission?.date_fin_reel).format('DD/MM/YYYY HH:mm') || 'Non spécifiée'} 
            </span>
          </span>
          }
	  </div>
	  <div style={{ color: '#838EAB', display: 'flex', flexDirection: 'column', fontSize: 13 }}>
		 <span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		  Statut de la mission :{' '}
		  <span style={{ color: 'red' }}>
		  <span
					  style={{
						  marginRight: 15,
						  color: 'red'
					  }}
				  >
		   {mission?.statut==="init" && "Non commencée"}
		   {mission?.statut==="en-cours" && "En cours"}
		   {mission?.statut==="terminer" && "Terminée"}
		   {mission?.statut==="annuler" && "Annulée"}
		  </span>
		  </span>
		</span>
		<span style={{ color: '#838EAB', marginBottom: '1rem' }}>
		  Nombre de kilomètrage :{' '}
		  <span style={{ color: 'red' }}>{mission.kilometrage}</span>
		</span>
		<span style={{ color: '#838EAB' }}>
		  Nombre de ripper en plus du chauffeur :{' '}
		  <span style={{ color: 'red' }}>
			{mission.nombre_rippeur === null || mission.nombre_rippeur === undefined ? '0' : mission.nombre_rippeur}
		  </span>
		</span>
		<div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
		  <span style={{ color: '#838EAB', marginBottom: '.5rem' }}>
			{mission.type_transport} : {mission.type_transport === 'Poids lourds' ? mission.vehicule_poids_lourds : mission.type_transport_details}
		  </span>
		  <span style={{ color: '#838EAB', marginBottom: '.5rem' }}>
			{mission.type_transport === 'Poids lourds' ? mission.options_poids_lourds.join(',') : mission.options_vehicule_leger.join(',')}
		  </span>
		</div>
		<span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		  Matières dangereuses :{' '}
		  <span style={{ color: 'red' }}>{mission.danger  ? 'Oui' : 'Non'}</span>
		</span>
		<span style={{ marginRight: 13, marginBottom: '1rem', color: '#838EAB', width: '15rem' }}>
		Mission signée  :{' '}
		  <span style={{ color: 'red' }}>{mission.signé  ? 'Oui' : 'Non'}</span>
		</span>
		
	  </div>
	  <div style={{ color: '#838EAB', display: 'flex', flexDirection: 'column', fontSize: 13 }}>
	  {( !hidecancel && mission?.statut !== 'terminer' && mission?.statut !== 'annuler')&&
	  <button
              onClick={onClickCancelMission}
              style={{
                padding: '0.5rem 1rem',
                background: 'red',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginRight: 10,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                width: '170px',
                marginBottom: '.5rem',
              }}
            >
          Annuler
      </button>
      }
      


	  {(mission.statut==="en-cours" && !mission.signé && !hideAddForfait)&&
	  <button
                 onClick={onClickAddForfait}
                style={{
                  padding: '0.5rem 1rem',
                  background: '#51C7F2',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: 7,
                  marginRight: 10,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  width: '170px',
                  marginBottom: '.5rem',
                }}
              >
				Ajouter Forfait
      </button>
      }
	  {(mission.statut==="en-cours" && !mission.signé && !hideValidateMission) &&
	  <button
              onClick={()=>{setModalIsOpenSignature(true)}}
              style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginRight: 10,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                width: '170px',
                marginBottom: '.5rem',
              }}
            >
              Valider mission
     </button>
      }
    {!hideChauffeur && mission.chauffeurID && (
            <span style={{ color: 'black', fontWeight: 'bold', marginBottom: '.5rem' }}>
              {mission.chauffeurID.first_name} {mission.chauffeurID.last_name}
            </span>
    )}
    {!hideAssign && !mission.chauffeurID && (mission.statut === 'init' || mission.statut === 'en-cours') &&
    <button
     onClick={onClickAttribuer}  
      style={{
        padding: '0.5rem 1rem',
        background: '#50F5A9',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        borderRadius: 7,
        marginRight: 10,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        width: '170px',
        marginBottom: '.5rem',
        }}
        >
        Attribuer
    </button>
    }
    {!hideAssign && mission.chauffeurID && (mission.statut === 'init' || mission.statut === 'en-cours') &&
     <button
      onClick={onClickTransferer}
      style={{
            padding: '0.5rem 1rem',
            background: '#51C7F2',
            border: 'none',
            cursor: 'pointer',
            color: 'white',
            borderRadius: 7,
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            width: '170px',
          }}
        >
          Transférer
      </button>
    }
    {!hideHistoryChauffeur &&  mission.chauffeurID &&
     <button
       onClick={onClickHistoriqueChauffeur}
     style={{
       padding: '0.5rem 1rem',
       background: '#50F5A9',
       border: 'none',
       cursor: 'pointer',
       color: 'white',
       borderRadius: 7,
       marginRight: 10,
       fontFamily: 'Montserrat',
       fontWeight: 'bold',
       width: '170px',
       marginBottom: '.5rem',
       marginTop: '.5rem',
     }}
   >
     Historique Chauffeur
     </button>
    }

      {(mission?.statut !== 'init' && mission?.statut !== 'annuler') && (
      <>
      <button
      onClick={onClickPlaces}
      style={{
        padding: '0.5rem 1rem',
        background: '#50F5A9',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        borderRadius: 7,
        marginRight: 10,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        width: '170px',
        marginBottom: '.5rem',
      }}
    >
      Points passage
      </button>
    
      <button
      onClick={onClickForfaits}
      style={{
        padding: '0.5rem 1rem',
        background: '#51C7F2',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        borderRadius: 7,
        marginRight: 10,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        width: '170px',
        marginBottom: '.5rem',
      }}
    >
      Forfaits supplémentaires
      </button>
      </>
     )}
     {((mission.statut==="init" || mission.statut==="en-cours") && !mission.signé && !hideFiles )&&(
	  <button
     onClick={onClickFiles}
     style={{
      padding: '0.5rem 1rem',
      background: '#50F5A9',
      border: 'none',
      cursor: 'pointer',
      color: 'white',
      borderRadius: 7,
      marginRight: 10,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      width: '170px',
      marginBottom: '.5rem',
    }}
   >
    Gérer Documents
   </button>
     )}

	
		
	   
	  </div>
	</div>
	<Modal
    isOpen={modalIsOpenSignature}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 15, 
            padding: '2rem',
            width: '400px', 
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            border: 'none', 
        },
    }}
    contentLabel="Validation de la signature"
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                fontWeight: 'bold',
                fontSize: 22, 
                color: '#51C7F2',
                marginBottom: '1rem', 
            }}
        >
            Valider Mission
        </div>

        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <span
                style={{
                    marginBottom: '0.75rem', 
                    color: '#333', 
                    fontSize: 14,
                }}
            >
                Introduisez le code de validation
            </span>
            <StyledInput
                type="text"
                placeholder="Code"
                value={enteredCode}
                onChange={(e) => setEnteredCode(e.target.value)}
                style={{
                    width: '100%',
                    padding: '0.75rem',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    fontSize: '14px',
                    marginBottom: '1.5rem', 
                    outline: 'none',
                }}
               
            />
        </div>

        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <button
                onClick={async (e) => {
                    e.preventDefault();
					          if (!enteredCode) {
						        toast.error("Veuillez introduire le code", {
							        style: {
								      fontFamily: 'Montserrat',
							       },
					        	});
						        return;
					         }
					          setLoading(true);
                    try {
                        const response = await verifyCodeSMSMission(
                            { missionId: mission._id, code: enteredCode },
                            token
                        );
                        toast.success(response.message, {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        });
                        setChargement(true);
                    } catch (error) {
                      if (error.response?.status === 400 && error.response?.data?.message === "Cette mission a déjà été signée.") {
                        toast.error("Cette mission est déjà signée.", {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        });
                      }else{
                        toast.error("Une erreur s'est produite", {
                          style: {
                              fontFamily: 'Montserrat',
                          },
                      });
                      }
                      
                    } finally {
                        setLoading(false);
                        setEnteredCode('');
						            setModalIsOpenSignature(false)
                    }
                }}
                disabled={loading}
                style={{
                    width: '150px',
                    height: '50px',
                    background: '#5BBCEA',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'background-color 0.3s ease',
                }}
                
            >
                {loading ? (
                    <div className="loader"></div>
                ) : (
                    'Confirmer'
                )}
            </button>

            <button
                onClick={() => {
                    setModalIsOpenSignature(false);
                }}
                style={{
                    width: '150px',
                    height: '50px',
                    background: '#FF5B5B',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: 7,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#E54444')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#FF5B5B')}
            >
                Annuler
            </button>
        </div>
    </div>
    </Modal>
	
	
    </div>
  )
}

export default MissionCard