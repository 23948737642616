import React from 'react';

export const VehicleOptions = ({
  title,
  options,
  register,
  name,
  required,
  onOptionChange
}) => {
  return (
    <div style={styles.container}>
      <span style={styles.title}>
        {title}
      </span>
      <div style={styles.optionsContainer}>
        <div style={styles.optionsRow}>
          {options.map((option, index) => (
            <div key={index} style={styles.optionItem}>
              <input
                type="checkbox"
                style={styles.checkbox}
                {...register(name, { 
                    required:'Veuillez cocher au moins une option pour le type de transport.' }
                )}
                value={option}
                onChange={onOptionChange}
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    marginTop:"1rem"
  },
  title: {
    marginBottom: '16px',
    color: 'black',
    fontSize: '14px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  optionItem: {
    width: '240px',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: '16px',
  },
  errorText: {
    color: 'red',
    marginTop: '8px',
  },
};
