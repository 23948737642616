import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {  useParams,useHistory } from 'react-router'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import toast from 'react-hot-toast'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { CustomInput } from '../../RegisterTransporter/style'
import {
    getChauffeur,
	toggleSuspendreChauffeur
} from '../../../api/chauffeurs'
import { FormSectionCard,GradientText } from '../../PasserCommande/PasserCommande.styles'
import ActionModal from '../../../Components/ValidationModal/ActionModal'
function SingleChauffeur() {
	const { id } = useParams()
	const history = useHistory()
	const [chauffeur, setChauffeur] = useState(null)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [suspendre, setSuspendre] = useState("")
	const token = useSelector((state) => state.auth.token);
	const [loading, setLoading] = useState(false)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const isArchiveMode = window.location.pathname.includes('archived');  


	const  fetchChauffeur=async(id)=>{
		setChargementEnCours(true)
		try {
			const chauffeur = await getChauffeur(id,token)
			setChauffeur(chauffeur)
			setSuspendre(chauffeur.status)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
		});
		}finally{
			setChargementEnCours(false)

		}
	}
	useEffect(() => {
		if (id) {
			fetchChauffeur(id)
		}
	}, [id])
  return (
    <LayoutAdmin>
        <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>{`DÉTAIL CHAUFFEUR`} </GradientText>
				{chargementEnCours ?
			    <div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"10rem"
			}}
			>
			<div className='customLoader'></div>	
			    </div>
				:
				<>
				<div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
				<FormSectionCard>
						
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Fiche Chauffeur
								</p>
								
							</div>
                            {!isArchiveMode &&
							<div
								style={{
									display: 'flex',
								}}
							>
								<button
								onClick={() => {setModalIsOpen(true)}}
								style={{
									border: 'none',
									background: '#ECC9F7',
									color: '#AB1CC5',
									fontSize: 17,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
									padding: '0.6rem 1rem',
									borderRadius: 5,
									cursor: 'pointer',
								}}
							>
							{suspendre==="actif" ? 'Suspendre' : 'Activer'}
							</button>
								
								
								
							</div>
                            }
						</div>
					</FormSectionCard>
				</div>
				<div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
					
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Sexe
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '30%',
										}}
									>
										<input
											type='radio'
                                            disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											checked={chauffeur?.sexe === 'homme'}
											value={'homme'}
										/>

										<span>Homme</span>
									</div>

									<div>
										<input
											type='radio'
											disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											checked={chauffeur?.sexe === 'femme'}
											value={'femme'}
										/>

										<span>Femme</span>
									</div>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Permis
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Léger', 'Lourds', 'Super lourds'].map(
										(item, index) => (
											<div
												key={String(index)}
												style={{
													width: '20%',
												}}
											>
												<input
													type='checkbox'
                                                    disabled
													checked={chauffeur?.permis?.includes(item)}
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}
													value={item}
												/>

												<span>{item}</span>
											</div>
										)
									)}
								</div>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
										type='text'
										placeholder='Doe'
                                        disabled
										defaultValue={chauffeur?.last_name}
									    style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
									/>
									
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
                                        disabled
										placeholder='Doe'
										defaultValue={chauffeur?.first_name}
									    style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								        }}
										
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse
									</span>
									<CustomInput
                                        disabled
										type='text'
										placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
										defaultValue={chauffeur?.address}
									    style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								        }}
									/>
									
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									disabled
									defaultValue={chauffeur?.city}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									disabled
									defaultValue={chauffeur?.postalCode}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
								/>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
                                        disabled
										type='text'
										placeholder='(0) 01 45 66 32 88'
										defaultValue={chauffeur?.phone}
									    style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								       }}
										
									/>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
                                        disabled
										type='text'
										placeholder='John.doe@wayne.fr'
										defaultValue={chauffeur?.email}
									    style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								       }}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date de naissance
									</span>
								
											<Flatpickr
                                                disabled
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={chauffeur?.date_naiss}
												style={{
													backgroundColor: '#f0f0f0', 
													color: '#a9a9a9',           
													cursor: 'not-allowed'       
												   }}
											/>
											
										
									<div >
									
									</div>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date d’embauche
									</span>
									<Flatpickr
                                                disabled
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={chauffeur?.date_embauche}
												style={{
													backgroundColor: '#f0f0f0', 
													color: '#a9a9a9',           
													cursor: 'not-allowed'       
												   }}
											/>
									
								<div/>
								
								</div>
							</div>

							<div
                             style={{
                              display: 'flex',
                              flexDirection: 'column',
                               marginBottom: '2rem',
                             }}
                            >
                            <span
                             style={{
                              color: '#262626',
                               marginBottom: '1rem',
                             }}
                            >
                               Fimo
                            </span>
                            <div
                             style={{
                             display: 'flex',
                             flexDirection: 'row',
                             }}
                            >
                            {[
                            { label: 'Oui', value: true },
                            { label: 'Non', value: false },
                            ].map(({ label, value }, index) => (
                            <div
                              key={String(index)}
                              style={{
                               width: '20%',
                              }}
                             >
                            <input
                             type="radio"
                             disabled
                             checked={chauffeur?.fimo === value}
                             style={{
                              marginRight: '1rem',
                               transform: 'scale(1.5)',
                            }}
                               value={value}
                            />
                           <span>{label}</span>
                           </div>
                            ))}
                            </div>
                            </div>

                           <div
                            style={{
                             display: 'flex',
                             flexDirection: 'column',
                             marginBottom: '2rem',
                           }}
                          >
                         <span
                          style={{
                           color: '#262626',
                           marginBottom: '1rem',
                         }}
                        >
                         Matière dangereuse
                        </span>
                        <div
                         style={{
                          display: 'flex',
                          flexDirection: 'row',
                         }}
                        >
                        {[
                         { label: 'Oui', value: true },
                         { label: 'Non', value: false },
                        ].map(({ label, value }, index) => (
                         <div
                           key={String(index)}
                            style={{
                              width: '20%',
                            }}
                        >
                        <input
                          type="radio"
                          disabled
                          checked={chauffeur?.danger === value}
                          style={{
                           marginRight: '1rem',
                           transform: 'scale(1.5)',
                           }}
                          value={value}
                         />
                         <span>{label}</span>
                        </div>
                        ))}
                          </div>
                        </div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
							
							</div>
						</FormSectionCard>

						

						
					
				</div>
				</>
                }
		</div>
		<ActionModal
			  isOpen={modalIsOpen}
			  onRequestClose={()=>setModalIsOpen(false)}
			  title={suspendre==="actif" ? 'Suspendre Chauffeur' :  'Activer Chauffeur' }
			  message=	{`Êtes-vous sûr de vouloir ${suspendre==="actif" ? 'Suspendre' : 'Activer'} ce Chauffeur ?`}
			  onConfirm={async () => {	
				setLoading(true)
				try {
					const data={status:suspendre==="actif" ? "suspendu" :"actif"}
					const res=await toggleSuspendreChauffeur (
						chauffeur._id,
						data,
						token
					)
				toast.success(res.message, {
				style: {
					fontFamily: 'Montserrat',
				},
			})
			setSuspendre(suspendre==="actif" ? "suspendu" :"actif")
			history.goBack()
				} catch (error) {
					toast.error(
						error.response?.data.message || 'Erreur lors de la suspension du client',
						{
							style: {
								fontFamily: 'Montserrat',
							},
						}
					)
				}finally {
					setLoading(false)
					setModalIsOpen(false)
				}	
				}}
				
			  onCancel={()=>setModalIsOpen(false)}
			  isLoading={loading}
			/>
		
    </LayoutAdmin>
  )
}

export default SingleChauffeur