import React, { useEffect, useState } from 'react'
import {
	getFilteredTransporters,
} from '../../../api/transporteurs'

import DataTable from 'react-data-table-component'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { fullTextSearchTransporteurs } from '../../../api/search'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function MesTransporteurs() {
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [data, setData] = useState([])
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
    const [isSearching,setIsSearching]=useState(false)
	
	const fetchTransporteurs = async (page,perPage) => {
		setChargementEnCours(true)
		try {
		 let res
		 if(isSearching){
			if (searchQuery.length === 0) {
				res = await getFilteredTransporters(page, perPage,token)
			} else {
				res = await fullTextSearchTransporteurs(searchQuery,page,perPage,token)
			}
		 }else{
			res = await getFilteredTransporters(page, perPage,token)
		 }
		setData(res.docs)
		setTotalRows(res.totalDocs)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
				  fontFamily: 'Montserrat',
				},
			  });
		}finally{
			setChargementEnCours(false)
		}
		
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchTransporteurs(page,perPage)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchTransporteurs(page,newPerPage)
		setPerPage(newPerPage)	
	}
	const  handleSearch=async()=>{
		setIsSearching(true)
		setCurrentPage(1)
		if(isSearching){
			await fetchTransporteurs(1,perPage)
		}
	}
	const handelReset=async()=>{
		setIsSearching(false)
		setSearchQuery('')
		setCurrentPage(1)
		await fetchTransporteurs(1,perPage)
	}
	

	useEffect(() => {
		fetchTransporteurs(currentPage,perPage)
	}, [])
	useEffect(() => {
		fetchTransporteurs(currentPage,perPage)
	}, [isSearching])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width:"200px"
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Nom du transporteur',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Statut',
			selector: (row) => (row.status==="suspendu" ? 'Suspendu' : 'Activé'),
			sortable: false,
		},
		{
			name: "Date de suspension",
			selector: (row) => (row.status==="suspendu"  ? moment(row?.date_suspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
		},
		
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:"200px"
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/admin/transporter/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Mes transporteurs'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
						disabled={chargementEnCours}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSearch()							}
						}}
						placeholder='Rechercher par identifiant,nom transporteur / societe'
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '25rem',
							fontFamily: 'Montserrat',
						}}
					/>

					<button
						disabled={chargementEnCours}
						onClick={handleSearch}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={handelReset}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des transporteurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
		</LayoutAdmin>
	)
}

export default MesTransporteurs
