import React ,{useState,useEffect} from 'react'
import CommonHeader from '../../../Components/CommonHeader/common-header.component'
import { useParams,useHistory } from 'react-router-dom';
import { validateToken } from '../../../api/transporteurs';
import {uploadDocument} from '../../../api/upload'
import toast from 'react-hot-toast';
import { FileInputContainer } from '../../PasserCommande/PasserCommande.styles';
import File from  '../../../assets/img/file@2x.png'


function UpdateDocument() {
    const {token}=useParams()
    const history=useHistory()
    const [file, setFile] = useState(null);
    const [documentKey, setDocumentKey] = useState('');
    const [documentLabel,setDocumentLabel]=useState('')
    const [loading,setLoading]=useState(false)
    const accept = 'pdf';

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
          const fileType = selectedFile.type.split('/')[1];
          if (accept.includes(fileType)) {
            setFile(selectedFile);
          } else {
            alert(`Désolé, le fichier ${selectedFile.name} n'est pas pris en charge. Veuillez choisir un fichier au format ${accept.replace('.', '')}.`);
          }
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(!file){
            toast.error('Veuillez sélectionner un document',{
				style: {
					fontFamily: 'Montserrat',
				},
			})
            return
        }
        setLoading(true)
        try {
         const formData = new FormData();
         formData.append(documentKey,file)
         formData.append('token', token);
         await uploadDocument(formData)
         toast.success('Document mis à jour avec succès',{
            style: {
                fontFamily: 'Montserrat',
            },
        });
        history.push('/')
        } catch (error) {
            toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
        }finally{
            setLoading(false)
        }
    }
    useEffect(() => {
        const _validateToken = async () => {
          try {
            const response = await validateToken(token)
            setDocumentKey(response.documentKey);
            setDocumentLabel(response.documentLabel);
          } catch (err) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
          }
        };
        _validateToken();
      }, [token]);
  return (
    <div>
    <CommonHeader/>
   
    <div
				style={{
					height: '95vh',
					width: '100%',
					backgroundColor:'#f7f8f8',
					display: 'flex',
				    alignItems: 'center',
				    justifyContent: 'center',

				
				}}
			>
				<div
					style={{
						width: '100%',
						maxWidth: "600px",
						background:'white' ,
						borderRadius: "8px",
						padding: '2rem',
						boxShadow:"0 1px 3px rgba(0, 0, 0, 0.1)" ,
						fontFamily: 'Montserrat',

					}}
				>
					<h1 style={{
						 fontSize: "1.5rem",
						 fontWeight: 600,
						 textAlign: "center",
						 marginBottom: '2rem'
					}}>
          {`Mettre à jour le document` .toUpperCase()}
          </h1>
					<form
						onSubmit={handleSubmit}
						style={{							
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem'

						}}
					>
						<label
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<span
								style={{
									marginBottom: 7,
									fontSize: "14px",
									color:'#606060',
									fontWeight:500,
									lineHeight:"17px"
								}}
							>
              {documentLabel} {' '} (PDF)
              </span>
      <div
      style={{
        display: 'flex',
        flex: 1,
        borderRadius: '0.5rem',
        background: 'white',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        position: 'relative',
        border:'1px  solid #ccc'
      }}
    >
      {file && (
        <img
          src={File}
          style={{
            height: 20,
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
          alt='Fichier'
        />
      )}
      <span
        style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {file && file.name}
      </span>
      <FileInputContainer>
        <input
          type='file'
          id={documentKey}
          name={documentKey}
          className='file'
          accept={'.pdf'}
          onChange={handleChange}
        />
        <label htmlFor={documentKey}>Télécharger</label>
      </FileInputContainer>
    </div>
							
						</label>

						<button
							style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
								color: 'white',
								fontFamily: 'Montserrat',
								background: '#5BBCEA',
								border: 'none',
								padding: '1rem',
								cursor: 'pointer',
								fontWeight: 'bold',
								borderRadius: "8px",
							}}
							type='submit'
              disabled={loading}
						>
          {loading ? (
					<div className='loader'></div>
					) : (
						'Soumettre'
					)}						
          </button>						
					</form>
				</div>
			</div>

    </div>
  )
}

export default UpdateDocument