
import api from './base'

export const createSignalement = async (data,token) => {
	const res = await api.post('/signalements', data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getSignalement = async (id,token,page,perPage) => {
	const res = await api.get(`/signalements/${id}?page=${page}&limit=${perPage}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}
export const getSignalementForAdresse = async (id,token,page,perPage) => {
	const res = await api.get(`/signalements/adresse/${id}?page=${page}&limit=${perPage}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}  
export const getSignalementForCommandeMiseADispo=async (id,token,page,perPage) => {
	const res = await api.get(`/signalements/commande-mise-a-dispo/${id}?page=${page}&limit=${perPage}?nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}  
export const getAllSignalementsByCommandeID = async (commandeID,token,page,perPage) => {
	const res = await api.get(
		`/signalements/commande/${commandeID}?page=${page}&limit=${perPage}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)
	return res.data
}
export const getAllSignalementsByCommandeIDForAdmin = async (commandeID,token,page,perPage) => {
	const res = await api.get(
		`/signalements/forAdmin/commande/${commandeID}?page=${page}&limit=${perPage}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)
	return res.data
}
export const refundSignalement=async(signalementID,token)=>{
	const res = await api.get(
		`/signalements/refund/${signalementID}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
			
	)
	return res.data
} 
export const getAllSignalementsByCommandeIDByStatus = async (commandeID,token,status) => {
	const res = await api.get(
		`/signalements/commandePaid/${commandeID}/${status}?page=1&limit=100`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)
	return res.data
}
export const getSignalementForMission=async(missionID,token,page, size)=>{
	const res = await api.get(
		`/signalements/mission/${missionID}?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data  
}


export const updateSignalement = async (id, data,token) => {
	const res = await api.patch(`/signalements/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}



export const softDeleteSignalement = async (id,token) => {
	const res = await api.get(`/signalements/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const sendInvoiceSignalement = async (id,token) => {
	const res = await api.get(`/signalements/sendinvoice/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const checkDeletedSignalement=async (id,token)=>{
	const res = await api.get(`/signalements/check-deleted/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


