import api from './base'
export const getChauffeurs = async (page, size,token) => {
	const res = await api.get(
		`/users?filter=chauffeur&fields=role&page=${page}&limit=${size}&sort=name&order=-1&nocache=${Date.now()}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data
}
export  const getValidChauffeurs=async(page, size,token)=>{
	const res = await api.get(`/users/validated/all?filter=chauffeur&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export  const getNotValidChauffeurs=async(page, size,token)=>{
	const res = await api.get(`/users/not-validated/all?filter=chauffeur&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export const getChauffeursByTransporter = async (transporterId, page, size,token,filterStatus) => {
	const res = await api.get(
		`/users/transporter/${transporterId}/chauffeurs?status=${filterStatus}&page=${page}&limit=${size}&sort=name&order=-1&nocache=${Date.now()}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data
}
export const getValidChauffeursByTransporter = async (idTransporter,token) => {
	const res = await api.get(
		`/users/transporter/${idTransporter}/validchauffeurs?nocache=${Date.now()}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)
	return res.data
}

export const getChauffeur = async (_id,token) => {
	const res = await api.get(`/users/${_id}?nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const updateChauffeur = async (id,data,token) => {
	
	const res = await api.patch(`/profile/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}


export const toggleSuspendreChauffeur = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const  getChauffeursOfTransporterDeleted=async (id,token,page,size)=>{
	const res = await api.get(`/users/chauffeurfortransporteurdeleted/${id}/all?page=${page}&limit=${size}&sort=name&order=-1&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}