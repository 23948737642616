import rootReducer from '.'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: ['auth', 'commande'],
}

const middlewares = [thunkMiddleware]
const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = createStore(
	persistedReducer,
	process.env.NODE_ENV === 'development'
	  ? composeWithDevTools(applyMiddleware(...middlewares))
	  : applyMiddleware(...middlewares)
  );
  
  const persistor = persistStore(store);
  
  export { store, persistor };