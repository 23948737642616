import React, { useEffect, useState } from 'react'
import {getMissionsByCommandeId} from '../../api/commande'
import { useParams,useHistory } from 'react-router'
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles'
import toast from 'react-hot-toast'
import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import { useSelector } from 'react-redux'
import PointsPassageModal from '../../Components/PointsPassages/PointsPassageModal'
import MissionCard from '../../Components/MissionCard/MissionCard'
import ForfaitSupplementaireModal from '../../Components/ForfaitSupplementaire/ForfaitSupplementaireModal'
import MissionFilesModal from '../../Components/Files/MissionFilesModal'


function MissionsCommercial() {
    const  {id}=useParams()
    const history=useHistory()
    const token = useSelector((state) => state.auth.token);

    const [missions, setMissions] = useState(null)
	const [selectedMission,setSelectedMission]=useState(null)
    const [indexNumber,setIndexNumber]=useState(0)

    const [chargementEnCours,setChargementEnCours]=useState(false)
    const [chargement,setChargement]=useState(false)
	
	const [modalIsOpenPlace, setModalIsOpenPlace] = useState(false);
	const [modalIsOpenForfait,setModalIsOpenForfait]=useState(false)
    const [modalIsOpenFiles,setModalIsOpenFiles]=useState(false)

    async function fetchMissions(id) {
        setChargementEnCours(true)
        try {
			const res = await getMissionsByCommandeId(id,token)
			setMissions(res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        }finally{
            setChargementEnCours(false)

        }
       

    }
    
	
	

	useEffect(() => {
		if (id) {
			fetchMissions(id)
		}
	}, [id,chargement])
   
  return (
    <LayoutCommercant>
        <div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
                <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{`Gestion des  missions`.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginTop:'10rem'
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div> 
                   :
                   <div
                    style={{width:'100%',marginTop:'1rem'
                    }}>
                    {missions?.map((mission, index) => (
                    
		            <MissionCard 
					key={index}
					mission={mission}
					hideAssign
					hideHistoryChauffeur
					hideChauffeur
                    hideAddForfait
                    hideValidateMission
                    hidecancel
					onClickPlaces={()=>{
						setSelectedMission(mission)
		                setModalIsOpenPlace(true);
					}} 
					onClickForfaits={()=>{
						setSelectedMission(mission)
						setModalIsOpenForfait(true);
					}}
					onClickFiles={()=>{
						setSelectedMission(mission)
						setIndexNumber(index)
                        setModalIsOpenFiles(true)
					}}
					index={index}  
					setChargement={setChargement}
					
					/>
                   
					
                    ))}
                   </div>
                   }
        </div>
		<PointsPassageModal 
			  isOpen={modalIsOpenPlace} 
			  onClose={() => setModalIsOpenPlace(false)}
			  missionId={selectedMission?._id}
		/>
		<ForfaitSupplementaireModal
			 isOpen={modalIsOpenForfait} 
			 onClose={() => setModalIsOpenForfait(false)}
			 missionId={selectedMission?._id}
			 marge={0}	 
		/>
		<MissionFilesModal
		   isOpen={modalIsOpenFiles}
		   onClose={()=>setModalIsOpenFiles(false)}
		   missionId={selectedMission?._id}
		   index={indexNumber}
           hideAddFile={true}
           hideRemoveFile={true}
		/>
		
		 
		 

    </LayoutCommercant>
  )
}

export default MissionsCommercial