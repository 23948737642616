import React, { useEffect, useState } from 'react'
import { GradientText ,Wrapper,StyledInput} from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import {
	searchCommandesByDateForClient,
	searchCommandesByPrixMinMaxForClient,
	searchCommandesByIdForClient
} from '../../../api/clients'
import CourseACourseHorizontal from '../../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import MiseADispoHorizontal from '../../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import Select from 'react-select'
import { getAllCommandesByClientId } from '../../../api/commande'
import moment from 'moment'
import { useHistory ,useParams} from 'react-router'
import { useSelector } from 'react-redux'
import _ from 'lodash'
function ListCommandes() {
    const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const clientID = useSelector((state) => state.auth.user._id)
    const {id}=useParams()
	const token = useSelector((state) => state.auth.token);
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [searchQueryId, setSearchQueryId] = useState('')
	const [type,setType]=useState("commande")
	const [total, setTotal] = useState(0)
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };

	async function fetchCommandes(clientID) {
		const commandes = await getAllCommandesByClientId(clientID,token)
		console.log(commandes)
		setCommandes(commandes.docs)
		const totalPackagePrice = _.sum(
			commandes.docs.map((cmd) =>
			  _.sumBy(cmd.packages, (pkg) =>(pkg.price))
			)
		); 
		const totalCommandPrice=_.sum(commandes.docs.map((cmd) => cmd.prix))
		const total=(totalCommandPrice+totalPackagePrice).toFixed(2)
		setTotal(total)
		setSearchQueryId('')
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		  setSearchCriteria("id")
	}



	useEffect(() => {
		fetchCommandes(id)
	}, [clientID])

  return (
    <LayoutAdmin>
        <Wrapper>
				<GradientText>{'commandes'.toUpperCase()}</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
						onClick={async () => {
							let res
							if (searchCriteria === 'id') {
								res =
									await searchCommandesByIdForClient(
										id,
										searchQueryId,
										type,
										token
										
									)
							}
							if (searchCriteria === 'prix') {
								res =
									await searchCommandesByPrixMinMaxForClient(
										min,
										max,
										id,
										type,
										token
									)
							}

							if (searchCriteria === 'date') {
								let minDate = moment(range[0]).format()
								let maxDate = moment(range[1]).format()
                                let offsetUTC = moment().format("Z");
								
								res = await searchCommandesByDateForClient(
									minDate,
									maxDate,
									id,
									type,
									token,
									offsetUTC
								)
							}
                             
							setCommandes(res)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={async () => {
							await fetchCommandes(id)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginRight: '1rem',
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					<div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{total}€</span>
					</div>
				</div>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							//justifyContent: 'center',
						}}
					>
				
						<div
							style={{
								width: '10rem',
								height: 1,
								background: '#F2F2F2',
							}}
						/>
					</div>
					{ commandes.length === 0 ? 
					<span
					style={{
					
						
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					Il n'y a pas des commandes à afficher
				</span>
				:commandes.map((commande) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidefacture
                                    hideUpdate
                                    hidecancel
									hideStatus
									hideSignaler
									onClickDetails={() => {
										history.push(`/admin/plateforme/commandes/${commande._id}`		
								
										)
									}}
									
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
									
									
									
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidefacture
									hideStatus
                                    hideUpdate
                                    hidecancel
									hideSignaler
									
									onClickDetails={() => {
										history.push(
											`/admin/plateforme/commandes/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
								
								
								/>
							)
						}
					})}
				</div>
			</Wrapper>
    </LayoutAdmin>
  )
}

export default ListCommandes