import React from 'react'
import Footer from '../../Components/Footer/footer.component'
import CommonHeader from '../../Components/CommonHeader/common-header.component'
import { useHistory } from 'react-router'

export default function CreateAccount() {
	const history = useHistory()

	return (
		<div>
			<CommonHeader />
			<div
				style={{
					height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					backgroundColor:'#f7f8f8'

				}}
			>

				<div style={{
					 color: '#000',
					 fontFamily: 'Montserrat, sans-serif',
					 fontWeight: 400,
					 fontSize: '32px',
					 textAlign: 'center',
					 lineHeight: '1.2',
				}}>
				Créer un compte
				</div>
				<div style={{ height: 50 }} />

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							//width: 200,
							maxWidth: 400,
							padding: '3rem 3rem',
							background: '#fff',
							borderRadius: 10,
							display: 'flex',
							flexDirection: 'column',
							fontFamily: 'Montserrat',
						}}
					>
						<span
							style={{
								marginBottom: '2rem',
								fontSize: '14px',
                                fontWeight:400,
								textAlign: 'center',
								color:"#606060",
								lineHeight:"17.07px"
							}}
						>
							Vous êtes clients et vous souhaitez commander un ou
							plusieurs transports
						</span>

						<button
							onClick={() => history.push('/client/signup')}
							style={{
								color: 'white',
								border: 'none',
								background: '#5BBCEA',
								padding: '1rem 2rem',
								borderRadius: 10,
								cursor: 'pointer',
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Créer un compte Client
						</button>
					</div>

					<div style={{ width: 50 }} />

					<div
						style={{
							//width: 200,
							maxWidth: 400,
							padding: '3rem 3rem',
							background: '#fff',
							borderRadius: 10,
							display: 'flex',
							flexDirection: 'column',
							fontFamily: 'Montserrat',
						}}
					>
						<span
							style={{
								marginBottom: '2rem',
								fontSize: '14px',
                                fontWeight:400,
								textAlign: 'center',
								color:"#606060",
								lineHeight:"17.07px",
							}}
						>
							Vous êtes un transporteur et vous souhaitez vous
							inscrire pour effectuer des transports
						</span>

						<button
							onClick={() => history.push('/transporter/signup')}
							style={{
								color: 'white',
								border: 'none',
								background: '#5BBCEA',
								padding: '1rem 2rem',
								borderRadius: 10,
								cursor: 'pointer',
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Créer un compte Transporteur
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
