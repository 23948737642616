import {
	GradientText,
	SubText,
	Wrapper,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { getCommande} from '../../../api/commande'
import { useParams,useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment'

const title={
    color:'#25A6E4',
    fontSize:'16px',
    fontWeight: 500,
    lineHeight: '19.5px'
}
const details = {
	padding: "15px",
	marginTop: '10px',
	marginBottom: "10px",
	borderTop: '1px solid #D9D9D9A8',
  };
const detailsTitle = {
	color: "#333333",
	fontSize: "14px",
	fontWeight: 400,
	lineHeight: '19.5px',
};
const detailsSpan = {
	color: "#606060",
	fontSize: "14px",
	fontWeight: 400,
	lineHeight: '19.5px',
};
function DetailCommandeAdmin() {
	const { id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [commande, setCommande] = useState(null)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const history=useHistory()

	const  fetchCommande=async(id)=>{
		setChargementEnCours(true)
		try {
			const res=await getCommande(id,token)
			setCommande(res)
			console.log('res',res)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
		}finally{
         setChargementEnCours(false)
		}

	}

	useEffect(() => {
		
		if (id) {
			fetchCommande(id)
		}
	}, [id])

	return (
		<LayoutAdmin>
			<Wrapper>
			<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop:'1rem'
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

                    <GradientText  marginTop={'unset'}>{`${'Détail COMMANDE'.toUpperCase()} ${!!commande ? ' N°' + commande.__id : '' }`}</GradientText>

			</div>
				<SubText>
					Ici retrouver le détail complet du transport à effectuer
				</SubText>
				{chargementEnCours ?
					<div
					style={{
					  display: 'flex',
					  alignItems: 'center',
					  justifyContent: 'center',
					  backgroundColor: 'white',
					  marginTop:"10rem"
					}}
					>
					<div className='customLoader'></div>	
					</div>
					 :
					 <>
					 <div
						 style={{
							 width: '65%',
						 }}
					 >
						<div
	                    style={{
		                    padding: '0rem 1em',
		                    color: 'white',
		                    display: 'flex',
		                    flexDirection: 'row',
		                    alignItems: 'center',
		                    justifyContent: 'space-between',
		                    borderRadius: '0.5rem',
		                    fontSize: 15,
		                }}
		                >
		                <span style={title}>Détails transport</span>
                        <div
			            style={{
				        padding: '1rem 1em',
			            background: '#5BBCEA',
				        color: 'white',
				        fontFamily: 'Montserrat',
				        display: 'flex',
				        flexDirection: 'row',
				        alignItems: 'center',
				        justifyContent: 'space-between',
				        borderRadius: '0.5rem',
				        fontSize: 15,
				       }}>
                       <span>Prix: </span>
		               <span style={{ width: 20 }} />
	                   <span style={{ fontWeight: 'bold' }}>
                        {commande?.prix}  € ht
	                   </span>
							
		               </div>
					   <div style={details}>
                        <p style={detailsTitle}> Type de transport : {' '}
							  <span style={detailsSpan}>
								{commande?.type_commande==="mise-a-disposition" ? "Mise à disposition" :"Course à course"}
								</span>
							  </p>
							  <p  style={detailsTitle}> Type de temporisation: {' '}
							  <span style={detailsSpan}>
								{commande?.temporisation==="immediat" ? "Immédiat" :"Planifié"}
							  </span>
							  </p>
							  <p style={detailsTitle}>
							   Date du transport: {' '}
							  <span style={detailsSpan}>
								{moment(commande?.date_transport).format('DD/MM/YYYY HH:mm')}
								</span>
							  </p>
							  {/* <p style={detailsTitle}>
							  Statut de la commande: {' '}
							  <span style={detailsSpan}>{renderStatutMission(commande?.statut)}</span>
							  </p> */}
							  <p style={detailsTitle}>
							  Statut de paiement: {' '}
							  <span style={detailsSpan}>
							 {commande?.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}
							 </span>
							  </p>
					   </div>
                       </div> 
						
					 </div>
					
					 </>
				}
               

				
			</Wrapper>
		</LayoutAdmin>
	)
}

export default DetailCommandeAdmin
