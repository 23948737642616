import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'
import ScrollToTop from '../Components/ScrollToTop'
import SnackbarProvider from 'react-simple-snackbar'
import SuspendedGuard from '../Components/SuspendedGuard'
import TarifConfig from '../Components/TarifConfig'
import { Toaster } from 'react-hot-toast'
import {store,persistor} from '../redux/configureStore'
import CookieConsent from './CookieConsent/CookieConsent'

function App() {

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<SnackbarProvider>
					<Router>
						<ScrollToTop />
						<CookieConsent/>
						<SuspendedGuard />
						<TarifConfig />
						<Routes />
					</Router>
				</SnackbarProvider>
				<Toaster />
			</PersistGate>
		</Provider>
	)
}

export default App
