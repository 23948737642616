import React from 'react';
import LayoutAdmin from '../LayoutAdmin/LayoutAdmin';
import LayoutChauffeur from '../LayoutChauffeur/LayoutChauffeur';
import LayoutCommercant from '../LayoutCommercant/LayoutCommercant';
import LayoutTransporter from '../LayoutTransporter/LayoutTransporter';
import Container from '../LayoutClient/container.component';

const LayoutSwitcher = ({ layout, children }) => {
  switch (layout) {
    case 'transporter':
      return <LayoutTransporter>{children}</LayoutTransporter>;
    case 'client':
      return <Container>{children}</Container>;
    case 'commercial':
      return <LayoutCommercant>{children}</LayoutCommercant>;
    case 'chauffeur':
      return <LayoutChauffeur>{children}</LayoutChauffeur>;
    case 'admin':
    case 'admin comptabilité':
    case 'admin service après vente':
    case 'super admin':
    case 'admin distribution':
      return <LayoutAdmin>{children}</LayoutAdmin>;
    default:
      return null; 
  }
};

export default LayoutSwitcher;
