import Footer from '../../Components/Footer/footer.component'
import React,{useEffect} from 'react'
import { login } from '../../api/auth'
import { loginAction } from '../../redux/auth'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory,useLocation  } from 'react-router'
import { useSnackbar } from 'react-simple-snackbar'
import CommonHeader from '../../Components/CommonHeader/common-header.component'

function SignInV2() {
	const history = useHistory()
	const location = useLocation();
	const dispatch = useDispatch()
	const { register, handleSubmit,formState: { errors, isValid, isDirty } } = useForm()
	const [openSnackbar] = useSnackbar({
		position: 'top-center',
		style: {
			color: 'black',
			background: 'white',
			fontFamily: 'Montserrat',
		},
		closeStyle: {
			fontSize: '2rem',
			color: '#51C7F2',
		},
	})
    useEffect(()=>{
		const getQueryParam = (name) => {
			const urlSearchParams = new URLSearchParams(location.search);
			return urlSearchParams.get(name);
		  };
		  const email_verified = getQueryParam('email_verified');
		  const email_verifiedtransporter=getQueryParam('email-verifiedtransporter');
		  if (email_verified === 'true') {
			openSnackbar(
                 'Nous avons bien vérifié votre adresse e-mail. Vous pouvez maintenant vous connecter à notre plateforme.',
				 120000
			)
		  }
		  if(email_verifiedtransporter==='true'){
			openSnackbar(
				"Nous avons bien vérifié votre adresse e-mail. Cependant, veuillez patienter jusqu'à ce que votre compte soit validé afin de pouvoir vous connecter à notre plateforme.",
				120000
			)
		  }
	},[])
	
	const onSubmit = async (data) => {
		try {
			const res = await login(data.email, data.password)
			dispatch(loginAction(res.user, res.token,res.refreshToken))

			if (res.user.role === 'chauffeur') {
				history.push('/chauffeur/transports')
			}

			if (res.user.role === 'client') {
				history.push('/commandes/new')
			}
			if (res.user.role === 'commercial') {
				history.push('/commercial/client/new')
			}
			if (res.user.role === 'transporter') {
					history.push('/transports');
			}
			if ((res.user.role === 'admin') || (res.user.role === 'super admin')|| (res.user.role === 'admin distribution')||(res.user.role==='admin service après vente')) {
				history.push('/admin/clients')
			}
			if(res.user.role==='admin comptabilité'){
				history.push('/admin/plateforme/facturation')
			}
		} catch (error) {
			console.log('error',error.response?.data?.message)
			const errorMessage = error.response?.data?.message || 'Erreur de connexion, veuillez vérifier votre email et votre mot de passe.';
			toast.error(errorMessage,
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}
	}
	

	return (
		<div>
			<CommonHeader />
			<div
				style={{
					height: '95vh',
					width: '100%',
					backgroundColor:'#f7f8f8',
					display: 'flex',
				    alignItems: 'center',
				    justifyContent: 'center',

				
				}}
			>
				<div
					style={{
						width: '100%',
						maxWidth: "600px",
						background:'white' ,
						borderRadius: "8px",
						padding: '2rem',
						boxShadow:"0 1px 3px rgba(0, 0, 0, 0.1)" ,
						fontFamily: 'Montserrat',

					}}
				>
					<h1 style={{
						 fontSize: "1.5rem",
						 fontWeight: 600,
						 textAlign: "center",
						 marginBottom: '2rem'
					}}>
                    Bienvenue à nouveau !
                    </h1>
					<form
						onSubmit={handleSubmit(onSubmit)}
						style={{							
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem'

						}}
					>
						<label
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<span
								style={{
									marginBottom: 7,
									fontSize: "14px",
									color:'#606060',
									fontWeight:500,
									lineHeight:"17px"
								}}
							>
								Adresse email 
							</span>
							<input
								{...register('email', {
									required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#333',
									borderRadius: "8px",
									background: 'white',
									padding: '0.75rem',
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
									':focus': {
										outline: 'none',
										border: '1px solid #5BBCEA',
									  }
									
                             
								}}
								name='email'
								placeholder='Email'
								type='text'
							/>
							{errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                               )}
						</label>

						<label
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: 7,
										fontSize: "14px",
										color:'#606060',
										fontWeight:500,
										lineHeight:"17px"
									}}
								>
									Mot de passe 
								</span>
								
							</div>
							<input
								{...register('password', {
									required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#333',
									borderRadius: "8px",
									background: 'white',
									padding: '0.75rem',
                                    border: '1px solid #D9D9D9',
                                    width: '100%',
								}}
								name='password'
								placeholder='Mot de passe'
								type='password'
							/>
							{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
						</label>
						<span
									style={{
										textAlign:'right',
										fontSize: 12,
										cursor: 'pointer',
									}}
									onClick={()=>history.push('/forgot')}

								>
									Mot de passe oublié ?
								</span>
						<button
							style={{
								color: 'white',
								fontFamily: 'Montserrat',
								background: '#5BBCEA',
								border: 'none',
								padding: '1rem',
								cursor: 'pointer',
								fontWeight: 'bold',
								borderRadius: "8px",
							}}
							type='submit'
						>
							Connexion
						</button>
						
						<button
						 onClick={() => {
							history.push('/CreateAccount')
						 }}
							style={{
								color: '#5BBCEA',
								fontFamily: 'Montserrat',
								background:'white',
								border: '1px solid #5BBCEA',
								padding: '1rem',
								cursor: 'pointer',
								fontWeight: 'bold',
								borderRadius: "8px",
							}}
						>
                        créer un nouveau compte					
                        </button>
						
					</form>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default SignInV2
