import React from 'react';

const RadioGroup = ({ options, name, register, required, disabled, label,errors }) => {
  return (
    <div>
      <p 
        style={{
			marginBottom: '1rem',
			color: 'black',
			fontSize: 14,
		}}>
        {label}
      </p>
      <div 
        style={{
			display: 'flex',
			flexDirection: 'row',
			marginBottom: '1rem',
		}}>
        {options.map((option) => (
          <div key={option.value} 
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '15rem',
          }}>
            <input
             style={{
                marginRight: '1rem',
             }}
              type="radio"
              id={`${name}-${option.value}`}
              disabled={disabled}
              {...register(name, { required })}
              value={option.value}
            />
            <label htmlFor={`${name}-${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {errors[name] && (
        <div style={{ color: 'red', marginTop: '0.5rem' }}>
          {errors[name].message || `Veuillez sélectionner une option pour ${label.toLowerCase()}`}
        </div>
      )}
    </div>
  );
};

export default RadioGroup;