import React, { useEffect, useState } from 'react'
import {
	attributionCommandeForSalon,
	attributionCommandesTransporter,
	softDeleteCommande,
	getCommandesPaginatedForAdmin,
	updateCommandesMargin,

} from '../../../api/commande'
import toast from 'react-hot-toast'
import {
	searchCommandesByClientAndTransporter,
	searchCommandesByDate,
	searchCommandesByPrixMinMax,
	searchCommandesById
} from '../../../api/search'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { BASE_URL } from '../../../api/base'
import Select from 'react-select'
import { getValidTransporters} from '../../../api/transporteurs'
import moment from 'moment'
import { useHistory } from 'react-router'
import DetailCommandeModal from '../../../Components/DetailCommande/DetailCommandeAdminModal'
import ActionModal from '../../../Components/ValidationModal/ActionModal'

Modal.setAppElement('#root')

function Commandes() {
	const history = useHistory()
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [loadingSalon,setLoadingSalon]=useState(false)
	const [loadingAttribuer,setLoadingAttribuer]=useState(false)
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [modalIsOpenSalon,setModalIsOpenSalon]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [marginModalIsOpen, setMarginModalIsOpen] = useState();
	const [margin, setMargin] = useState(0);
	const [commande,setCommande]=useState('')
	const [transporters, setTransporters] = useState([])
	const [selected, setSelected] = useState([])
	const [clearSelected, setClearSelected] = useState(false);
	const [selectedTransporter, setSelectedTransporter] = useState(null)
	const [searchQueryId, setSearchQueryId] = useState('')
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [searchQuery, setSearchQuery] = useState('')
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const type="commande"
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [selectedCommande,setSelectedCommande]=useState(null)
	const [selectedOption, setSelectedOption] = useState({
		 value: 'id', label: "Recherche par l'identifiant " 
	});
	const [isSearching, setIsSearching] = useState(false);

	  const handleChange = (selectedOption) => {
		setSelectedOption(selectedOption);
	    setSearchCriteria(selectedOption.value)
	  };

	 
	  const fetchCommandes = async (page, perPage, isSearching) => {
        setChargementEnCours(true);
        try {
            let res;
            if (!isSearching) {
                res = await getCommandesPaginatedForAdmin(page, perPage, token);
            } else {
                switch (searchCriteria) {
                    case 'id':
                        if (!searchQueryId.trim()) {
                            toast.error('Veuillez saisir un identifiant', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        res = await searchCommandesById(searchQueryId, type, token);
                        break;
                    case 'ct':
                        if (!searchQuery.trim()) {
                            toast.error('Veuillez saisir un nom de client', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        res = await searchCommandesByClientAndTransporter(searchQuery, type, token, page, perPage);
                        break;
                    case 'prix':
                        if (Number(min) > Number(max)) {
                            toast.error('Le prix minimum doit être inférieur au prix maximum', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        res = await searchCommandesByPrixMinMax(min, max, type, token, page, perPage);
                        break;
                    case 'date':
                        if (!range || !range[0] || !range[1]) {
                            toast.error('Veuillez sélectionner une plage de dates', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        const minDate = moment(range[0]).format();
                        const maxDate = moment(range[1]).format();
                        const offsetUTC = moment().format('Z');
                        res = await searchCommandesByDate(minDate, maxDate, type, token, page, perPage, offsetUTC);
                        break;
                    default:
                        return;
                }
            }
            setData(res?.commandes || []);
            setTotalRows(res?.totalCommandes || 0);
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la recherche", {
                style: { fontFamily: 'Montserrat' },
            });
        } finally {
            setChargementEnCours(false);
        }
    };

    const handleSearch = async () => {
        setIsSearching(true);
        setCurrentPage(1);
        await fetchCommandes(1, perPage, true);
    };
	const handleClear = async () => {
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		})
		setSearchCriteria('id')
		setSearchQuery('');
		setSearchQueryId('');
		setMin(0);
		setMax(300);
		setRange([new Date(), new Date()]);
		setIsSearching(false);
		setCurrentPage(1);
	
		await fetchCommandes(1, perPage, false);
	};
	
	

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchCommandes(page,perPage,isSearching)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchCommandes(page,newPerPage,isSearching)
		setPerPage(newPerPage)
	}

	const fetchTransporters = async () => {
		try {
		const res = await getValidTransporters(token)
		setTransporters(res)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
		}
		
	}
    const handleSelectedRowsChange = (state) => {
		setSelected(state.selectedRows);
		setClearSelected(false); 
	};
	const findClosestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
	
		if (validMissions.length === 0){
			const sortedAnnulerMissions=missions
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
			return sortedAnnulerMissions[0]
		}
	
		return validMissions[0];
	};
	const findLatestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
				return bDateTime - aDateTime;
			});
			
			
		if (validMissions.length === 0){
				const sortedAnnulerMissions=missions
				.sort((a, b) => { 
					const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
					const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
					return bDateTime - aDateTime;
				});
				return sortedAnnulerMissions[0]
			}
		return validMissions[0];
	};
	useEffect(() => {
		fetchCommandes(currentPage,perPage,isSearching)
		fetchTransporters()
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			width: '110px',
			sortable: false,
		},
		{
			name: 'Type du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: '#51C7F2',
							marginBottom: 3,
						}}
					>
						{row.type_commande == 'course-a-course'
							? 'Course à course'
							: 'Mise à disposition'}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		{
			name: 'Date du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							marginBottom: 3,
						}}
					>
					{moment(row?.date_transport).format('DD/MM/YYYY HH:mm')}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		
		{
			name: 'Date début',
			selector: (row) => {


				if (row.type_commande === 'mise-a-disposition') {
					const closestMission = findClosestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{closestMission?.date_debut}</span>
							<span style={{marginLeft:'0.5rem'}}>{closestMission?.heure_debut}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0]?.date_debut}</span>
								<span style={{marginLeft:'0.5rem'}}>{row.courses[0]?.heure_debut}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Date fin',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const latestMission = findLatestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{latestMission?.date_fin}</span>
							<span style={{marginLeft:'0.5rem'}}>{latestMission?.heure_fin}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								
								<span>{row.courses.length===1 ? row.courses[0]?.date_fin :row.courses[row.courses.length-1]?.date}</span>
								<span style={{marginLeft:'0.5rem'}}> {row.courses.length===1 ? row.courses[0]?.heure_fin : row.courses[row.courses.length-1]?.heure}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Départ',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const closestMission = findClosestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
							<span>{closestMission?.adresse_debut?.label}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0]?.adresse_debut?.label}
									
								</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: ' Arrivé',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const latestMission = findLatestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{latestMission?.adresse_fin?.label}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length===1 ? row.courses[0]?.adresse_fin?.label :row.courses[row.courses.length-1]?.adresse?.label}</span>
						</div>	
					)
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: 'Etapes ',
			selector: (row) => {

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length}</span>
						</div>	
					)
				}else{
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.missions.length}</span>
						</div>
					)
				}
			},
			sortable: false,
			width: '100px',
		},
		{
			name: 'Montant',
			selector: (row) => {
				const totalForfait = row.packages || [];
				const totalPackagePrice = totalForfait.reduce((total, p) => total + p.price, 0);
				
				const montantTotal = row.prix + totalPackagePrice;
				const montantAvecMargin = (montantTotal - (montantTotal * (row.margin ?? 0)) / 100).toFixed(2);
			  
				return (
				  <div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{montantTotal.toFixed(2)}€</span>
					<span>{montantAvecMargin}€</span>
				  </div>
				);
			  },
			  width: '110px',
			sortable: false,
		},
		{
			name: 'Client',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onClick={()=>{
						if(row.clientID?.deletedAt !== null){
							history.push(`/admin/clients/single/${row.clientID._id}`)

						}else{
							history.push(`/admin/clients/${row.clientID._id}`)

						}
					}}
				>
					<img
						style={{
							width: '2rem',
							height: '2rem',
							borderRadius: '1rem',
							marginRight: 10,
						}}
						src={
							row.clientID && row.clientID.logo
								? `${BASE_URL}/${row.clientID.logo.path}`
								: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
						}
						alt=''
					/>
                  <span style={{
					fontWeight: 'bold',
					cursor: 'pointer',
					color:row?.clientID?.deletedAt !== null ? 'red' :'#51C7F2'
				  }}>
                  {row?.clientID?.societe}
                  </span>				
				  </div>
			),
			sortable: false,
			width:'150px'
		},
		{
			name: 'Type de compte',
			selector: (row) => (row.clientID.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width:'150px'
		},
		{
			name: 'Statut du payment',
			selector: (row) => row.paymentStatus,
			width: '150px',
			sortable: false,
		},
		{
			name: '% marge appliqué',
			selector: (row) => <div 
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		><span  
		style={{
			// background: '#50F5A9',
			border: '1px solid',
			borderBlockColor: 'grey',
			// cursor: 'pointer',
			color: 'black',
			fontFamily: 'Montserrat',
			padding: '.5rem',
			borderRadius: 6,
		}} >{row.margin ?? 0}</span></div>,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Date de création',
		    selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			width: '150px',
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							setSelectedCommande(row?._id)
							setModalIsOpenDetail(true)
						  }}
						style={{
							cursor: 'pointer',
						}}
					>
						 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>

					<div style={{ width: 10 }} />
                    {(user.role==="admin" || user.role==="super admin") && 
					<div
						onClick={ () => {
						 setCommande(row._id)
						 setModalIsOpenDelete(true)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.694'
							height='26.43'
							viewBox='0 0 21.694 26.43'
						>
							<g
								id='Group_11098'
								data-name='Group 11098'
								transform='translate(-11052.168 -6281.57)'
							>
								<path
									id='Rectangle_4434'
									data-name='Rectangle 4434'
									d='M0,0H17a1,1,0,0,1,1,1V17a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z'
									transform='translate(11054 6288)'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11757'
									data-name='Path 11757'
									d='M11081.736,6283.418h-7.568v2.032h21.694v-2.032h-7.8v-1.848h-6.324Z'
									transform='translate(-22)'
									fill='#e7e7eb'
								/>
							</g>
						</svg>
					</div>
		            }
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'commandes'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							// paddingLeft: '1rem',
							// paddingRight: '1rem',
							// marginBottom: '2rem',
							// marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{ value: 'id', label: "Recherche par l'identifiant " },
								{
									value: 'ct',
									label: 'Nom Client',
								},
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'ct' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}

					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
						onClick={handleSearch}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={handleClear}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>

					<button
						onClick={() => {
							if (selected.length === 0) {
								alert('Il faut séléctionner des commandes')
								return
							}

							setModalIsOpen(true)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9F7EC',
							border: 'none',
							cursor: 'pointer',
							color: '#1CC523',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Attribuer
					</button>

					<button
					    disabled={loadingSalon}
						onClick={ () => {
							if (selected.length === 0) {
								alert('Il faut séléctionner des commandes')
								return
							}
                            setModalIsOpenSalon(true)
							setLoadingSalon(false)

							

							
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#ECC9F7',
							border: 'none',
							cursor: 'pointer',
							color: '#AB1CC5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Salon
					</button>

					<button
						onClick={() => {
							if (selected.length === 0) {
								alert('Il faut séléctionner des commandes')
								return
							}

							setMarginModalIsOpen(true)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Modifier % de Marge 
					</button>
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						selectableRows
						clearSelectedRows={clearSelected}
						onSelectedRowsChange={handleSelectedRowsChange}
						noDataComponent="Il n'y a pas des commandes à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
            <DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div 
					style={{
							fontWeight: 'bold',
							fontFamily: 'Montserrat',
							fontSize:20,
							color: '#51C7F2',
					}}>
					Attribuer le transport à un Transporteur
					</div>

					<div
						style={{
							marginTop: 20,
							width: '100%',
						}}
					>
						<Select
							options={transporters.map((t) => ({
								value: t._id,
								label: `${t.societe}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							onChange={(x) => setSelectedTransporter(x.value)}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					<div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '2rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
						disabled={loadingAttribuer}
						onClick={async () => {
							setLoadingAttribuer(true)
							try {
								const response=await attributionCommandesTransporter(
									selected.map((c) =>  ({
										_id: c._id,
										__id: c.__id
									})),
									selectedTransporter,
									token
								)
								if(response.errors){
									toast.error(response.errors,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}
								if(response.success){
									toast.success(response.success,{
										style: {
											 fontFamily: 'Montserrat',
									}})
									history.push("/admin/plateforme/attribuer")
								}
							} catch (error) {
								toast.error(error.response?.data.error || "Erreur lors de l'attribution des commandes",
									{
									   style: {
											fontFamily: 'Montserrat',
										},}
								)
							}finally{
							setClearSelected(true)
							setSelected([])
							setSelectedTransporter(null)
							setLoadingAttribuer(false)
				            setModalIsOpen(false)
							}		
						}}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
						{loadingAttribuer ? (
									<div className='loader'></div>
								) : (
									'Valider'
						)}
					</button>
					<button
						onClick={ () => {
							setClearSelected(true)
							setSelected([])
							setSelectedTransporter(null)
							setModalIsOpen(false)						
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
				</div>
			</Modal>

			{/* margin modal  */}
			<Modal
				isOpen={marginModalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='margin Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div 
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2'
					}}>Nouveau % de marge</div>

					<div
						style={{
							marginTop: 20,
							width: '100%',
						}}
					>
						<input
						id='marge'
						min="0"
						max="100"
						type='number'
						disabled={loading}
						value={margin}
						onChange={(e) => setMargin(e.target.value)}
						style={{
							width:'80%',
							padding: '10px',
							border: '2px solid #ddd',
							borderRadius: '5px',
							outline: 'none',
							fontSize: '16px',
							color: '#333',
							backgroundColor: loading ? '#f0f0f0' : '#fff',
							cursor: loading ? 'not-allowed' : 'text',
							transition: 'border-color 0.3s ease-in-out',
						}}
					/>
					</div>
					<div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
						onClick={async () => {
							setLoading(true)
							try {
								const response=await updateCommandesMargin(
									selected.map((c) => ({
										_id: c._id,
										__id: c.__id
									})),
									margin,
									token
								)
								if(response.errors){
									toast.error(response.errors,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}
								if(response.success){
									toast.success(response.success,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}
							} catch (error){
								toast.error(error.response?.data.error || "Erreur lors de la mise à jour des marges des commandes" ,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
							}finally {
								setMargin(0)
								setClearSelected(true)
								setSelected([])
								setLoading(false)
								setMarginModalIsOpen(false)
								await fetchCommandes(currentPage,perPage,isSearching)

							}

						}}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
						{loading ? (
									<div className='loader'></div>
								) : (
									'Valider'
								)}
					</button>
					<button
						onClick={ () => {
							setMargin(0)
							setClearSelected(true)
							setSelected([])
							setMarginModalIsOpen(false)						
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
				</div>
			</Modal>
			
		   <ActionModal
           isOpen={modalIsOpenDelete}
           onRequestClose={() => {}}
           title="Supprimer Commande"
           message="Êtes-vous sûr de vouloir supprimer cette commande ?"
           onConfirm={async () => {	
			setLoading(true)
			   try {
				const res=await softDeleteCommande(commande,token)
				toast.success(res.message, {
					style: {
						fontFamily: 'Montserrat',
					},
				})
				setModalIsOpenDelete(false)
				await fetchCommandes(currentPage,perPage,isSearching)
			   } catch (error) {
				toast.error(
					error.response.data.message,
					{
						style: {
							fontFamily: 'Montserrat',
						},
					}
				)
				setModalIsOpenDelete(false)
			   }finally {
				setLoading(false)
			}
			}}
           onCancel={()=>{
			setModalIsOpenDelete(false)												
		   }}
           isLoading={loading}
         />
	      <ActionModal
           isOpen={modalIsOpenSalon}
           onRequestClose={() => {}}
           title="Mettre les commandes au salon"
           message="Êtes-vous sûr de vouloir mettre les commandes au salon ?"
           onConfirm={async () => {	
			setLoading(true)
			try {
				const  response= await attributionCommandeForSalon(
					selected.map(c => ({
						_id: c._id,
						__id: c.__id
					})),
					token
				)
				if(response.errors){
					toast.error(response.errors,{
						style: {
							 fontFamily: 'Montserrat',
					}})
				}
				if(response.success){
					toast.success(response.success,{
						style: {
							 fontFamily: 'Montserrat',
					}})
					history.push('/admin/plateforme/salon')
				}
				
			} catch (error) {
				toast.error(error.response?.data.error || "Erreur lors de l'attribution des commandes au salon" ,
					{
						style: {
							fontFamily: 'Montserrat',
						},
					}
				)
			}
			   finally {
				setClearSelected(true)
				setSelected([])
				setModalIsOpenSalon(false)
				setLoading(false)
			}

			  
			  
			}}
           onCancel={()=>{
				setClearSelected(true)
				setSelected([])
				setModalIsOpenSalon(false)						
		   }}
           isLoading={loading}
         />
		 
		 
		</LayoutAdmin>
	)
}

export default Commandes
