import axios from 'axios'
import api, { BASE_URL } from './base'


export const searchCommandesById = async (query,type,token) => {
	const res = await api.get(`/commandes/search/${query}/all?filter=${type}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const geoSearchChauffeurs = async (lat, lng, radius) => {
	const res = await axios({
		method: 'POST',
		url: `${BASE_URL}/indexes/users/search`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
		data: JSON.stringify({
			filter: `_geoRadius(${lat}, ${lng}, ${radius})`,
		}),
	})

	return res.data
}

export const searchCommandesByClientAndTransporter = async (query,type,token,page,size) => {
	const res = await api.get(`/commandes/searchclient/${query}/all`, {
        params: {
            filter: type,
            page: page,
            limit: size,
			nocache:Date.now()
  
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
	return res.data


}
export const searchCommandesByClientAndTransporterForPlateforme = async (query,type,token,page,size) => {
   const res = await api.get(`/commandes/plateforme/${query}/all`, {
	params: {
		filter: type,
		page: page,
		limit: size,
		nocache:Date.now()

	},
	headers: {
		Authorization: `Bearer ${token}`
	}
})
	return res.data
}
export const searchCommandesByPrixMinMax = async (min, max,type,token,page,size) => {
	const res = await api.get(`/commandes/price/${min}/${max}/all?filter=${type}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const searchCommandesAnomaliesByPrixMinMax = async (min, max,page,size,token) => {
	
	const res = await api.get(`/commandes/anomalie/price/${min}/${max}/all?page=${page}&limit=${size}&sort=date_transport&order=1`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}


export const searchCommandesByDate = async (min, max,type,token,page,size,offsetUTC) => {
    const res = await api.get(`/commandes/date/${min}/${max}/all`, {
        params: {
            filter: type,
            page: page,
            limit: size,
            offsetUTC: offsetUTC,
			nocache:Date.now()
  
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return res.data;
};
export const searchCommandesAnomaliesByDate = async (min, max,page,size,token,offsetUTC) => {
	const res = await api.get(`/commandes/anomalie/date/${min}/${max}/all`, {
        params: {
            page: page,
            limit: size,
            offsetUTC: offsetUTC,
			nocache:Date.now()
  
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
	
	return res.data
}
export const searchCommandesAnomaliesByClientAndTransporter = async (query,page,size,token) => {
	
	const res = await api.get(`/commandes/anomalie/search/${query}/all?page=${page}&limit=${size}&sort=date_transport&order=1`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const searchCommandesAnomaliesById = async (id,page,size,token) => {

	const res = await api.get(`/commandes/anomalie/${id}/all?page=${page}&limit=${size}&sort=date_transport&order=1`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const fullTextSearchChauffeurs = async (q) => {
	const res = await axios({
		method: 'GET',
		url: `${BASE_URL}/indexes/users/search?q=${q}`,
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer QNYw4Apg0b7c9bc136c5fb1fa035556bfea22858bf951fdb183343460bd9f020ad7bd97f',
		},
	})

	return res.data
}

export const fullTextSearchClients = async (query,page,size,token) => {
	const res = await api.get(`/users/clientforadmin/${query}/all?filter=deleted&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchClientsForAdmin = async (query,status,page,size,token) => {
	const res = await api.get(`/users/clientforadmin/${query}/all?filter=active&status=${status}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchChauffeur = async (query,status,page,size,token) => {
	const res = await api.get(`/users/chauffeurs/byIdandName/${query}/all?status=${status}&filter=active&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchTransporteurs = async (query,page,size,token) => {
	const res = await api.get(`/users/transporteurforadmin/${query}/all?filter=active&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const fullTextSearchDeletedTransporteurs = async (query, page, size, token) => {
	const res = await api.get(`/users/transporteurforadmin/${query}/all?filter=deleted&page=${page}&limit=${size}`, {
		headers: {
			Authorization: `Bearer ` + token
		},
	});
	return res.data;
}

export const fullTextSearchNewTransporteurs = async (query,status,page,size,token) => {
	const res = await api.get(`/users/newtransporteur/${query}/all?filter=new&status=${status}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchValidNewTransporteurs = async (query,token) => {
	query=query.trim()
	const res = await api.get(`/users/newtransporteur/${query}/all?status=validated`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const fullTextSearchNoValidNewTransporteurs = async (query,token) => {
	query=query.trim()
	const res = await api.get(`/users/newtransporteur/${query}/all?status=notValidated`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
