import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const ModalContent = styled.div`
  width: 35rem;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;
`;

const ModalButton = styled.button`
  padding: 0.5rem 1rem;
  background: #50F5A9;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 7px;
  margin-top: 30px;
`;

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 19,
  },
};

export const ValidationModal = ({ isOpen, onClose, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Validation Modal"
    >
      <ModalContent>
        <div>{message}</div>
        <ModalButton onClick={onClose}>OK</ModalButton>
      </ModalContent>
    </Modal>
  );
};