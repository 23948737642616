import React,{useState} from 'react';
import { Phone, MapPin } from 'lucide-react';

import './Header.css'
import Logo from '../../assets/img/FullLogo_Transparent_NoBuffer.png'
const CommonHeader = () => {

	return (
    <header className="header">
    <div className='part1'>
	 <img src={Logo} alt="B2BGLOB" className="logo" />
	 </div>
     <div className='part2'>
      <div className='top-bar'>
	  <div className="top-bar-content">
          <div className="contact-info">
            <div className="contact-item">
              <Phone size={16} />
              <span>+33 01 45 67 43 22</span>
            </div>
            <div className="contact-item">
              <MapPin size={16} />
              <span>74 avenue Gambetta 75020 Paris France</span>
            </div>
          </div>
        </div>
	  </div>

  <div className="main-nav">
  <div className="nav-content">
    <div className="nav-links">
      <a
        href="/"
        className="nav-link"
      >
        Accueil
      </a>
      <a
        href="/CreateAccount"
        className="nav-link"
       
      >
        Créer un compte
      </a>
      <a
        href="/contact"
        className="nav-link"
       
      >
        Nous contacter
      </a>
      <a
        href="/login"
        className="nav-link"
       
      >
        Se connecter 
        </a>
    
    </div>
  
  </div>
 
</div>

	 </div>
    </header>
  );
};

export default CommonHeader;