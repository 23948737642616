import axios from 'axios'
import { refreshUserToken } from './auth'
import { refreshTokenAction, logoutAction } from '../redux/auth'
import { store } from '../redux/configureStore'

export const BASE_URL =
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_PROD_API_URL
		: process.env.REACT_APP_DEV_API_URL || 'http://localhost:8000'

export const PUBLIC = `${BASE_URL}/media`

const api = axios.create({ baseURL: BASE_URL })

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config
		if (
			error.response &&
			error.response.status === 401 &&
			error.response.data.message === 'Token expiré' &&
			!originalRequest._retry
		) {
			originalRequest._retry = true
			const state = store.getState()
			try {
				const newToken = await refreshUserToken(state.auth.refreshToken)

				if (newToken) {
					store.dispatch(refreshTokenAction(newToken))
					originalRequest.headers.Authorization = `Bearer ${newToken}`
					return api(originalRequest)
				} else {
					store.dispatch(logoutAction())
					window.location.href = '/login'
				}
			} catch (refreshError) {
				store.dispatch(logoutAction())
				window.location.href = '/login'
			}
		}

		return Promise.reject(error)
	}
)

export default api
