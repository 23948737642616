import React from 'react'
import "./Footer.css"
import Logo from '../../assets/img/FullLogo_Transparent_NoBuffer.png'
import { MapPin, Phone, Send } from 'lucide-react';


function Footer() {
	return (
		<footer className='footer'>
		<div className='footer-content'>
		<div className="footer-section presentation">
	      <img src={Logo} alt="B2BGLOB" className="logo" />
          <p className="presentation-text">
            TPE est ravi de présenter la plateforme numérique - B2BGLOB
          </p>
          <p className="sub-text">
            Commander votre transporteur avec chauffeur en quelques clics !
          </p>
        </div>
		<div className="footer-section">
          <h3>Liens Rapides</h3>
          <ul>
            <li><a href="/">Accueil</a></li>
            <li><a href="/CreateAccount">Devenir Transporteur</a></li>
            <li><a href="/CreateAccount">Inscription Client</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
		<div className="footer-section">
          <h3 >Informations</h3>
		  <ul>
            <li>
			<div className="info-item">
              <MapPin size={16} className="icon" />
              <p>74 avenue Gambetta<br />75020 Paris France</p>
            </div>
			</li>
			<li>
			
            <div className="info-item">
              <Phone size={16} className="icon" />
              <p>+33 01 45 67 43 22</p>
            </div>
			</li>
          </ul>
        
        </div>
          {/* Account Section */}
		  <div className="footer-section">
          <h3 >Mon Compte</h3>
          <ul>
            <li><a href="/login">Accès Client</a></li>
            <li><a href="/login">Accès Chauffeur</a></li>
            <li><a href="/login">Accès Professionel</a></li>
          </ul>
        </div>
		  {/* Newsletter Section */}
		  <div className="footer-section">
          <h3 >Lettre D'information</h3>
          <div className="newsletter-form">
            <input 
              type="email" 
              placeholder="Addresse email" 
              className="newsletter-input"
            />
            <button className="newsletter-btn">
              <Send size={18} />
            </button>
          </div>
        </div>

		</div>
		<div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>© 2025 B2BGLOB</p>
          <div className="footer-links">
            <a href="/politique-confidentialite">Politique de confidentialité</a>
            <span className="separator">|</span>
            <a href="/conditions">Conditions Générales d’Utilisation</a>
          </div>
        </div>
      </div>
		</footer>
	)
}

export default Footer
