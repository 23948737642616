import 'flatpickr/dist/themes/material_blue.css'

import { Controller, useForm } from 'react-hook-form'
import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	getSingleChauffeur,

} from '../../api/clients'
import {updateChauffeur} from '../../api/chauffeurs'
import { useDispatch, useSelector } from 'react-redux'

import { CustomInput } from '../RegisterTransporter/style'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import LayoutChauffeur from '../../Components/LayoutChauffeur/LayoutChauffeur'
import toast from 'react-hot-toast'
import { updateProfile } from '../../redux/auth'



function ChauffeurAccount() {
	const { _id } = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);

	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm()

	const [chauffeur, setChauffeur] = useState()
	const [loading, setLoading] = useState(false)
	const [chargementEnCours, setChargementEnCours] = useState(false);

	async function fetchChauffeur(_id) {
		setChargementEnCours(true)
		try {
		const data = await getSingleChauffeur(_id,token)
		setChauffeur(data)
		reset({
			address:data?.address,
			city:data?.city,
			postalCode:data?.postalCode,
			phone:data?.phone
		})
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
				  fontFamily: 'Montserrat',
				},
			  });
		}finally{
			setChargementEnCours(false)

		}
		
	}
	useEffect(() => {
		if (_id) {
			fetchChauffeur(_id)
		}
	}, [_id])

	
	const onSubmit = async (data) => {
		setLoading(true)
		const update = {
			address:data.address,
			city:data.city,
			postalCode:data.postalCode,
			phone:data.phone,
		}
		
        try {
		const res=await updateChauffeur(_id,update,token)
		toast.success('Votre compte a été mis à jour', {
			style: {
				fontFamily: 'Montserrat',
			},
		})
		dispatch(updateProfile(res))

		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setLoading(false)

		}
		
	}

	

	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<Wrapper>
					<GradientText>MON COMPTE</GradientText>
				</Wrapper>
				{chargementEnCours ?
			    <div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  position: 'absolute',
			  top: 0,
			  left: 0,
			  right: 0,
			  bottom: 0,
   
			}}
			>
			<div className='customLoader'></div>	
			    </div>
			      :
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					<div
						style={{
							width: '70%',
							fontFamily: 'Montserrat',
							alignSelf: 'center',
						}}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Sexe
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div
											style={{
												width: '30%',
											}}
										>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												checked={chauffeur?.sexe === 'homme'} 
												value={'homme'}
											/>

											<span>Homme</span>
										</div>

										<div>
											<input
												type='radio'
												disabled
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												checked={chauffeur?.sexe === 'femme'} 
												value={'femme'}
											/>

											<span>Femme</span>
										</div>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											marginBottom: '1rem',
										}}
									>
										Permis
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										{[
											'Léger',
											'Lourds',
											'Super lourds',
										].map((item, index) => (
											<div
												key={String(index)}
												style={{
													width: '30%',
												}}
											>
												<input
													type='checkbox'
													disabled
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}
													checked={chauffeur?.permis?.includes(item)} 
													value={item}
												/>

												<span>{item}</span>
											</div>
										))}
									</div>
								</div>

								
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Nom
										</span>
										<CustomInput
											type='text'
											disabled
											value={chauffeur?.last_name}
											style={{
												backgroundColor: '#f0f0f0', 
												color: '#a9a9a9',           
												cursor: 'not-allowed'       
											}}
										/>
									</div>

									<div style={{ width: 50 }} />

									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Prénom
										</span>
										<CustomInput
											type='text'
											disabled
											value={chauffeur?.first_name}
											style={{
												backgroundColor: '#f0f0f0', 
												color: '#a9a9a9',           
												cursor: 'not-allowed'       
											}}
										/>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Adresse
										</span>
										<Controller
                                         name="address"
                                         control={control}
									     defaultValue={chauffeur?.address }
                                         rules={{
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^[A-Za-z0-9\s',-]+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
                                    }}
                                    render={({ field }) => (
                                     <CustomInput
                                     type='text'
									 placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
                                      {...field} 
                                     />
                                    )}
                                    />
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
									</div>
								</div>
                                <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									
									{...register('city', {
									 	required: 'Veuillez indiquer la ville.',
										 minLength: {
											value: 3,
											message: 'La ville doit contenir au moins 3 caractères.',
										  },
										  pattern: {
											value: /^.+$/,
											message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										  },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Téléphone
										</span>
										<CustomInput
											type='text'
											placeholder='(0) 01 45 66 32 88'
											{...register('phone', {
												required: true,
											})}
										/>
									</div>

									<div style={{ width: 50 }} />

									<div
										style={{
											width: '50%',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Adresse email
										</span>
										<CustomInput
											type='text'
											disabled
											value={chauffeur?.email}
											style={{
												backgroundColor: '#f0f0f0', 
												color: '#a9a9a9',           
												cursor: 'not-allowed'       
											}}
											
										/>
									</div>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '50%',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Date de naissance
										</span>
										<Flatpickr
                                          disabled
                                          className="date-input"
                                           options={{
                                             mode: 'single',
                                             locale: French,
                                              dateFormat: 'd/m/Y',
                                           }}
                                            value={chauffeur?.date_naiss} 
                                        />

									</div>

									<div style={{ width: 50 }} />

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '50%',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Date d’embauche
										</span>
										<Flatpickr
                                          disabled
                                          className="date-input"
                                           options={{
                                             mode: 'single',
                                             locale: French,
                                              dateFormat: 'd/m/Y',
                                           }}
                                            value={chauffeur?.date_embauche} 
                                        />
										
									</div>
								</div>
						   <div
                            style={{
                             display: 'flex',
                             flexDirection: 'column',
                             marginBottom: '2rem',
                            }}
                            >
                           <span
                             style={{
                              color: '#262626',
                              marginBottom: '1rem',
                             }}
                            >
                            Fimo
                          </span>
                          <div
                           style={{
                           display: 'flex',
                           flexDirection: 'row',
                          }}
                          >
                          {[
                           { label: 'Oui', value: true },
                           { label: 'Non', value: false },
                           ].map(({ label, value }, index) => (
                           <div
                            key={String(index)}
                            style={{
                             width: '20%',
                            }}
                            >
                            <input
                              type="radio"
                              style={{
                               marginRight: '1rem',
                               transform: 'scale(1.5)',
                              }}
							  disabled
                              value={value}
							  checked={value === chauffeur?.fimo} 
                            />

                            <span>{label}</span>
                             </div>
                            ))}
                           </div>
                          
                            </div>


							<div
                             style={{
                              display: 'flex',
                              flexDirection: 'column',
                               marginBottom: '2rem',
                             }}
                            >
                            <span
                            style={{
                              color: '#262626',
                              marginBottom: '1rem',
                            }}
                            >
                            Matière dangereuse
                             </span>
                           <div
                            style={{
                             display: 'flex',
                             flexDirection: 'row',
                           }}
                           >
                          {[
                           { label: 'Oui', value: true },
                           { label: 'Non', value: false },
                          ].map(({ label, value }, index) => (
                         <div
                           key={String(index)}
                           style={{
                            width: '20%',
                          }}
                        >
                        <input
                         type="radio"
                         style={{
                          marginRight: '1rem',
                          transform: 'scale(1.5)',
                        }}
						disabled
					    checked={value === chauffeur?.danger} 
                        value={value}
                       />

                       <span>{label}</span>
                      </div>
                      ))}
                     </div>
                   
                    </div>

							</FormSectionCard>

							<div style={{ height: '1rem' }} />

							<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
							{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}	
							</SubmitButton>
						</SubmitButtonContainer>
						</form>
					</div>
				</div>
            }
			</div>
		</LayoutChauffeur>
	)
}

export default ChauffeurAccount
