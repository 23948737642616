import * as XLSX from 'xlsx'

import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import { createTarif } from '../../api/tarif'
import { setTarif } from '../../redux/tarif'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import { uploadSingleFileTarif } from '../../api/upload'
import  {getTarif} from  '../../api/tarif'
import {updateMarge,getMarge} from '../../api/configurations'
function sleep(time) {
	return new Promise((resolve) => setTimeout(resolve, time))
}

function Tarif(props) {
	const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [nomFichierCourse, setNomFichierCourse] = useState('Aucun fichier choisi');
	const [nomFichierMISEADISPO, setNomFichierMISEADISPO] = useState('Aucun fichier choisi');
	const dispatch = useDispatch()
	const [file,setFile]=useState(null)
	const [margin, setMargin] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const token = useSelector((state) => state.auth.token);
	

	async function fetchData() {
		setChargementEnCours(true);
	  
		try {
		  const [res1, res2] = await Promise.all([
			getTarif("MISEADISPO"),
			getTarif("COURSEACOURSE")
		  ]);
	  
		  setNomFichierMISEADISPO(res1.name);
		  setNomFichierCourse(res2.name);
	  
		  const marge = await getMarge(token);
		  setMargin(marge);
		} catch (error) {
		  console.error('Erreur lors de la récupération des données:', error);
		} finally {
		  setChargementEnCours(false);
		}
	  }
	  
	const readCell = (cellAddress, workBook) => {
		var first_sheet_name = workBook.SheetNames[0]
		var worksheet = workBook.Sheets[first_sheet_name]
		var desired_cell = worksheet[cellAddress]
		const value = desired_cell ? desired_cell.v : undefined

		return value
	}


	const loadFile = async (file, type) => {
		setLoading(true)
		setFile(file)
		const data = await file.arrayBuffer()
		const workBook = XLSX.read(data)

		let tarif = {
			TYPE: type
		}

		tarif.BREAK = {}

		tarif.BREAK.IMMEDIAT = Number(readCell('B2', workBook))
		tarif.BREAK.PLANIFIE = Number(readCell('C2', workBook))
		tarif.BREAK.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D2', workBook))
		tarif.BREAK.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E2', workBook))
		tarif.BREAK.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F2', workBook)
		)
		tarif.BREAK.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G2', workBook)
		)
		tarif.BREAK.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H2', workBook)
		)
		tarif.BREAK.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I2', workBook)
		)
		tarif.BREAK.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J2', workBook)
		)
		tarif.BREAK.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K2',workBook)
		) 
		tarif.BREAK.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L2',workBook)
		)
		tarif.BREAK.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N2',workBook)
		)
		tarif.BREAK.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O2',workBook)
		)
		tarif.BREAK.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P2',workBook)
		)
		

		tarif.F12 = {}

		tarif.F12.IMMEDIAT = Number(readCell('B3', workBook))
		tarif.F12.PLANIFIE = Number(readCell('C3', workBook))
		tarif.F12.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D3', workBook))
		tarif.F12.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E3', workBook))
		tarif.F12.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F3', workBook)
		)
		tarif.F12.FRIGORIFIQUE_EN_POURCENTAGE = Number(readCell('G3', workBook))
		tarif.F12.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H3', workBook)
		)
		tarif.F12.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I3', workBook)
		)
		tarif.F12.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J3', workBook)
		)
		tarif.F12.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K3',workBook)
		) 
		tarif.F12.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L3',workBook)
		)
		tarif.F12.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N3',workBook)
		)
		tarif.F12.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O3',workBook)
		)
		tarif.F12.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P3',workBook)
		)
		
		tarif.F14 = {}

		tarif.F14.IMMEDIAT = Number(readCell('B4', workBook))
		tarif.F14.PLANIFIE = Number(readCell('C4', workBook))
		tarif.F14.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D4', workBook))
		tarif.F14.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E4', workBook))
		tarif.F14.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F4', workBook)
		)
		tarif.F14.FRIGORIFIQUE_EN_POURCENTAGE = Number(readCell('G4', workBook))
		tarif.F14.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H4', workBook)
		)
		tarif.F14.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I4', workBook)
		)
		tarif.F14.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J4', workBook)
		)
		tarif.F14.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K4',workBook)
		) 
		tarif.F14.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L4',workBook)
		)

		tarif.F14.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N4',workBook)
		)
		tarif.F14.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O4',workBook)
		)
		tarif.F14.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P4',workBook)
		)
		

		tarif.F20 = {}

		tarif.F20.IMMEDIAT = Number(readCell('B5', workBook))
		tarif.F20.PLANIFIE = Number(readCell('C5', workBook))
		tarif.F20.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D5', workBook))
		tarif.F20.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E5', workBook))
		tarif.F20.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F5', workBook)
		)
		tarif.F20.FRIGORIFIQUE_EN_POURCENTAGE = Number(readCell('G5', workBook))
		tarif.F20.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H5', workBook)
		)
		tarif.F20.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I5', workBook)
		)
		tarif.F20.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J5', workBook)
		)
		tarif.F20.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K5',workBook)
		) 
		tarif.F20.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L5',workBook)
		)
		tarif.F20.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N5',workBook)
		)
		tarif.F20.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O5',workBook)
		)
		tarif.F20.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P5',workBook)
		)
		

		tarif.V7_5T = {}

		tarif.V7_5T.IMMEDIAT = Number(readCell('B6', workBook))
		tarif.V7_5T.PLANIFIE = Number(readCell('C6', workBook))
		tarif.V7_5T.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D6', workBook))
		tarif.V7_5T.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E6', workBook))
		tarif.V7_5T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F6', workBook)
		)
		tarif.V7_5T.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G6', workBook)
		)
		tarif.V7_5T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H6', workBook)
		)
		tarif.V7_5T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I6', workBook)
		)
		tarif.V7_5T.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J6', workBook)
		)
		tarif.V7_5T.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K6',workBook)
		) 
		tarif.V7_5T.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L6',workBook)
		)
		tarif.V7_5T.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N6',workBook)
		)
		tarif.V7_5T.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O6',workBook)
		)
		tarif.V7_5T.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P6',workBook)
		)
		
		

		tarif.V12T = {}

		tarif.V12T.IMMEDIAT = Number(readCell('B7', workBook))
		tarif.V12T.PLANIFIE = Number(readCell('C7', workBook))
		tarif.V12T.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D7', workBook))
		tarif.V12T.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E7', workBook))
		tarif.V12T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F7', workBook)
		)
		tarif.V12T.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G7', workBook)
		)
		tarif.V12T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H7', workBook)
		)
		tarif.V12T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I7', workBook)
		)
		tarif.V12T.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J7', workBook)
		)
		tarif.V12T.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K7',workBook)
		) 
		tarif.V12T.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L7',workBook)
		)
		tarif.V12T.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N7',workBook)
		)
		tarif.V12T.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O7',workBook)
		)
		tarif.V12T.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P7',workBook)
		)
		

		tarif.V19T = {}

		tarif.V19T.IMMEDIAT = Number(readCell('B8', workBook))
		tarif.V19T.PLANIFIE = Number(readCell('C8', workBook))
		tarif.V19T.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D8', workBook))
		tarif.V19T.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E8', workBook))
		tarif.V19T.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F8', workBook)
		)
		tarif.V19T.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G8', workBook)
		)
		tarif.V19T.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H8', workBook)
		)
		tarif.V19T.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I8', workBook)
		)
		tarif.V19T.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J8', workBook)
		)
		tarif.V19T.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K8',workBook)
		) 
		tarif.V19T.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L8',workBook)
		)

		tarif.V19T.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N8',workBook)
		)
		tarif.V19T.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O8',workBook)
		)
		tarif.V19T.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P8',workBook)
		)
		
		tarif.TRACTEUR = {}

		tarif.TRACTEUR.IMMEDIAT = Number(readCell('B9', workBook))
		tarif.TRACTEUR.PLANIFIE = Number(readCell('C9', workBook))
		tarif.TRACTEUR.TARIFS_PAR_ETAGE_ESCALIER = Number(
			readCell('D9', workBook)
		)
		tarif.TRACTEUR.TARIF_PAR_ETAGE_ASSENCEUR = Number(
			readCell('E9', workBook)
		)
		tarif.TRACTEUR.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F9', workBook)
		)
		tarif.TRACTEUR.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G9', workBook)
		)
		tarif.TRACTEUR.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H9', workBook)
		)
		tarif.TRACTEUR.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I9', workBook)
		)
		tarif.TRACTEUR.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J9', workBook)
		)
		tarif.TRACTEUR.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K9',workBook)
		) 
		tarif.TRACTEUR.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L9',workBook)
		)
		tarif.TRACTEUR.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N9',workBook)
		)
		tarif.TRACTEUR.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O9',workBook)
		)
		tarif.TRACTEUR.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P9',workBook)
		)
	


		tarif.SEMI = {}

		tarif.SEMI.IMMEDIAT = Number(readCell('B10', workBook))
		tarif.SEMI.PLANIFIE = Number(readCell('C10', workBook))
		tarif.SEMI.TARIFS_PAR_ETAGE_ESCALIER = Number(readCell('D10', workBook))
		tarif.SEMI.TARIF_PAR_ETAGE_ASSENCEUR = Number(readCell('E10', workBook))
		tarif.SEMI.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F10', workBook)
		)
		tarif.SEMI.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G10', workBook)
		)
		tarif.SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H10', workBook)
		)
		tarif.SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I10', workBook)
		)
		tarif.SEMI.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J10', workBook)
		)
		tarif.SEMI.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K10',workBook)
		) 
		tarif.SEMI.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L10',workBook)
		)
		tarif.SEMI.TARIF_SEMI_PAR_24H=Number(
			readCell('M10',workBook)
		)

		tarif.SEMI.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N10',workBook)
		)
		tarif.SEMI.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O10',workBook)
		)
		tarif.SEMI.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P10',workBook)
		)
		


		tarif.TRACTEUR_SEMI = {}

		tarif.TRACTEUR_SEMI.IMMEDIAT = Number(readCell('B11', workBook))
		tarif.TRACTEUR_SEMI.PLANIFIE = Number(readCell('C11', workBook))
		tarif.TRACTEUR_SEMI.TARIFS_PAR_ETAGE_ESCALIER = Number(
			readCell('D11', workBook)
		)
		tarif.TRACTEUR_SEMI.TARIF_PAR_ETAGE_ASSENCEUR = Number(
			readCell('E11', workBook)
		)
		tarif.TRACTEUR_SEMI.TARIFS_PAR_RIPPEUR_PAR_HEURE_EN_EUROS = Number(
			readCell('F11', workBook)
		)
		tarif.TRACTEUR_SEMI.FRIGORIFIQUE_EN_POURCENTAGE = Number(
			readCell('G11', workBook)
		)
		tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_POUR_200KM_JOURS_POUR_7H = Number(
			readCell('H11', workBook)
		)
		tarif.TRACTEUR_SEMI.TARIFS_EN_EUROS_DÉPACEMENT_AU_KM = Number(
			readCell('I11', workBook)
		)
		tarif.TRACTEUR_SEMI.TARIF_PAR_HEURE_DE_MISE_À_DISPO = Number(
			readCell('J11', workBook)
		)
		tarif.TRACTEUR_SEMI.TARIF_PAR_MINUTE_DE_RETARD=Number(
			readCell('K11',workBook)
		) 
		tarif.TRACTEUR_SEMI.Tarif_DE_PRISE_EN_CHARGE=Number(
			readCell('L11',workBook)
		)
		tarif.TRACTEUR_SEMI.TARIF_PAR_1_10_ETAGE=Number(
			readCell('N11',workBook)
		)
		tarif.TRACTEUR_SEMI.TARIF_PAR_11_20_ETAGE=Number(
			readCell('O11',workBook)
		)
		tarif.TRACTEUR_SEMI.TARIF_PAR_21_30_ETAGE=Number(
			readCell('P11',workBook)
		)
		

		tarif.FACTURATION_HEURES_D_ATTENTE = Number(readCell('K2', workBook))

		

		await sleep(3000)

		const res=await createTarif(tarif,token)
        await uploadSingleFileTarif(res,file)
		dispatch(setTarif(tarif))

		setLoading(false)

		return Promise.resolve()
	}
    useEffect(()=>{
		fetchData()
	},[])
	return (
		<LayoutAdmin>
			{chargementEnCours ?
			    <div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  position: 'absolute',
			  top: 0,
			  left: 0,
			  right: 0,
			  bottom: 0,
   
			}}
			>
			<div className='customLoader'></div>	
			    </div>
			      :
			<div
				style={{
					width: '100%',
					display: 'flex',
					//alignItems: 'center',
					//justifyContent: 'center',
					flexDirection: 'column',
					padding: '2rem',
					fontFamily: 'Montserrat',
				}}
			>
				<p>Charger fichier tarif</p>
				{loading && <progress />}
				<div
				style={{
					display: 'flex',
					flexDirection: 'row',
				}}
				>
				<span>pour courses à courses</span>	
				<div
				style={{
					position:"relative",
					paddingLeft:"5px"
				}}
				>
					 <label
					 style={{
						border:"1px solid #000",
						minHeight:"10px",
						width:"150px",
						position:"absolute",
						borderRadius:"3px",
						paddingLeft:"2px"
						
					 }}
					 >
				     Choisir un fichier
					 </label>{' '}
					
					<input
					    style={{
							width:"150px",
							opacity:'0'
						}}
						id='fileSelect'
						type='file'
						disabled={loading}
						accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
						onChange={(e) =>{
							loadFile(e.target.files[0], 'COURSEACOURSE');
							setNomFichierCourse(e.target.files[0].name)
						}
					}
					/>
					<span
					style={{
						paddingLeft:"2px"
					}}
					>{nomFichierCourse}</span>
				</div>
				</div>
				<br/>
				<div
				style={{
					display: 'flex',
					flexDirection: 'row',
				}}
				>
				<span>pour mise à disposition</span>	
				<div
					style={{
						position:"relative",
						paddingLeft:"5px"
					}}>
				<label
					 style={{
						border:"1px solid #000",
						minHeight:"10px",
						width:"150px",
						position:"absolute",
						borderRadius:"3px",
						paddingLeft:"2px"
						
					 }}
					 >
				     Choisir un fichier
					 </label>{' '}
					<input
					style={{
						width:"150px",
						opacity:'0'
					}}
						id='fileSelect'
						type='file'
						disabled={loading}
						placeholder=""
						accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
						onChange={(e) =>{
							loadFile(e.target.files[0], 'MISEADISPO')
							setNomFichierMISEADISPO(e.target.files[0].name)
						} 
						}
						
					/>
                    <span
					style={{
						paddingLeft:"2px"
					}}
					>{nomFichierMISEADISPO}</span>
				</div>
				</div>
				<br/>
				<div>
					<label>
					% de marge appliqué 
					 </label>{' '}
					<span style={{
							// background: '#50F5A9',
							border: '1px solid',
							borderBlockColor: 'grey',
							cursor: 'pointer',
							color: 'black',
							fontFamily: 'Montserrat',
							padding: '.5rem',
							borderRadius: 6,
					}} onClick={() => setModalIsOpen(true)}>{margin}</span>
				</div>
				<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div 
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2'
					}}>
						Nouveau % de marge
					</div>

					<div
						style={{
							marginTop: 40,
							width: '100%',
						}}
					>
						<input
						id='marge'
						min="0"
						max="100"
						type='number'
						disabled={loading}
						value={margin}
						onChange={(e) => setMargin(e.target.value)}
						style={{
							width:'80%',
							padding: '10px',
							border: '2px solid #ddd',
							borderRadius: '5px',
							outline: 'none',
							fontSize: '16px',
							color: '#333',
							backgroundColor: loading ? '#f0f0f0' : '#fff',
							cursor: loading ? 'not-allowed' : 'text',
							transition: 'border-color 0.3s ease-in-out',
						}}
					/>
					</div>
					<div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					    disabled={loading}

						onClick={async () => {
						
							setLoading(true);
							try {
							 await updateMarge( margin,token)
							 toast.success('La marge a été mise à jour avec succès', {
								style: {
									fontFamily: 'Montserrat',
								},
							})
							}catch(err) {
							} finally {
								setModalIsOpen(false)
								setLoading(false)
							}
						}}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							marginRight:10,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
					
						{loading ? (
									<div className='loader'></div>
								) : (
									'Valider'
								)}
					</button>
					<button
						onClick={ () => {
							
							setModalIsOpen(false)	
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
				</div>
			</Modal>


			</div>
            }		
		</LayoutAdmin>
	)
}

export default Tarif
