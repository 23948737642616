import React, { useEffect, useState } from 'react'
import { getDeletelClient,restoreDeletedClient } from '../../api/clients'
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast'
import DataTable from 'react-data-table-component'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import { fullTextSearchClients } from '../../api/search'
import moment from 'moment'
import { useHistory } from 'react-router'
import ActionModal from '../../Components/ValidationModal/ActionModal';

function MesClientsSupprimer() {
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [data, setData] = useState([])	
	const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
    const [client,setClient]=useState('')
	const [isSearching,setIsSearching]=useState(false)

	const fetchClients = async (page,perPage) => {
		setChargementEnCours(true)
		try {
		let res
		if(isSearching){
			if (searchQuery.length === 0) {
				res = await getDeletelClient(page, perPage,token)	
			} else {
			    res = await fullTextSearchClients(searchQuery,page,perPage,token)

			  }
		}else{
			res = await getDeletelClient(page, perPage,token)	
		}
		setData(res.docs)
		setTotalRows(res.totalDocs)	
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
				  fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
	
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchClients(page,perPage)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchClients(page,newPerPage)
		setPerPage(newPerPage)		
	}
	const  handleSearch=async()=>{
		setIsSearching(true)
		setCurrentPage(1)
		if(isSearching){
			await fetchClients(1,perPage)
		}
	}
	const handelReset=async()=>{
		setIsSearching(false)
		setSearchQuery('')
	}
	useEffect(() => {
		fetchClients(currentPage,perPage)
		
	}, [])
	useEffect(() => {
		fetchClients(currentPage,perPage)
	}, [isSearching])

	

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width:"200px"
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Nom du client',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:"250px"
		},
		{
			name: 'Type de compte',
			selector: (row) => (row.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width: "200px"
		},
		{
		   name: 'Statut',
		   selector: (row) => (row.status==="suspendu" ? 'Suspendu' : 'Activé'),
		    sortable: false,
		},
		{
			name: "Date de suspension",
			selector: (row) => (row.status==="suspendu"  ? moment(row?.date_suspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
		},
		
		
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width: "200px"
		},
		{
			name: 'Restaurer le Compte',
			button: true,
			width: '200px',
			cell: (row) => (
                <button
						
						onClick={ () => {
							setClient(row._id)
                            setModalIsOpen(true)
						}}
						style={{
							background: '#C9F7F5',
							color: '#1CC5BD',
							fontFamily: 'Montserrat',
							border: 'none',
							cursor: 'pointer',
							padding: '0.5rem 1rem',
							borderRadius: 5,
							fontWeight: 'bold',
							marginRight: 10,
						}}
					>
						Valider
					</button>
            )
		},
		
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/admin/archive/clients/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'ARCHIVES  Clients'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
						disabled={loading}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						placeholder='Rechercher par identifiant,nom client / societe'
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '22rem',
							fontFamily: 'Montserrat',
						}}
					/>

					<button
						disabled={chargementEnCours}
						onClick={handleSearch}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={handelReset}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des clients à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
			<ActionModal
			  isOpen={modalIsOpen}
			  onRequestClose={()=>setModalIsOpen(false)}
			  title="Restaurer Client"
			  message="Êtes-vous sûr de vouloir Restaurer ce Client "
			  onConfirm={async () => {	
				setLoading(true)
				try {
				const res=await restoreDeletedClient(client,token) 
				toast.success(res.message, {
				style: {
					fontFamily: 'Montserrat',
				},
				})
				history.push('/admin/clients')
				} catch (error) {
					toast.error(error.response?.data.error || "Une erreur est survenue lors de la restauration de ce compte", {
						style: {
							fontFamily: 'Montserrat',
						},
					})
				}finally {
					setLoading(false)
					setModalIsOpen(false)

				}
				  
				}}
				
			  onCancel={()=>setModalIsOpen(false)}
			  isLoading={loading}
			/>
			
		</LayoutAdmin>
	)
}

export default MesClientsSupprimer
