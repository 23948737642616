import React, { useState } from 'react'
import Footer from '../../Components/Footer/footer.component'
import { useHistory } from 'react-router-dom'
import imagebg from '../../assets/img/background__home.png'
import CommonHeader from '../../Components/CommonHeader/common-header.component'
import imageTransportSteps from '../../assets/img/background__transport.png'
 
import step1 from "../../assets/img/step1.png"; 
import step2 from "../../assets/img/step2.png";
import step3 from "../../assets/img/step3.png";
import step4 from "../../assets/img/step4.png";
import delivery from "../../assets/img/delivery.png"
import commande from "../../assets/img/commande.png"
import transporteur from "../../assets/img/transporteur.png"
import suivi from "../../assets/img/suivi.png"
import visibilite from "../../assets/img/visibilite.png"
import comptabilite from "../../assets/img/comptabilite2.png"
import transporteurs from "../../assets/img/transporteurs.png"
export default function HomePage() {

	
	const [typeCommande, setTypeCommande] = useState()
	const [temporisation, setTemporisation] = useState()

	const history = useHistory()

	const services = [
		{
		  title: "Placez votre commande",
		  description:
			"Payez intégralement à la création et planifiez votre transport immédiat ou à votre convenance.",
		  image:commande , 
		},
		{
		  title: "Un transporteur est affecté à votre commande",
		  description:
			"Communication efficace entre transporteurs et chauffeurs pour des attributions rapides et un suivi en temps réel.",
		  image: transporteur
		},
		{
		  title: "Suivez vos commandes en temps réel",
		  description:
			"Suivez vos livraisons en temps réel et recevez des notifications pour rester informé à chaque étape.",
		  image: suivi
		},
		{
		  title: "Visibilité et Informations",
		  description:
			"Obtenez une vision claire de vos transports : suivi des commandes, réalisations fiscales et dépenses, pour une visibilité totale.",
		  image: visibilite
		},
		{
		  title: "Gestion Comptable Intégrée",
		  description:
			"Notre plateforme automatise les processus comptables, offrant une gestion transparente et facilitant le suivi financier de vos opérations.",
		  image:comptabilite
		},
	  ];

	return (
		<div>
			<CommonHeader/>
			<div style={{
               position: 'relative',
               width: '100%',
               height: '460px',
               backgroundImage: `url(${imagebg})`,
               backgroundSize: 'cover',
               backgroundPosition: 'center',
           }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
             height: '100%',
		    background: 'linear-gradient(107deg, rgba(169, 218, 237, 0.3) 0%, rgba(27, 106, 143, 0.5) 100%)',

          }}></div>
		    <div 
			style={{
            position: 'relative',
            color: '#fff', 
             zIndex: 1, 
           }}>
			<div  
			style={{
				width:"550px",
				paddingLeft:"4rem",
				paddingRight:"4rem",
				paddingTop:'4rem'

			}}
			>
			
			<span 
			  style={{
				color: '#5BBCEA',
				 fontSize: 30.13, 
				 fontFamily: 'Montserrat', 
				 fontWeight: '600', 
				 textTransform: 'uppercase', 
				}}>
				BGB2LOB
			</span>
			<span 
			  style={{color: '#5BBCEA', fontSize: 30.13, fontFamily: 'Montserrat', fontWeight: '600'}}> </span><span style={{color: 'white', fontSize: 30.13, fontFamily: 'Montserrat', fontWeight: '600'}}>–</span>
			  <span style={{color: '#5BBCEA', fontSize: 30.13, fontFamily: 'Montserrat', fontWeight: '600'}}> </span><span style={{color: 'white', fontSize: 30.13, fontFamily: 'Montserrat', fontWeight: '600'}}>Votre première solution aux services de transport
			  </span>

            {/* Sous-texte */}
        <div 
            style={{
                paddingTop: '30px', 
                color: 'white', 
                fontSize: 22, 
                fontFamily: 'Montserrat', 
                fontWeight: 600, 
                lineHeight: '1.5em',
            }}
        >
            Commander votre transporteur avec chauffeur en quelques clics !
        </div>
		
		    </div>
			<div
  style={{
    backgroundColor: "white",
    borderRadius: "1rem",
    boxShadow:
      "0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)",
    padding: "1rem 2rem",
    color: "#333333",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem",
   margin:"4rem",
   fontFamily:"Montserrat"
  }}
>
  {/* Course à course */}
  {/* <label
    style={{
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      cursor: "pointer",
    }}
  >
    <input
      type="radio"
      id="course-a-course"
      checked={typeCommande === "course-a-course"}
      onChange={(e) => {
        if (e.target.checked) {
          setTypeCommande("course-a-course");
        }
      }}
      name="type_commande"
      style={{ cursor: "pointer" }}
    />
    Course à course
  </label> */}

  {/* Mise à disposition */}
  <label
    style={{
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      cursor: "pointer",
    }}
  >
    <input
      type="radio"
      id="mise-a-disposition"
      checked={typeCommande === "mise-a-disposition"}
      onChange={(e) => {
        if (e.target.checked) {
          setTypeCommande("mise-a-disposition");
        }
      }}
      name="type_commande"
      style={{ cursor: "pointer" }}
    />
    Mise à disposition
  </label>
  <div style={{
	  width: '4px',
	  height:' 2rem',
	  background: '#1B6A901F',
	  margin:' 0 1rem'
  }}></div>
  {/* Immédiat */}
  <label
    style={{
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      cursor: "pointer",
    }}
  >
    <input
      type="radio"
      id="immediat"
      checked={temporisation === "immediat"}
      onChange={(e) => {
        if (e.target.checked) {
          setTemporisation("immediat");
        }
      }}
      name="temporisation"
      style={{ cursor: "pointer" }}
    />
    Immédiat
  </label>

  {/* Planifié */}
  <label
    style={{
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      cursor: "pointer",
    }}
  >
    <input
      type="radio"
      id="planifie"
      checked={temporisation === "planifie"}
      onChange={(e) => {
        if (e.target.checked) {
          setTemporisation("planifie");
        }
      }}
      name="temporisation"
      style={{ cursor: "pointer" }}
    />
    Planifié
  </label>

  {/* Bouton Commander */}
  <button
    onClick={() => {
      history.push(
        `/passer-commande?type_commande=${typeCommande}&temporisation=${temporisation}`
      );
    }}
    style={{
      color: "white",
      fontFamily: "Montserrat, sans-serif",
      background: "#5BBCEA",
      border: "none",
      padding: "0.75rem 1.5rem",
      cursor: "pointer",
      fontWeight: "bold",
      borderRadius: ".5rem",
      transition: "background 0.3s ease",
      boxShadow: "0 4px 6px rgba(0, 123, 255, 0.2)",
    }}
   
  >
    Commander
  </button>
</div>


    </div>

            </div>
			<div style={{
               position: 'relative',
               width: '100%',
               height: '460px',
			   backgroundColor:'#f7f8f8',
               backgroundImage: `url(${imageTransportSteps})`,
               backgroundSize: 'cover',
               backgroundPosition: 'center',
			   textAlign: 'center',
			   padding:"40px"

           }}>
            <h2 
			style={{
				color:'#25A6E4',
				fontFamily:'Montserrat',
                fontSize:'16px',
				fontWeight:400,
		
			}}>
			Comment ça fonctionne</h2>
			<p 
			style={{
              color:"#000",
			  fontFamily:'Montserrat',
			  fontSize:'28px',
			  fontWeight:400,
			  lineHeight:"39.01px",

			}}>
            Commander votre transport en toute simplicité en suivant ces 4 étapes
            faciles et pratiques.
            </p>
		   <div style={{
			display: 'flex',
			justifyContent: 'center',
			gap: '30px',
			flexWrap: 'wrap'
		}}>
        <div style={{
			 width: '250px',
			 textAlign: 'center'
		}}>
          <img 
		  src={step1} 
		  alt="Créer une commande" 
		  style={{
			width: '200px',
			height: '130px',
			marginBottom: '10px'
		  }} 
		  />
          <p  
		  style={{
			fontSize: '16px',
            color: '#333',
            fontWeight: 500,
            lineHeight: 1.5,
			fontFamily:'Montserrat',

		  }}>
			Créer une commande et payez en ligne
		 </p>
        </div>
        <div 
		style={{
			 width: '250px',
			 textAlign: 'center'
		}}>
          <img
            src={step2}
            alt="Transporteur adéquat"
			style={{
				width: '200px',
				height: '130px',
				marginBottom: '10px'
			  }} 
          />
          <p 
		  style={{
			fontSize: '16px',
            color: '#333',
            fontWeight: 500,
			fontFamily:'Montserrat',
            lineHeight: 1.5
		  }}>
            Un transporteur adéquat prendra en charge votre commande
          </p>
        </div>
        <div  
		style={{
			 width: '250px',
			 textAlign: 'center'
		}}>
          <img
            src={step3}
            alt="Chargement des marchandises"
            style={{
				width: '200px',
				height: '130px',
				marginBottom: '10px'
			  }} 
          />
          <p style={{
			fontSize: '16px',
            color: '#333',
            fontWeight: 500,
            lineHeight: 1.5,
			fontFamily:'Montserrat',

		  }}>
            Vos marchandises seront chargées dans les temps
          </p>
        </div>
        <div style={{
			 width: '250px',
			 textAlign: 'center'
		}}>
          <img
            src={step4}
            alt="Confirmation de fin"
			style={{
				width: '200px',
				height: '130px',
				marginBottom: '10px'
			  }} 
          />
          <p style={{
			fontSize: '16px',
            color: '#333',
            fontWeight: 500,
            lineHeight: 1.5,
			fontFamily:'Montserrat',

		  }}>
            Confirmez la fin de votre commande dès le déchargement de vos
            marchandises
          </p>
        </div>
           </div>
			</div>
			<div 
			style={{
				  padding:"40px",
				  fontFamily:'Montserrat',
                  backgroundColor:"#f7f8f8"
			}}>
				
				<div 
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					gap:'20px' 
				}}>
				<div style={{
					flex:1,
					textAlign:"center",
					minWidth: "250px",
				}}>
				<h2 
				    style={{
					color:"#25A6E4",
					fontWeight:400,
					fontSize:"16px"
				   }}
				>
					Nous sommes TPE
				</h2>
				<h3 style={{
                 color:"#333",
				 fontSize:"32px",
				 fontWeight:400,
				 lineHeight:"24.38px",
				 fontFamily:"Montserrat"
				}}>
					Qui nous sommes ?
				</h3>
				<p style={{
					color:"#606060",
					fontSize:"16px",
					fontWeight:400,
					lineHeight:"24.38px"
				}}>
						L’entreprise s’est développée depuis ses débuts en 2000 spécialisée
                        dans le transport et la livraison express à travers la France et
                        l’Europe.
				</p>
				</div>
					<div
					 style={{
						flex: 1,
						minWidth: "300px",
						position: "relative"
					}}>
					<img
                     src={delivery}
                     alt="Livreur"
                     style={{
						width: '100%',
						borderRadius: '8px',
						boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
					 }}
                    />
					<div 
					style={{
						 position: "absolute",
						 bottom: "10px",
						 right: "10px",
						 background:"#5BBCEA",
						 color: "white",
						 padding: "10px 20px",
						 borderRadius: "8px",
						 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
						 textAlign: "center"
					}}>
                      <p style={{
						 fontSize: "1.5rem",
						 fontWeight: "bold"
					  }}>1480+</p>
					  <p>Clients satisfaits</p>
					</div>

					</div>
					<div 
					style={{
						flex: 1,
						minWidth: "250px",
						textAlign:"center"
					}}>
					<p style={{
						fontFamily:'Montserrat',
						color:"#606060",
						fontSize:"16px",
						fontWeight:400,
						lineHeight:"24.38px"
					}}>
					Notre objectif est d’être un leader en gestion des commandes de
                    transport pour les entreprises et les particuliers à travers
                    l’Europe. Avec nos focus sur la France, nous proposons une
                    technologie innovante pour améliorer l’expérience client.
					</p>
					<p style={{
                     	fontFamily:'Montserrat',
	                    color:"#606060",
	                    fontSize:"16px",
	                    fontWeight:400,
	                    lineHeight:"24.38px"
					}}>
                     Nous sommes ravis de vous présenter
                     notre plateforme numérique  - B2BGLOB.              
					</p>
					<button 
					 onClick={()=>{
						history.push('/CreateAccount')
					  }}
					style={{
						backgroundColor:"#5BBCEA" ,
						color: "white",
						padding: "10px 20px",
						border: "none",
						borderRadius: "5px",
						fontSize: "1rem",
						cursor:"pointer" ,
						marginTop: "10px"
					}}>
						Créer un compte
					</button>


					</div>

				</div>
				
				

			</div>
			<div 
			 style={{
				 padding:"40px",
				 fontFamily:'Montserrat',
				 backgroundColor:"#f7f8f8",
				 textAlign: "center"

			 }}>
			<h2 style={{
				  fontSize: "32px",
				  fontWeight: 400,
				  marginBottom: "0.5rem",
				  color: "#25A6E4",
				  lineHeight:"39.01px"

			}}>
				Nos Services
			</h2>
			<p 
			style={{
				fontSize: '1rem',
				color: '#555',
				marginBottom: '2rem'
			}}>
              Découvrez nos services ci-dessous pour une expérience de gestion de
			  commandes transparente et efficace.
			</p>
			<div style={{
				  display: 'flex',
				  flexWrap: 'wrap',
				  justifyContent: 'center',
				  gap: '20px'
			}}>
		{services.map((service, index) => (
          <div 
		  style={{
			background: 'white',
			borderRadius: '8px',
			boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
			padding: '20px',
			maxWidth: "300px",
			textAlign: "left",
			transition: 'transform 0.3s ease'
		  }} 
		  key={index}>
            <img
              src={service.image}
              alt={service.title}
              style={{
				width: '200px',
				height:"150px",
				margin: "0 auto 10px",
				display: "block",
			  }}
            />
            <h3 
			 style={{
				textAlign: 'center', 
				color: '#25A6E4', 
				fontSize: "20px",
				fontFamily: 'Montserrat', 
				fontWeight: '400'
			}}>
				{service.title}
			</h3>
            <p 
			style={{
              fontSize: '16px',
			  fontFamily:"Montserrat",
              color: '#606060',
              lineHeight: '19.5px',
			  textAlign:'center'
			}}>
			{service.description}
			</p>
          </div>
        ))}

			</div>


			</div>
			<div 
			style={{
				padding:"40px",
				fontFamily:'Montserrat',
				backgroundColor:"#f7f8f8",
			}}>
			<div 
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				maxWidth: '1200px',
				margin: '0 auto'
			}}>
				<div 
				style={{
					flex: 1,
					maxWidth: '600px',
					color: '#333'
				}}>
				<h1 style={{
					fontSize: '36px',
					fontFamily:"Montserrat",
					fontWeight:500,
					color:'#25A6E4' ,
					marginBottom: "15px"
				}}>
					B2BGLOB pour les entreprises
				</h1>
               <p 
			   style={{
				fontSize: '16px',
				fontWeight:400,
				fontFamily:"Montserrat",
				lineHeight:"19.5px",
				marginBottom: "20px",
				color: "#606060"
			   }}>
               Une solution avancée permettant aux entreprises de gérer leurs
               services de transport.
              </p>
			  <ul style={{
				 listStyleType: "none",
				 padding: 0,
				 margin:"0 0 20px" 
			  }}>
              <li 
			  style={{
				marginBottom: "10px",
				fontSize: "1rem",
				color: "#555"
			  }}>
              Vous procure de la visibilité et accroît votre présence sur le
              marché.
              </li>
            <li
			  style={{
				marginBottom: "10px",
				fontSize: "1rem",
				color: "#555"
			  }} 
			>
              Obtenez une vision claire de vos opérations de transport.
            </li>
            <li 
			  style={{
				marginBottom: "10px",
				fontSize: "1rem",
				color: "#555"
			  }}>
              Gestion comptable intégrée pour le suivi de vos finances.
            </li>
          </ul>
		  <button 
		  onClick={()=>{
			history.push('/CreateAccount')
		  }}
		  style={{
			  display: "inline-block",
			  padding: "10px 20px",
			  fontSize: "1rem",
			  backgroundColor:"#5BBCEA" ,
			  color: 'white',
			  border: 'none',
			  borderRadius: "5px",
			  cursor: "pointer",
			  transition: "background-color 0.3s ease"
		  }}>
            Créer un compte Transporteur
          </button>
		 </div>
		 <div 
		 style={{
			flex: 1,
			maxWidth: "500px"
		 }}>
          <div style={{ position: "relative", width: "100%", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
  <img
    src={transporteurs}
    alt="Transporteurs"
    style={{
      width: "100%",
      borderRadius: "8px",
      display: "block",
    }}
  />
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "linear-gradient(to bottom, rgba(25, 81, 98, 0.7), rgba(25, 81, 98, 0.9))",
      borderRadius: "8px",
    }}
  ></div>
</div>

        </div>

			</div>

			</div>
		

			<Footer />
		</div>
	)
}
