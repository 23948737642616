import React,{useEffect,useState} from 'react'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import {  FormSectionCard} from '../../PasserCommande/PasserCommande.styles'
import { CustomInput } from '../../RegisterTransporter/style';
import {toggleSuspendreCommercial,softDeleteCommercial,getSingleCommercial} from '../../../api/commerciaux'
import { useForm,Controller } from 'react-hook-form';
import {  useSelector } from 'react-redux'
import { updateAccountCommercial } from '../../../api/commerciaux';
import { useHistory, useParams } from 'react-router'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import ActionModal from '../../../Components/ValidationModal/ActionModal';
Modal.setAppElement('#root')
function CommercantSingle() {
	const { id } = useParams()
    const history = useHistory()
    const [commercial,setCommercial]=useState(null)
    const [modalIsOpen,setModalIsOpen]=useState(false)
    const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [modalIsOpenUpdate,setModalIsOpenUpdate]=useState(false)
    const [suspendre, setSuspendre] = useState("")
	const [loading, setLoading] = useState(false)
	const [formValues, setFormValues] = useState(null); 
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const user = useSelector((state) => state.auth.user)
    const token = useSelector((state) => state.auth.token);
    const { handleSubmit,formState: { errors },control } = useForm();
	const isArchiveMode = window.location.pathname.includes('archive');  

	const onSubmit = async (values) => {
		setFormValues(values)
		setModalIsOpenUpdate(true)
	}
	async function fetchCommercial(id){
		setChargementEnCours(true)
		try {
			const data=await getSingleCommercial(id,token)
			setCommercial(data)
			setSuspendre(data.status)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
				  fontFamily: 'Montserrat',
				},
		});
		}finally{
			setChargementEnCours(false)
		}
		
		

	  } 
    useEffect(()=>{
       
          if(id){
            fetchCommercial(id)
          }
       
        },[id])
  return (
    <LayoutAdmin>
    <div
    style={{
        width: '100%',
        fontFamily: 'Montserrat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '1rem',
        }}
    >
		{chargementEnCours ? 
		 <div
		 style={{
		   display: 'flex',
		   alignItems: 'center',
		   justifyContent: 'center',
		   backgroundColor: 'white',
		   marginTop:"10rem"
		 }}
		 >
		 <div className='customLoader'></div>	
		 </div>
		 :
        <form
            style={{ width: '65%' }}
            onSubmit={handleSubmit(onSubmit)}
        >
        <FormSectionCard>
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <p
                            style={{
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                           Fiche Commercial
                        </p>
                    </div>
					{!isArchiveMode &&
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <button
                            onClick={ () => {setModalIsOpen(true) }}
                            style={{
                                border: 'none',
                                background: '#ECC9F7',
                                color: '#AB1CC5',
                                fontSize: 17,
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                padding: '0.6rem 1rem',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                           
                        >
					    {suspendre==="actif" ? 'Suspendre' : 'Activer'}
                        </button>
                        {(user.role==="admin" || user.role==="super admin") &&
                        <button
                           onClick={async () => {
                           setModalIsOpenDelete(true)
                        }}
                            style={{
                                border: 'none',
                                background: '#F7CDC9',
                                color: '#C5431C',
                                fontSize: 17,
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                padding: '0.6rem 1rem',
                                borderRadius: 5,
                                cursor: 'pointer',
                                marginLeft: '0.5rem',
                            }}
                          
                        >
                            Supprimer
                        </button>
                         }
                        <button
                            type='submit'
                            style={{
                                border: 'none',
                                background: '#168EEA',
                                color: '#FFF',
                                fontSize: 17,
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                padding: '0.6rem 1rem',
                                borderRadius: 5,
                                cursor: 'pointer',
                                marginLeft: '0.5rem',
                            }}
                        >
                            Enregistrer
                        </button>
                        <button
                        onClick={()=>{
							
							history.push(`/admin/commerçants/${id}/clients`)
						}

						}
									style={{
										border: 'none',
										background: '#50F5A9',
										color: '#FFF',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '0.5rem',
									}}
								>
									 Liste Client
								</button>
                    </div>
             }
        </div>
        </FormSectionCard>  
        <FormSectionCard>
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Nom
                        </span>
                        <CustomInput
						type='text'
						defaultValue={commercial?.last_name}
                        disabled	
						style={{
							backgroundColor: '#f0f0f0', 
							color: '#a9a9a9',           
							cursor: 'not-allowed'       
						}}			
						/>
                    </div>

                    <div style={{ width: 50 }} />

                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Prénom
                        </span>
                        <CustomInput
                            disabled
							defaultValue={commercial?.first_name}
							type='text'
							style={{
								backgroundColor: '#f0f0f0', 
								color: '#a9a9a9',           
								cursor: 'not-allowed'       
							}}	
						/>
                    </div>
        </div>  
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '2rem',
                        }}
                    >
                        <span
                            style={{
                                color: '#262626',
                                paddingBottom: '1rem',
                            }}
                        >
                            Adresse email
                        </span>
                        <CustomInput
                            disabled
							defaultValue={commercial?.email}
							type='text'
							style={{
								backgroundColor: '#f0f0f0', 
								color: '#a9a9a9',           
								cursor: 'not-allowed'       
							}}	
						/>
                    </div>            
        </div>  
      
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
                                    disabled
									type='text'
									defaultValue={commercial?.address || 'Non disponible'}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
									}}	
								/>

		</div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville
								</span>
								<CustomInput
                                    disabled
									defaultValue={commercial?.city || 'Non disponible'}
									type='text'
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
									}}	
								/>
		</div>
        <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
                                    disabled
									defaultValue={commercial?.postalCode  || 'Non disponible'}
									type='text'
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
									}}	
								/>
		</div>
        <div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
                                        disabled
										defaultValue={commercial?.phone  || 'Non disponible'}
										type='text'
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}	
										
									/>
									
		</div>
        <div
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									
                                Paramétrer une réduction en %
								</span>
								<Controller
                                    name="reduction"
                                    control={control}
									defaultValue={commercial?.reduction }
									rules={{
										min: { value: 0, message: "La réduction doit être au moins 0%" }, // Validation : valeur minimale
										max: { value: 100, message: "La réduction ne peut pas dépasser 100%" }, // Validation : valeur maximale
									}}
									render={({ field }) => (
									<CustomInput
									type='number'
									min={0}
									max={100}
									disabled={isArchiveMode} 
									style={{
										backgroundColor: isArchiveMode && '#f0f0f0' ,
										color: isArchiveMode && '#a9a9a9',
										cursor: isArchiveMode && 'not-allowed',
									}}
									{...field} 
									/>
                                    )}
                                    />
								   {errors.reduction && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                    {errors.reduction.message}
                                    </div>
                                   )}
								
                             
							</div>
        </FormSectionCard>    
      
        </form>
        }
        
    </div>
	<ActionModal
			  isOpen={modalIsOpen}
			  onRequestClose={()=>setModalIsOpen(false)}
			  title={suspendre==="actif" ? 'Suspendre Commercial' :  'Activer Commercial' }
			  message=	{`Êtes-vous sûr de vouloir ${suspendre==="actif" ? 'Suspendre' : 'Activer'} ce Commercial ?`}
			  onConfirm={async (e) => {	
				e.preventDefault();
				setLoading(true)
				try {
					const data={status:suspendre==="actif" ? "suspendu" :"actif"}
					const res=await toggleSuspendreCommercial (
						commercial._id,
						data,
						token
					)
				toast.success(res.message, {
				style: {
					fontFamily: 'Montserrat',
				},
			})
			setSuspendre(suspendre==="actif" ? "suspendu" :"actif")
			history.goBack()
				} catch (error) {
					toast.error(
						error.response?.data.message || 'Erreur lors de la suspension du client',
						{
							style: {
								fontFamily: 'Montserrat',
							},
						}
					)
				}finally {
					setLoading(false)
					setModalIsOpen(false)
				}	
				}}
				
			  onCancel={()=>setModalIsOpen(false)}
			  isLoading={loading}
			/>
   
    <Modal
				isOpen={modalIsOpenDelete}
                onRequestClose={() => setModalIsOpenDelete(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Commercial</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce commercial ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
							 const res=await softDeleteCommercial(commercial._id,token)
							 toast.success(res.message, {
								 style: {
									 fontFamily: 'Montserrat',
								 },
							 })
							 history.goBack()
							} catch (error) {
							 toast.error(
								 error.response?.data.message || "Erreur lors de la suppression du commercial ",
								 {
									 style: {
										 fontFamily: 'Montserrat',
									 },
								 }
							 )
							}finally {
							 setLoading(false)
							 setModalIsOpenDelete(false)
						 }
							
                            }}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					 {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
					 )} 
					</button>
					<button
						onClick={ () => {
							setModalIsOpenDelete(false)					
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>
	<Modal
				isOpen={modalIsOpenUpdate}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='modification commercial'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>
                    Modifier le compte		            
                    </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						Êtes-vous sûr de vouloir modifier le compte de ce Commercial ??
						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								const updatedFormValues = {
									...formValues,
									reduction: formValues.reduction ?? commercial.reduction
								};
								await updateAccountCommercial(commercial._id,updatedFormValues, token)
								toast.success("Le compte a été modifié avec succès", {
									style: {
										fontFamily: 'Montserrat',
									},
								});
								history.goBack();
							} catch (error) {
								toast.error(error.response?.data.message ||  'Erreur lors de la mise à jours du compte commercial', {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally{
								setModalIsOpenUpdate(false)
								setLoading(false)
							}
						}}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
					        justifyContent: 'center',
					        alignItems: 'center'
						}}
					>
				   {loading ? 	<div className='loader'></div> : 'Confirmer'} 

					</button>
					<button
						onClick={ () => {
							setModalIsOpenUpdate(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
    </LayoutAdmin>
  )
}

export default CommercantSingle