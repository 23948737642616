import React from 'react'
import CommonHeader from '../../Components/CommonHeader/common-header.component'
import Footer from '../../Components/Footer/footer.component'

function PolitiqueConfidentialite() {
  return (
    <div>
    <CommonHeader />
    <div style={{
        backgroundColor:'#f7f8f8',
        fontFamily:"Montserrat",
        padding: "40px",

    }}>
      <h1 style={{
         fontSize: "2rem",
         marginBottom: "20px",
         color: "#25A6E4"
      }}>
        Politique de confidentialité
     </h1>

      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>
            1. Définitions
        </h2>
        <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
         Lors de votre utilisation de notre Site et de la passation de commande sur notre Site, nous pouvons être amenés à vous demander de nous communiquer des données à caractère personnel vous concernant.
        </p>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
        Le terme <strong>« données à caractère personnel »</strong> désigne toutes les données qui permettent d’identifier un individu, ce qui correspond notamment à vos nom, prénoms, pseudonyme, photographie, adresse postale et de courrier électronique, intitulé de votre poste, numéros de téléphone, date de naissance, données relatives à vos transactions sur le Site, détails de vos achats, numéros de carte bancaire, SIRET, numéro de TVA intracommunautaire, adresse IP, ainsi qu’à tout autre renseignement que vous choisirez de nous communiquer à votre sujet.
        </p>
      </article>

      <article>
        <h2 
        style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>2. Responsable de traitement</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
         Le responsable de traitement de vos données à caractère personnel est la société à responsabilité limitée TRANSPORT PROFESSIONNEL EXPRESS au capital de 57.000 euros, dont le siège est situé 3 avenue Montesquieu – 77270 Villeparisis, immatriculée au Registre du Commerce et des Sociétés de Meaux sous le numéro 802 636 985.
          Le responsable de traitement est la société <strong>TRANSPORT PROFESSIONNEL EXPRESS</strong>, située 3 avenue Montesquieu – 77270 Villeparisis.
        </p>
      </article>

      <article>
        <h2
         style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>3. Finalités de traitement</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
          Vos données sont collectées pour les finalités suivantes :
          <ul 
          style={{
             margin: "10px 0 20px",
             paddingLeft: "20px"
          }}>
            <li style={{
                 listStyleType: 'disc'
            }}>Gérer l'accès aux services et leur utilisation.</li>
            <li style={{
                 listStyleType: 'disc'
            }}>
            Effectuer les opérations relatives à la gestion des clients concernant les contrats, factures, suivi de la relation avec les clients.</li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
            Constituer un fichier d’utilisateurs, de clients et prospects.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
             Adresser des newsletters, sollicitations et messages promotionnels. Dans le cas où vous ne le souhaiteriez pas, nous vous donnons la faculté d’exprimer votre refus à ce sujet lors de la collecte de vos données.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
            Élaborer des statistiques commerciales et de fréquentation de nos services.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
            Organiser des jeux concours, loteries et toutes opérations promotionnelles à l’exclusion des jeux d’argent et de hasard en ligne soumis à l’agrément de l’Autorité de Régulation des Jeux en ligne.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
            Gérer la gestion des avis des personnes sur des produits, services ou contenus.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
            Gérer les impayés et les contentieux éventuels quant à l’utilisation de nos produits et services.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>
            Personnaliser les réponses à vos demandes d’information.
            </li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>Respecter nos obligations légales et réglementaires. Nous vous informons, lors de la collecte de vos données personnelles, si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives (par des astérisques visibles sur le Site).</li>
          </ul>
        </p>
      </article>

      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>4. Destinataires des données à caractère personnel</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
        Auront accès à vos données personnelles :          
        <ul style={{
             margin: "10px 0 20px",
             paddingLeft: "20px"
          }}>
            <li style={{
                 listStyleType: 'disc'
            }}>Le personnel de notre société.</li>
            <li
             style={{
                 listStyleType: 'disc'
            }}>Les services chargés du contrôle (avocat, comptable notamment).</li>
            <li 
            style={{
                 listStyleType: 'disc'
            }}>Nos sous-traitants:
             <ul style={{
                 listStyle: 'circle',
                 paddingLeft: '20px'
             }}>
              <li>Prestataires de communication,</li>
              <li>Prestataires de logiciels de productivité,</li>
              <li>Prestataire d’hébergement des données,</li>
              <li>Prestataires de service de paiement,</li>
              <li>Prestataires de facturation,</li>
              <li>Prestataires d’analyse et de mesure d’audience,</li>
              <li>Prestataires de gestion de la relation client.</li>
            </ul>
            </li>
            <li style={{
                 listStyleType: 'disc'
            }}>Peuvent également être destinataires de vos données à caractère personnel les organismes publics, exclusivement pour répondre à nos obligations légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le recouvrement de créances.</li>
          </ul>
        </p>
      </article>
      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>5. Durée de conservation des données à caractère personnel</h2>
        <section>
        <h3 style={{ fontSize: "1.1rem", margin: "10px 0"}}>
         5.1 Concernant les données relatives à la gestion de clients et prospects :
        </h3>
       <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
       Vos données à caractère personnel ne seront pas conservées au-delà de la
      durée strictement nécessaire à la gestion de notre relation commerciale
      avec vous.
      </p>
    <p 
     style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      Toutefois, les données permettant d’établir la preuve d’un droit ou d’un
      contrat seront conservées pendant la durée prévue par la loi en vigueur.
    </p>
    <p 
    style={{
             fontSize: "1rem",
             lineHeight: "1.6"
    }}>
      Concernant les opérations de prospection :
      <ul  
      style={{
             margin: "10px 0 20px",
             paddingLeft: "20px"
      }}>
        <li style={{
                 listStyleType: 'disc'
        }}>
          <strong>Clients :</strong> Les données seront conservées pendant 3
          ans après la fin de la relation commerciale.
        </li>
        <li style={{
                 listStyleType: 'disc'
            }} >
          <strong>Prospects non clients :</strong> Les données seront
          conservées pendant 3 ans après leur collecte ou le dernier contact
          émanant du prospect.
        </li>
      </ul>
    </p>
    <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      En cas d’exercice du droit d’accès ou de rectification, les données
      relatives aux pièces d’identité pourront être conservées pendant 1 an.
      Pour les oppositions, ces données peuvent être archivées pendant 6 ans
      (article 8 du Code de procédure pénale).
    </p>
  </section>

  <section>
    <h3 style={{ fontSize: "1.1rem", margin: "10px 0"}}>
      5.2 Concernant la gestion des listes d’opposition à recevoir de la
      prospection :
    </h3>
    <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      Les informations permettant de prendre en compte votre droit d’opposition
      sont conservées au minimum 3 ans à compter de l’exercice de ce droit.
    </p>
  </section>

  <section>
    <h3 style={{ fontSize: "1.1rem", margin: "10px 0" }}>
      5.3 Concernant les statistiques de mesure d’audience :
    </h3>
    <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      Les informations stockées dans le terminal des utilisateurs ou tout autre
      élément utilisé pour identifier les utilisateurs et permettant leur
      traçabilité ne seront pas conservées au-delà de 13 mois.
    </p>
  </section>

  <section>
    <h3 style={{ fontSize: "1.1rem", margin: "10px 0"}}>
      Transactions financières :
    </h3>
    <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      Les transactions financières relatives au paiement des achats et des
      frais via le Site sont confiées à un prestataire de services de paiement
      qui en assure la sécurité. Vos données de carte bancaire :
    </p>
    <ul style={{
             margin: "10px 0 20px",
             paddingLeft: "20px"
      }} >
      <li style={{
                 listStyleType: 'disc'
            }}>
        <strong>Durée de conservation :</strong> Vos données sont conservées
        pendant le temps de votre inscription ou jusqu'à votre dernière
        transaction.
      </li>
      <li style={{
                 listStyleType: 'disc'
            }}>
        <strong>Cryptogramme visuel :</strong> Le CVV2 n’est pas stocké.
      </li>
    </ul>
    <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      En cas de refus de conservation des données bancaires, celles-ci ne sont
      pas conservées au-delà de la transaction.
    </p>
    <p style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
      Les données liées à la transaction sont archivées pendant 13 mois
      (article L133-24 du Code monétaire et financier) et jusqu’à 15 mois pour
      les cartes à débit différé.
    </p>
  </section>
      </article>

      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>6. Sécurité</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
         Nous vous informons prendre toutes précautions utiles, mesures organisationnelles et techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos données à caractère personnel et notamment, empêcher qu’elles soient déformées, endommagées ou que des tiers non autorisés y aient accès. Nous recourrons également à des systèmes de paiement sécurisé conformes à l’état de l’art et à la réglementation applicable.
        </p>
      </article>

      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>7. Hébergement</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
          Vos données sont conservées sur les serveurs sécurisés de notre hébergeur.
        </p>
      </article>
      
      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>8. Transfert hors de l'Union européenne</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
       Vos données peuvent être transférées hors de l’Union européenne dans le
       cadre des outils que nous utilisons et de nos relations avec nos
       sous-traitants. Ces transferts sont sécurisés au moyen des dispositifs
       suivants :       
        </p>
        <ul
        style={{
          margin: "10px 0",
          paddingLeft: "20px",
          fontSize: "1rem",
          lineHeight: "1.6",
        }}
       >
     <li   style={{
        listStyleType: 'disc'
        }}>
      <strong>Pays adéquats :</strong> Les données sont transférées dans un
      pays reconnu comme offrant un niveau de protection adéquat par une
      décision de la Commission Européenne.
    </li>
    <li   style={{
        listStyleType: 'disc'
        }}>
      <strong>Clauses contractuelles types :</strong> Nous avons conclu avec
      nos sous-traitants des contrats spécifiques encadrant les transferts de
      vos données en dehors de l’Union Européenne. Ces contrats sont basés sur
      les clauses contractuelles types approuvées par la Commission Européenne.
    </li>
  </ul>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Ces dispositifs garantissent un niveau de sécurité élevé et la protection
    de vos données personnelles, conformément aux exigences légales en vigueur.
  </p>
    
      </article>

      <article> 
     <h2
    style={{
      fontSize: "1.3rem",
      margin: "20px 0 10px",
      color: "#333",
    }}
  >
    9.  Accès, Rectification, Limitation et Effacement de vos Données à Caractère Personnel
  </h2>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Conformément à la réglementation européenne en matière de protections des
    données à caractère personnel, vous bénéficiez des droits suivants :
  </p>
  <ul
    style={{
      margin: "10px 0",
      paddingLeft: "20px",
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    <li   style={{
        listStyleType: 'disc'
        }}>
      <strong>Droit d’accès :</strong> Vous pouvez savoir si vos données
      personnelles sont traitées par nos services et, le cas échéant, y accéder
      ainsi qu'aux informations requises par la loi.
    </li>
    <li   style={{
        listStyleType: 'disc'
        }}>
      <strong>Droit de rectification :</strong> Vous pouvez demander la
      correction de toute inexactitude concernant vos données personnelles dans
      les meilleurs délais.
    </li>
    <li   style={{
        listStyleType: 'disc'
        }}>
      <strong>Droit à l’effacement :</strong> Vous pouvez demander l’effacement
      de vos données personnelles, sous réserve que cette demande respecte les
      conditions légales applicables.
    </li>
    <li   style={{
        listStyleType: 'disc'
        }}>
      <strong>Droit à la limitation :</strong> Vous pouvez demander une
      limitation du traitement de vos données personnelles, sous réserve des
      conditions requises par la loi.
    </li>
    <li>
      <strong>Droit à la portabilité :</strong> Vous pouvez recevoir vos
      données personnelles dans un format structuré, couramment utilisé et
      lisible par machine, ou demander leur transmission à un autre responsable
      de traitement.
    </li>
    <li  
    style={{
        listStyleType: 'disc'
        }}>
      <strong>Droit d’opposition :</strong> Vous pouvez vous opposer au
      traitement de vos données pour des raisons tenant à votre situation
      particulière, sous réserve des conditions légales.
    </li>
    <li  
    style={{
        listStyleType: 'disc'
        }}>
      <strong>Retrait du consentement :</strong> Vous pouvez retirer votre
      consentement à tout moment, notamment lorsque vos données sont traitées à
      des fins de prospection commerciale.
    </li>
    <li  
    style={{
        listStyleType: 'disc'
    }}>
      <strong>Directives post-mortem :</strong> Vous pouvez définir des
      directives relatives à la conservation, l’effacement et la communication
      de vos données après votre décès.
    </li>
  </ul>

  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Vous avez également le droit de limiter le traitement de vos données dans
    les cas suivants :
  </p>
  <ul
    style={{
      margin: "10px 0",
      paddingLeft: "20px",
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    <li 
    style={{
        listStyleType: 'disc'
    }}>
      Pendant la durée de vérification lorsque vous contestez l’exactitude de
      vos données personnelles.
    </li>
    <li   
    style={{
        listStyleType: 'disc'
        }}>
      Lorsque le traitement est illicite mais que vous souhaitez limiter ce
      traitement plutôt que supprimer vos données.
    </li>
    <li 
    style={{
        listStyleType: 'disc'
        }}>
      Lorsque nous n’avons plus besoin de vos données, mais que vous souhaitez
      leur conservation pour exercer vos droits.
    </li>
    <li 
        style={{
        listStyleType: 'disc'
        }}>
      Pendant la période de vérification des motifs légitimes si vous vous êtes
      opposés au traitement.
    </li>
  </ul>

  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Si vos données sont collectées sur la base de notre intérêt légitime, vous
    pouvez à tout moment vous opposer au traitement de celles-ci. Toutefois, le
    traitement pourra être poursuivi s’il existe des motifs légitimes qui
    prévalent sur vos droits ou s’il est nécessaire pour constater, exercer ou
    défendre nos droits en justice.
  </p>

  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Vous pouvez vous désabonner des emails promotionnels via le lien fourni
    dans nos messages. Cependant, vous continuerez à recevoir nos messages
    administratifs.
  </p>

  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Vous pouvez exercer vos droits à tout moment en nous contactant à :
  </p>
  <ul
    style={{
      margin: "10px 0",
      paddingLeft: "20px",
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    <li 
    style={{
        listStyleType: 'disc'
    }}>
      <strong>Email :</strong>{" "}
      <a href="mailto:tpe-transport@hotmail.com">
        tpe-transport@hotmail.com
      </a>
    </li>
    <li 
      style={{
     listStyleType: 'disc'
    }}>
      <strong>Adresse :</strong> 3 avenue Montesquieu – 77270 Villeparisis.
    </li>
  </ul>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Si vous avez des réclamations concernant le traitement de vos données
    personnelles, contactez-nous en utilisant les coordonnées ci-dessus.
  </p>
      </article>

      <article>
  <h2
    style={{
      fontSize: "1.3rem",
      margin: "20px 0 10px",
      color: "#333",
    }}
  >
    10. Portabilité de vos données à caractère personnel
  </h2>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Vous disposez d’un droit à la portabilité des données à caractère personnel
    que vous nous aurez fournies. Cela inclut les données que vous avez
    déclarées activement et consciemment dans le cadre de l’accès et de
    l’utilisation des services, ainsi que les données générées par votre
    activité lors de l’utilisation de nos services.
  </p>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Nous vous rappelons que ce droit ne s’applique pas aux données collectées
    et traitées sur une autre base légale que le consentement ou l’exécution du
    contrat nous liant.
  </p>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Ce droit peut être exercé gratuitement, à tout moment, et notamment lors de
    la fermeture de votre compte sur le Site, afin de récupérer et conserver
    vos données à caractère personnel.
  </p>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Dans ce cadre, nous vous adresserons vos données personnelles, par tous
    moyens jugés utiles, dans un format ouvert, standard, couramment utilisé et
    lisible par informatique, conformément à l’état de l’art.
  </p>

  <h2
    style={{
      fontSize: "1.3rem",
      margin: "20px 0 10px",
      color: "#333",
    }}
  >
    11. Introduction d’une réclamation devant une autorité de contrôle
  </h2>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Vous êtes informé que vous disposez du droit d’introduire une réclamation
    auprès d’une autorité de contrôle compétente, telle que la{' '}
    <strong>Commission Nationale Informatique et Libertés (CNIL)</strong> pour
    la France, dans l’État membre où se trouve votre résidence habituelle, votre
    lieu de travail ou le lieu où une violation de vos droits aurait été
    commise.
  </p>
  <p
    style={{
      fontSize: "1rem",
      lineHeight: "1.6",
    }}
  >
    Ce recours peut être exercé sans préjudice de tout autre recours devant une
    juridiction administrative ou juridictionnelle. Vous disposez également d’un
    droit à un recours administratif ou juridictionnel effectif si vous
    considérez que le traitement de vos données personnelles constitue une
    violation des textes applicables.
  </p>
</article>


      <article>
        <h2 style={{
            fontSize: "1.3rem",
            margin:"20px 0 10px",
            color: '#333'
        }}>12. Modifications</h2>
        <p 
        style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification
          sera publiée sur cette page.
        </p>
      </article>

      <p 
      style={{
             fontSize: "1rem",
             lineHeight: "1.6"
        }}>
        Pour toute question relative à cette politique.
      
      </p>
    </div>
    <Footer />

    </div>
  )
}

export default PolitiqueConfidentialite