import React, { useEffect, useState } from 'react'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import { useSelector } from 'react-redux'
import {attributionMiseAdispoChauffeur,getMissionsByCommandeId} from '../../api/commande'
import {getValidChauffeursByTransporter } from '../../api/chauffeurs'
import Modal from 'react-modal'
import Select from 'react-select'
import toast from 'react-hot-toast'
import { useParams,useHistory } from 'react-router'
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles'
import TransfertMissionChauffeur from '../../Components/TransfertMissionChauffeur/TransfertMissionChauffeur'
import PointsPassageModal from '../../Components/PointsPassages/PointsPassageModal'
import MissionCard from '../../Components/MissionCard/MissionCard'
import ForfaitSupplementaireModal from '../../Components/ForfaitSupplementaire/ForfaitSupplementaireModal'
import ActionModal from '../../Components/ValidationModal/ActionModal'


function MissionsTransporteur() {
    const  {id}=useParams()
    const history=useHistory()

	const [loading,setLoading]=useState(false)

    const [selectedMission,setSelectedMission]=useState(null)
    const [selectedChauffeur, setSelectedChauffeur] = useState(null)

	const [modalIsOpenTransfret,setModalIsOpenTransfret]=useState(false)
	const [modalIsOpenPlace, setModalIsOpenPlace] = useState(false);
	const [modalIsOpenForfait,setModalIsOpenForfait]=useState(false)

	const [isConfirmationOpenAttribuer, setIsConfirmationOpenAttribuer] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)

	const user = useSelector(state => state.auth.user)
	const token = useSelector((state) => state.auth.token);

    const [chargementEnCours,setChargementEnCours]=useState(false)
	const [refresh, setRefresh] = useState(false);
	const [chauffeurs, setChauffeurs] = useState([])
	const [missions, setMissions] = useState([])


	useEffect(() => {
		async function fetchChauffeurs() {
			try {
				const res = await getValidChauffeursByTransporter(user._id, token);
				setChauffeurs(res);
			} catch (error) {
				toast.error("Une erreur s'est produite", {
					style: {
						fontFamily: 'Montserrat',
					},
				});			}
		}
	
		fetchChauffeurs();
	}, []);
	
    async function fetchMissions(id) {
		setChargementEnCours(true)
		try {
			const res = await getMissionsByCommandeId(id,token)
			setMissions(res)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
      

    }
	useEffect(() => {
		fetchMissions(id)
	}, [id,refresh])

	
  

	return (
        <LayoutTransporter>
            <div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
                <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Gestion des mission'.toUpperCase()}
						</GradientText>
					</div>
					{chargementEnCours ?
					<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginTop:"10rem"
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div>
				     :	
                <div
                style={{width:'90%',
                    marginTop:'1rem'
                }}>
            {missions.map((mission, index) => (
            <MissionCard
			key={mission._id}
			mission={mission}
			index={index}
			hidecancel
			hideFiles
            hideAddForfait
            hideValidateMission
			onClickForfaits={()=>{
				setSelectedMission(mission)
				setModalIsOpenForfait(true);
			}}
			onClickAttribuer={() => {
				setSelectedMission(mission);
				setModalIsOpen(true);
			}}
			onClickTransferer={()=>{
				setSelectedMission(mission);
				setModalIsOpenTransfret(true);
			}}
			onClickHistoriqueChauffeur={()=>{
		         history.push(`/transports/mission/historique-chauffeur/${mission._id}`);

			}}
			onClickPlaces={()=>{
				setSelectedMission(mission)
				setModalIsOpenPlace(true);
			}} 
		  />
           ))}
                </div>
                   }
           </div>
           <Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#fff',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '25rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontWeight: 'bold',
							fontSize: 30,
							marginBottom: '2rem',
						}}
					>
						Attribuer un transport
					</span>
					<div
						style={{
							marginBottom: '2rem',
						}}
					>
						Veuillez sélectionner le chauffeur <br /> que vous
						voulez affecter à cette mission
					</div>

					<div
						style={{
							width: '80%',
						}}
					>
						<Select
							options={chauffeurs.map((c) => ({
								value: c._id,
								label: `${c.first_name} ${c.last_name}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
                                    border: '1px solid #ddd',
                                    fontSize: '14px',
                                    outline: 'none',
								}),
							}}
							menuPortalTarget={document.querySelector('body')}
							onChange={(x) => setSelectedChauffeur(x.value)}
						/>
					</div>
					<div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
						<div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
							<button
						onClick={ () => {
							if(!selectedChauffeur){
								toast.error(
									'Il faut séléctionner un chauffeur',
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)

							}else{
								setIsConfirmationOpenAttribuer(true)
								setModalIsOpen(false)
							}


						}}
						
                        style={{
                          width: '150px',
                          height: '50px',
                          background: '#5BBCEA',
                          border: 'none',
                          cursor: 'pointer',
                          color: 'white',
                          borderRadius: 7,
                          marginTop: 5,
                          fontFamily: 'Montserrat',
                          marginRight: 10,
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          }}
					>
						Attribuer
					</button>
					<button
                    onClick={() => {
                                    setModalIsOpen(false);
                                    setSelectedMission(null)
									setSelectedChauffeur(null)
                                }}
								style={{
                                    width: '150px',
                                    height: '50px',
                                    background: 'red',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    borderRadius: 7,
                                    marginTop: 5,
                                    fontFamily: 'Montserrat',
                                    fontWeight: 'bold',
								}}
                            >
                                Annuler
                    </button>
				
					</div>
					</div>
					
				</div>
			</Modal>
		
			<ActionModal 
			  isOpen={isConfirmationOpenAttribuer}
			  onRequestClose={()=>setIsConfirmationOpenAttribuer(false)}
			  title="Confirmation"
			  message="Êtes-vous sûr de vouloir attribuer cette mission ?"
			  onConfirm={async()=>{
				setLoading(true)
				try{
					const  response=await attributionMiseAdispoChauffeur(
						{missionID:selectedMission?._id,
						chauffeurID:selectedChauffeur}
						,
						token
					)
					toast.success(response.message, {
						style: {
						  fontFamily: 'Montserrat',
						},
					  });

				
				
				}catch(error){
					if (error.response && error.response.status === 403) {
                        toast.error(error.response.data.error, {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}else{
						toast.error("Une erreur s'est produite", {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}

				}finally{
					setLoading(false)
					setIsConfirmationOpenAttribuer(false)
                    await  fetchMissions(id)
                    setSelectedMission(null)
					setSelectedChauffeur(null)
				}
                }}
			 onCancel={()=>{
				setIsConfirmationOpenAttribuer(false)
				setSelectedMission(null)
				setSelectedChauffeur(null)
			 }}
			 isLoading={loading}
			/>
	        <TransfertMissionChauffeur 
	         modalIsOpenTransfret={modalIsOpenTransfret}
		     setModalIsOpenTransfret={setModalIsOpenTransfret}
		     selectedMission={selectedMission?._id}
			 setRefresh={setRefresh}
	        />
			<PointsPassageModal 
			  isOpen={modalIsOpenPlace} 
			  onClose={() => setModalIsOpenPlace(false)}
			  missionId={selectedMission?._id}
			/>
			<ForfaitSupplementaireModal
			 isOpen={modalIsOpenForfait} 
			 onClose={() => setModalIsOpenForfait(false)}
			 missionId={selectedMission?._id}
			 marge={selectedMission?.commande?.margin}
			 
			/>
        </LayoutTransporter>
	)
}

export default MissionsTransporteur