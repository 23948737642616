import { GradientText, SubText } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	searchCommandesByDateForTransporter,
	searchCommandesByPrixMinMaxForTransporter,
	searchCommandesByIdForTransporter
} from '../../api/transporteurs'

import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontalForTransporteur/MiseADispoHorizontal'
import Select from 'react-select'
import { getCommandesTerminerbyTransporter } from '../../api/commande'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'
import toast from 'react-hot-toast'
import DetailCommandeModal from '../../Components/DetailCommande/DetailCommandeModal'

const moment = require('moment')
const SIZE = 10

function HistoriqueTransporter(props) {
	const history = useHistory()
	const [commandes, setCommandes] = useState([])

	const transporterId = useSelector((state) => state.auth.user._id)
	const token = useSelector((state) => state.auth.token);
	
	const [page, setPage] = useState(1)
	const [totalPages,setTotalPages]=useState(1)

	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [isSearching, setIsSearching] = useState(false); 
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
    const [selectedCommande,setSelectedCommande]=useState(null)
	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	
	const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };


	const fetchCommandes = async (page) => {
		setChargementEnCours(true);
		try {
			let res;
			if (isSearching) {
				if (searchCriteria === 'id') {
					res = await searchCommandesByIdForTransporter(searchQueryId, transporterId,'historique', token,);
				} else if (searchCriteria === 'prix') {
					res = await searchCommandesByPrixMinMaxForTransporter(min, max, transporterId, 'historique', token, page, SIZE);
				} else if (searchCriteria === 'date') {
					let minDate = moment(range[0]).format();
					let maxDate = moment(range[1]).format();
					let offsetUTC = moment().format("Z");
					
					res = await searchCommandesByDateForTransporter(minDate, maxDate, transporterId, 'historique', token, page, SIZE,offsetUTC);
				}
			} else {
				res = await getCommandesTerminerbyTransporter(transporterId,page,SIZE,token)

			}
			setCommandes(res.commandes);
			setTotalPages(res.totalPages);
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};

	const handleSearch = () => {
		setIsSearching(true); 
		setPage(1); 
		if(isSearching){
			fetchCommandes(1); 
		}
	};
	const resetData = async () => {
		setIsSearching(false); 
		setSearchQueryId(''); 
		setSearchCriteria("id")
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setPage(1);
	};

	useEffect(() => {
		fetchCommandes(page)
	}, [page,isSearching])

	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>{'historique'.toUpperCase()}</GradientText>
				<SubText>
					Ici retrouver la totalité des transports effectués
				</SubText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
						onClick={handleSearch}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={resetData}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginRight: '1rem',
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
				</div>
				{chargementEnCours ?
                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginTop:"10rem",
					  marginBottom:"3rem"
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div>
					:
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '3rem',
					}}
				>
					{ commandes.length === 0 ? 
						<span
						style={{
							fontFamily: 'Montserrat',
							color: '#262626',
							marginTop: '0.5rem',
							textAlign: 'center',
							paddingLeft:"400px"
						}}

					>
						Il n'y a pas des commandes à afficher
					</span>
					:commandes.map((commande) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={commande._id}
									commande={commande}
									hideAttribuer
									hidecancel
									hideRecu
									hidefacture
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/transports/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/transporter/transports/${commande._id}/signaler`
										)
									}}
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={commande._id}
									commande={commande}
									hideAttribuer
									hidecancel
									hidefacture
									hideStatus
									hideRecu
									hideUpdate
									onClickDetails={() => {
										setSelectedCommande(commande._id)
										setModalIsOpenDetail(true)	
									}}
									onClickSignaler={() => {
										history.push(
										`/transports/${commande._id}/single`
										)
									}}
								/>
							)
						}
					})}
				</div>
                    }

				<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={totalPages}
							onChange={(newPage) => setPage(newPage)}
						/>
				</div>
				<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
			</div>
		</LayoutTransporter>
	)
}

export default HistoriqueTransporter
