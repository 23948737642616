import React,{useEffect,useState} from 'react'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { GradientText ,FormSectionCard,Wrapper,	SubmitButtonContainer,SubmitButton} from '../../PasserCommande/PasserCommande.styles'
import { useForm } from 'react-hook-form'
import { CustomInput } from '../../RegisterTransporter/style';
import { getSingleClient,updateClientAccount } from '../../../api/clients'
import { useSelector,useDispatch} from 'react-redux'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'
import { updateProfile } from '../../../redux/auth'
function CompteAdministrateur() {
  const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()
  
  const [administrateur, setAdministrateur] = useState()
  const [chargementEnCours,setChargementEnCours]=useState(false)
  const [loading, setLoading] = useState(false)
  const { _id } = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token);
  const history=useHistory()
	const dispatch = useDispatch()
  useEffect(()=>{
  async function fetchAdministrateur(_id){
    setChargementEnCours(true) 
    const data=await getSingleClient(_id,token)
    delete data.createdAt
    delete data.epi
    delete data.manutention
    delete data.premium
    delete data.reduction
    delete data.status
    delete data.updatedAt
    delete data.vehicules
    delete data.verification
    delete data.verified
    setAdministrateur(data)
    reset(data)
	setChargementEnCours(false) 
  } 
    if(_id){
      fetchAdministrateur(_id)
    }
 
  },[_id])
  const onSubmit = async (data) => {
	setLoading(true)
    const update = {
			...administrateur,
			last_name:data.last_name,
			first_name:data.first_name,
			address:data.address,
            city:data.city,
            postalCode:data.postalCode,
			phone:data.phone,
			password: data.password,
		}
    if (data.password.length === 0) {
			delete data.password
			delete update.password
		}
    try {
			const res = await updateClientAccount(update,token)

			toast.success('Votre compte a été mis à jour', {
				style: {
					fontFamily: 'Montserrat',
				},
			})

			dispatch(updateProfile(res))


			window.location.reload()
		} catch (error) {
			console.log(error)
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setLoading(false)
		}
	}
  return (
	<LayoutAdmin>
      
      <Wrapper>
				<GradientText>MON COMPTE</GradientText>
	  </Wrapper>
	  {chargementEnCours ?
	  <div
	  style={{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
		marginTop:'3rem',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,

	  }}
	  >
	  <div className='customLoader'></div>	
	  </div>
	  :
      <div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
        <div
					style={{
						width: '60%',
						fontFamily: 'Montserrat',
						alignSelf: 'center',
					}}
				>
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormSectionCard>
        <div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
										type='text'
                                        disabled
										{...register('last_name', {
											required: true,
										})}
									/>
									
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
                                        disabled
										{...register('first_name', {
											required: true,
										})}
									/>
									 
								</div>
	      </div>
                            <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
                                        disabled
										{...register('email', {
											required: true,
										
										})}
									/>
								
	       
                            </div>
                            <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Rôle
									</span>
									<CustomInput
                                     disabled
							         type='text'
										{...register('role')}
						            />
									
	       
                            </div>
                            <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
									type='text'
									placeholder=' 75017, Paris, France'
									{...register('address', {
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^[A-Za-z0-9\s',-]+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
									})}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
				           </div>
                <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville
								</span>
								<CustomInput
									type='text'
									placeholder='54 rue avenue de la grande armée'
									{...register('city', {
										required: 'Veuillez indiquer la ville.',
										minLength: {
										   value: 3,
										   message: 'La ville doit contenir au moins 3 caractères.',
										 },
										 pattern: {
										   value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
										   message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										 },
									})}
								/>
								{errors.city && (
                  <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                  {errors.city.message}
                  </div>
                )}
				</div>
                 <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									placeholder='75017'
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
							{errors.postalCode && (
                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                {errors.postalCode.message}
                </div>
              )}
				</div>
                <div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
										type='text'
										placeholder='(0) 01 45 66 32 88'
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                                    )}
								</div>
				</div>
				<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Changer mon mot de passe
									</span>
									<CustomInput
										type='password'
										{...register('password', {
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
								</div>
       
        </FormSectionCard>
        <div style={{ height: '1rem' }} />
				<SubmitButtonContainer>
					<SubmitButton type='submit' disabled={loading}>
								{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}	
					</SubmitButton>
				</SubmitButtonContainer>
        </form>
        </div>
      </div>
      } 
     
  </LayoutAdmin>
  )
}

export default CompteAdministrateur