import api from './base'


export const signup = async (data, files) => {
  const formData = new FormData();

  // Ajouter les données au FormData
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'object' && data[key] !== null) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] instanceof Date) {
      formData.append(key, data[key].toISOString());
    } else {
      formData.append(key, data[key]);
    }
  });

  // Ajouter les fichiers au FormData
  if (files && files.length > 0) {
    files.forEach(fileObj => {
      const [fileField, file] = Object.entries(fileObj)[0];
      formData.append(fileField, file);
    });
  }

  try {
    const response = await api.post('/register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement:', error);
    throw error;
  }
};

export const CreateUser = async (data) => {
	const res = await api.post('/register', data)
	return res.data

  };
  
export const login = async (email, password) => {
	const params = new URLSearchParams()
	params.append('email', email)
	params.append('password', password)

	const res = await api.post('/login', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})

	return res.data
}
export const forgotPassword = async (email) => {
	const params = new URLSearchParams()
	params.append('email', email)
	params.append('mode', 'web')
	const res = await api.post('/forgot', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})

	return res.data
}
export const resetPassword = async (id,password) => {
	const params = new URLSearchParams()
	params.append('id', id)
	params.append('password', password)
	
	const res = await api.post('/reset', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})

	return res.data
}

export const getUser = async (_id) => {
	const res = await api.get(`/users/${_id}`)
	return res.data
}
export const sendContactData=async(data)=>{
	const res = await api.post(`/contact`,data)
	return res.data
}

export const  refreshUserToken=async(refreshToken)=>{
	try {
	  const res = await api.post('/refresh-token',{refreshToken})
	  return res.data.token
	} catch (error) {
	  console.error('Erreur lors du rafraîchissement du token:', error)
	  return null
	}
  }

  


  export const logout = async (data,token) => {
	const res = await api.post('/logout', data
	,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}