import React, { useState,useEffect } from 'react'
import Modal from 'react-modal';
import { getChauffeur } from '../../api/chauffeurs';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import moment from 'moment';


// Style pour le modal
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '80vh',
        overflow: 'hidden',
        width: "70%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

// Styles pour les éléments du modal
const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#5BBCEA',
    padding: '20px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const closeButtonStyle = {
    position: 'absolute',
    right: '15px',
    backgroundColor: 'transparent',
    padding: '0',
    color: '#FF5A5F',
    border: 'none',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
};

const contentStyle = {
    padding: '20px',
    background: '#F7F8F8',
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 60px)',
    fontFamily: 'Montserrat',

};

const detailsStyle = {
    padding: '15px',
    marginBottom: '10px',
  };
  
  const detailRowStyle = {
    marginBottom: '8px',
  };
  
  const detailTitleStyle = {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333333',
  };
  
  const detailSpanStyle = {
    fontSize: '14px',
    fontWeight: '400',
    color: '#606060',
  };
// Composant pour afficher une ligne de détails
const DetailRow = ({ title, value }) => (
    <div style={detailRowStyle}>
      <p style={detailTitleStyle}>
        {title} : <span style={detailSpanStyle}>{value || 'N/A'}</span>
      </p>
    </div>
  );
  
const DetailsChauffeurModal=({ isOpen, onClose, chauffeurId })=> {
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const token = useSelector((state) => state.auth.token);
    const [chauffeur,setChauffeur]=useState(null)

    const fetchChauffeur=async(chauffeurId)=>{
        setChargementEnCours(true)
        try {
           const res=await  getChauffeur(chauffeurId,token)
           setChauffeur(res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });  
        }finally{
         setChargementEnCours(false)
        }
    }
   
    useEffect(()=>{
        if(chauffeurId && isOpen){
            fetchChauffeur(chauffeurId) 
        }
    },[chauffeurId,isOpen])
    
  return (
   <Modal
   isOpen={isOpen} 
   onRequestClose={onClose} 
   ariaHideApp={false}
   style={modalStyles}>
  <div style={headerStyle}>
    <h3 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>{`Détails Chauffeur`}</h3>
    <button onClick={onClose} style={closeButtonStyle} aria-label="Fermer le modal">
          X
    </button>
  </div>
  {chargementEnCours ?
  <div
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginTop:"2rem",
    marginBottom:"2rem"
  }}
  >
  <div className='customLoader'></div>	
  </div>
   :
  <div style={contentStyle}>
      
      <div style={detailsStyle}>
            <DetailRow title="Sexe" value={chauffeur?.sexe} />
            <DetailRow title="Nom & Prénom" value={`${chauffeur?.last_name} ${chauffeur?.first_name}`} />
            <DetailRow
              title="Adresse"
              value={`${chauffeur?.address || ''}, ${chauffeur?.city || ''}, ${chauffeur?.postalCode || ''}`}
            />
            <DetailRow title="Téléphone" value={chauffeur?.phone} />
            <DetailRow title="Adresse email" value={chauffeur?.email} />
            <DetailRow
              title="Date de naissance"
              value={moment(chauffeur?.date_naiss).format('DD/MM/YYYY')}
            />
            <DetailRow title="Permis" value={chauffeur?.permis?.join(', ')} />
            <DetailRow
              title="Date d’embauche"
              value={moment(chauffeur?.date_embauche).format('DD/MM/YYYY')}
            />
            <DetailRow title="Fimo" value={chauffeur?.fimo  === true ? 'Oui' : 'Non'} />
            <DetailRow
              title="Matière dangereuse"
              value={chauffeur?.danger === true ? 'Oui' : 'Non'}
            />
          </div>
     
    
  </div>
  }
   </Modal>
  )
}

export default DetailsChauffeurModal