import React from 'react';
import Numbro from 'numbro';
import styled from 'styled-components';

const PriceContainer = styled.div`
  position: fixed;
  top: 250px;
  right: 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
`;

const PriceBox = styled.div`
  padding: 1rem;
  background: #838EAB;
  color: white;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 20px;
`;

const PriceLabel = styled.span`
  margin-bottom: .5rem;
`;

const PriceValue = styled.span`
  font-weight: bold;
`;

export const PriceDisplay = ({ distance, price, reduction, prixAvecReduction }) => {
  const formatPrice = (value) => {
    return Numbro(value ?? 0).formatCurrency({
      currencySymbol: '€',
      currencyPosition: 'postfix',
      mantissa: 2,
    });
  };

  return (
    <PriceContainer>
      <PriceBox>
        <PriceLabel>Distance</PriceLabel>
        <PriceValue style={{ marginBottom: '1rem' }}>{distance / 1000} km</PriceValue>
        <PriceLabel>Prix</PriceLabel>
        <PriceValue>{formatPrice(price)} ht</PriceValue>
        {!!reduction && (
          <>
            <PriceLabel style={{ marginTop: '1rem' }}>Prix avec remise</PriceLabel>
            <PriceValue>{formatPrice(prixAvecReduction)} ht</PriceValue>
          </>
        )}
      </PriceBox>
    </PriceContainer>
  );
};