import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import LayoutChauffeur from '../LayoutChauffeur/LayoutChauffeur'
import { useParams } from 'react-router'
import {getMission} from '../../api/commande'
import AutocompleteAddress from '../GooglePlacesAutoComplete/AutocompleteAddress'
import { getDistanceBetweenTwoPlaces ,getHourBetweenTwoPlaces} from '../../api/distance'
import { updateCommandeWayPoints,getPointsPassageForMission,sendSMSMission} from '../../api/commande'
import 'react-phone-input-2/lib/style.css'
import toast from 'react-hot-toast'
import RecipientInfoForm from '../RecipientInfoForm/RecipientInfoForm'
import { GradientText,StyledInput } from '../../Containers/PasserCommande/PasserCommande.styles'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Modal from 'react-modal'
function TransportTracking() {
    const {id}=useParams()
	const history=useHistory()
	const token = useSelector((state) => state.auth.token);
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [loading,setLoading]=useState(false)
    const [mission,setMission]=useState(null)
	const [place,setPlace]=useState(null)
	const [places, setPlaces] = useState([])
	const [totalDistance,setTotalDistance]=useState(0)
    const [modalIsOpenSendSMS, setModalIsOpenSendSMS] = useState(false)
	const [modalIsOpenSendMail, setModalIsOpenSendMail] = useState(false)
    const [loadingSendSMS,setLoadingSendSMS]=useState(false)
    const [phone,setPhone]=useState("")
	const  [heure,setHeure]=useState('')
	const [totalHeuresPlaces,setTotalHeuresPlaces]=useState('')
	const [totalForfait,setTotalForfait]=useState(0)
	const [depassementHeures,setDepassementHeures]=useState(0)
	const [depassementKilometrage,setDepassementKilometrage]=useState(0)
    const [chargement,setChargement]=useState(false)
	const fetchMissionWithLoading = async () => {
		setChargementEnCours(true);
		try {
		  const mission = await getMission(id, token); 
		  const res=await getPointsPassageForMission(id,token)
		  console.log('res',res)
		setTotalForfait(res.totalKilometrage)
		setHeure(res.totalHeures)
		setMission(mission);
		setPlaces(res.pointsPassages)
		setTotalHeuresPlaces(res.sommeHeuresPointsPassage)
		setTotalDistance(res.sommeDistances)
		setDepassementHeures(res.depassementHeuresDuree)
	    setDepassementKilometrage(res.depassementKilometrage )
		} catch (err) {
			console.log('error',err)
		} finally {
		  setChargementEnCours(false);
		}
	}

	const fetchMission = async () => {
		try {
		  const mission = await getMission(id, token); 
		  const res=await getPointsPassageForMission(id,token)
           
		  setTotalForfait(res.totalKilometrage)
		  setHeure(res.totalHeures)
		  setMission(mission);
		  setPlaces(res.pointsPassages)
		  setTotalHeuresPlaces(res.sommeHeuresPointsPassage)
		  setTotalDistance(res.sommeDistances)
		  setDepassementHeures(res.depassementHeuresDuree)
		  setDepassementKilometrage(res.depassementKilometrage )

		
	
	
		
		} catch (err) {
			console.log('error',err)
		} 
	}
	
	
	
	
	
	
	
	
	
	
	
	
	useEffect(()=>{
		fetchMissionWithLoading()
	},[id])

	useEffect(()=>{
		fetchMission()
	},[chargement])
	
   
	


	return (
		<LayoutChauffeur>
		<div
		style={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			paddingTop: '2rem',
			paddingBottom: '4rem',
		}}>
		<div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => {
              history.goBack()
            }}
            style={{
              background: '#51C7F2',
              width: '3rem',
              height: '3rem',
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width={14.017} height={14.11} viewBox='0 0 14.017 14.11'>
              <g data-name='Group 11287' fill='none' stroke='#fff' strokeWidth={2}>
                <path data-name='Path 11687' d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232' transform='translate(-8.838 -11.113)' />
                <path data-name='Path 11688' d='M22.147 11.818l-6.348 6.349 6.348 6.348' transform='translate(-8.838 -11.113)' />
              </g>
            </svg>
          </div>

          <div
            style={{
              width: '1rem',
            }}
          />

          <GradientText marginTop={'unset'}>
            {`Points  de  passage`.toUpperCase()}
          </GradientText>
        </div>
		{chargementEnCours ?
		<div
		style={{
		  display: 'flex',
		  alignItems: 'center',
		  justifyContent: 'center',
		  backgroundColor: 'white',
		  marginTop:"3rem",
		  top: 0,
		  left: 0,
		  right: 0,
		  bottom: 0,

		}}
		>
		<div className='customLoader'></div>	
		</div>
		:
		<div 
		style={{
			
			width: '100%',
			fontFamily: 'Montserrat',
			paddingLeft: '3rem',
			paddingRight: '3rem',
			marginBottom: '2rem',
			marginTop: '1rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			
		}} >
		
        <p style={{ width: '100%', fontSize: '16px' }}>
        <b>
		Forfait {totalForfait} km pour {' '} 
		{(mission?.forfaits ?? []).some(forfait => forfait.type === 'kilometrage_etage' || forfait.type === 'kilometrage') ? '12h' : heure} 
        </b>
        </p>
		{!mission?.signé &&
		<div
	     style={{
		background: '#f8f9fa',
		borderRadius: '10px',
		padding: '2rem',
		marginBottom: '5rem',
		boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
		width: '100%',
		display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'

	}}
>

     
	<div
		style={{
			display: 'flex',
			flexDirection: 'column',
			marginBottom: '2rem',
			width: '70%',

		}}
	>
		<span
			style={{
				marginBottom: '1rem',
				color: '#495057',
				fontSize: '14px',
				fontWeight: 'bold',
			}}
		>
			Ajouter Adresse
		</span>
		<AutocompleteAddress
			onPlaceSelect={(placeDetails) => {
				console.log('Place ID:', placeDetails.place_id);
				console.log('Description:', placeDetails.description);
			}}
			onValueChange={async (selectedValue) => {
				if (selectedValue) {
					setPlace(selectedValue);
				}
			}}
			value={place}
			style={{
				marginBottom: '1rem',
			}}
		/>
		<div style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
		}}>
		<button
			disabled={loading}
			onClick={async () => {
				if (place) {
					setLoading(true);
					const origin = places[places.length - 1]?.adresse?.value?.place_id;
					const destination = place.value.place_id;
					let distance=0
					let heure=0
					if (origin) {
						try {
							 distance = await getDistanceBetweenTwoPlaces(origin, destination, token);
							 heure=await getHourBetweenTwoPlaces(origin,destination,token)
							} catch (error) {
							console.log(error);
						}
					}
					try {
						await updateCommandeWayPoints(
							id,
							{
								adresse: place,
								distance: distance / 1000,
								heure:heure?.toFixed(2)
							},
							token
						);
						fetchMission();
					} catch (error) {
						toast.error(error.response.data.message, {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}
					setLoading(false);
					setPlace(null)
				}
			}}
			style={{
				marginTop: '1rem',
									background: '#50F5A9',
									padding: '1rem 2rem',
									color: 'white',
									borderRadius: 5,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									cursor: 'pointer',
									fontWeight: 'bold',
									border: 'none',
									width:"200px",
							        height:"50px",
			}}
		>
			{loading ? <div className='loader'></div> : 'Ajouter'}
		</button>
		</div>
	</div>
	
	<div
        style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '1rem',
            background: '#ffffff',
        }}
    >
        <p style={{ margin: 0, padding: 0 }}>
            <b>
                <span>Total Km : </span>
                <span style={{ color: 'crimson' }}>
                    {totalDistance?.toFixed(2)} Km
                </span>
            </b>
            <br />
            <b>
                <span>Temps total : </span>
                <span style={{ color: 'crimson' }}>
                    {totalHeuresPlaces}
                </span>
            </b>
            <br />
            <b>
                Dépassement Km :{' '}
                <span style={{ color: 'crimson' }}>
                    {depassementKilometrage}
                    Km
                </span>
            </b>
			<br/>
			<b>
               Temps Dépassement  :{' '}
                <span style={{ color: 'crimson' }}>
                    {depassementHeures}
                    
                </span>
            </b>
            <br />
        </p>
    </div>

	

	
       </div>
        }
		{places.map((place, index) => (
            <RecipientInfoForm key={index} place={place} index={index} setChargement={setChargement} commandeID={mission?.commande?._id} />
        ))}
		   
		<div
			style={{
				width:"100%",
				background: mission?.signé ?'#E6F7FF' :'#E4EAF0',
				display: 'flex',
				flexDirection: 'column',
				padding: '1rem',
				borderRadius: 10,
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginTop: '4rem',
				marginBottom: 10,
			}}
		>

                <div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',
						width:'100%'

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width:'50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                               Nom du signataire	
                        </span>
                        
                          
                                <StyledInput
                                   
									disabled={true}
                                    type="text"
									value={mission?.commande?.nom_signataire
									}
                                />
                            
                        
                    </div>
					<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width:'50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                       	Prénom du signataire
                        </span>
                        
                          
                                <StyledInput
                                   
									disabled={true}
                                    type="text"
									value={mission?.commande?.prénom_signataire
									}
                                />
                            
                        
                    </div>
                    
                </div>
				<div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',
						width:'100%'

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width:'50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                              
				    Numéro de Téléphone 1 du signataire	 	
                        </span>
                        
								 <PhoneInput 
								country='fr'
								placeholder=''
								value={mission?.commande?.téléphone1_signataire}
								disabled={true}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'
								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>
                            
                        
                    </div>
					<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width:'50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                              
				    Numéro de Téléphone 2 du signataire	 	
                        </span>
                        
								 <PhoneInput 
								country='fr'
								placeholder=''
								value={mission?.commande?.téléphone2_signataire}
								disabled={true}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'
								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>
                            
                        
                    </div>
                    
                </div>
				{!mission?.signé &&
				<div
                style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
						width:'100%'

                 }}>
              
                <button  
					onClick={async(e)=>{
						e.preventDefault()
					    setPhone(mission?.commande?.téléphone1_signataire)
						setModalIsOpenSendSMS(true)
						
					}}
					style={{
						padding: '1rem 2rem',
                        mariginLeft:"1rem",
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						marginTop:'1rem',
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
                        height:"50px",
					}}>
						Envoyer le code par sms
                </button>
				<button  
					onClick={async(e)=>{
						e.preventDefault()
					    setPhone(mission?.commande?.téléphone2_signataire)
						setModalIsOpenSendSMS(true)
						
					}}
					style={{
						padding: '1rem 2rem',
                        mariginLeft:"1rem",
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						marginTop:'1rem',
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
                        height:"50px",
					}}>
						Envoyer le code par sms
                </button>
                </div>
                }
				<div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',
						width:'100%'

                    }}>
				<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width:'50%',
                            marginRight: '1rem',
                            marginBottom:'0.5rem'
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                              
				    Email du signataire	 	
                        </span>
						<StyledInput
                                   
								   disabled={true}
								   type="text"
								   value={mission?.commande?.email_signataire
								   }
							   />
					
                            
                        
                </div>
				{!mission?.signé &&
				<button  
					onClick={()=>{
						setModalIsOpenSendMail(true)
						
					}}
					style={{
						padding: '1rem 2rem',
                        mariginLeft:"1rem",
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						marginTop:'1.5rem',
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
                        height:"50px",
					}}>
						Envoyer le code par email
                </button>
                }
				</div>

				
				
				 <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
					}}
				>
					<span
						style={{
							marginBottom: '.5rem',
							color: 'black',
							fontSize: 13,
                            fontFamily: 'Montserrat',

						}}
					>
						Signé
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
					}}>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							disabled={true}
							 style={{
								background:mission?.signé?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: mission?.signé && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:mission?.signé ?'#fff': '#444'
							}}
							>Oui</button>
							
						
						</div>
						<div
							
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							 disabled={true}
							 style={{
								background:!mission?.signé ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: !mission?.signé && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:!mission?.signé?'#fff': '#444'
							}}
							>Non</button>
						</div>
					</div>					
					
				</div>
		</div>
		</div>
        }
     
		</div>
		<Modal
				isOpen={modalIsOpenSendSMS}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Envoyer Code</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir envoyer le  code  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoadingSendSMS(true)
							try {
								const res=await sendSMSMission({ "numero": phone,"missionId":mission?._id,"method":'sms',email:null},token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally {
								setModalIsOpenSendSMS(false)
								setLoadingSendSMS(false)
							}
							  
                              
                            }}

						disabled={loadingSendSMS}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
				         	justifyContent: 'center',
					        alignItems: 'center'
						}}

					>
					 {loadingSendSMS ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenSendSMS(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		   <Modal
				isOpen={modalIsOpenSendMail}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Envoyer Code</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir envoyer le  code  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoadingSendSMS(true)
							try {
								const res=await sendSMSMission({ "numero": null,"missionId":mission?._id,"method":'email',email:mission?.commande?.email_signataire},token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally {
								setModalIsOpenSendMail(false)
								setLoadingSendSMS(false)
							}
							  
                              
                            }}

						disabled={loadingSendSMS}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
				         	justifyContent: 'center',
					        alignItems: 'center'
						}}

					>
					 {loadingSendSMS ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenSendMail(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		</LayoutChauffeur>

	)
	
}

export default TransportTracking
