import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, roles = [], ...rest }) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.auth.user);
    const location = useLocation();

    // Vérification des rôles
    const hasPermission = roles.length === 0 || (user && roles.includes(user.role));

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    hasPermission ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/unauthorized" />
                    )
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: location } }} />
                )
            }
        />
    );
};

export default PrivateRoute;
