import { GradientText } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import {getHistoriqueTransporteurByCommandeID} from '../../api/commande'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import toast from 'react-hot-toast'
function HistoriqueTransporteurCommande() {
  const history=useHistory()
  const {id}=useParams()
  const token = useSelector((state) => state.auth.token);
  const [chargementEnCours, setChargementEnCours] = useState(false);
  const [data,setData]=useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

   const fetchData = async (page,perPage) => {
        setChargementEnCours(true)
        try {
            const res=await getHistoriqueTransporteurByCommandeID(id,token,page,perPage)
            setData(res.historique)
            setTotalRows(res.totalPages)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        }finally{
            setChargementEnCours(false)

        }
    }

    const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchData(page,perPage)	
	}
	const handlePerRowsChange = async (newPerPage,page) => {
        fetchData(page,newPerPage)
		setPerPage(newPerPage)
	}
  const columns = [
		{
			name: "Id de commande ",
			selector: (row) => row.commandeId.__id,
			sortable: false,
		},
		{
            name: "Ancien Transporteur",
            sortable: false,
            cell: (row) => (
                <div 
                onClick={()=>{
                    if(row.ancienTransporteurId?.deletedAt ){
                        history.push(`/admin/archive/transporter/${row.ancienTransporteurId._id}`)

                    }else{
                        history.push(`/admin/transporter/${row.ancienTransporteurId._id}`)

                    }
                }}
                style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color:row?.ancienTransporteurId?.deletedAt !== null ? 'red' :'#51C7F2'
                    }}
                >
                  {row.ancienTransporteurId && row.ancienTransporteurId.societe}
                </div>
              ),
        },
        {
            name: "Nouveau Transporteur",
            sortable: false,
            cell: (row) => (
                <div 
                onClick={()=>{
                    if(row.nouveauTransporteurId?.deletedAt){
                        history.push(`/admin/archive/transporter/${row.nouveauTransporteurId._id}`)

                    }else{
                        history.push(`/admin/transporter/${row.nouveauTransporteurId._id}`)

                    }
                }}
                style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color:row?.nouveauTransporteurId?.deletedAt !== null ? 'red' :'#51C7F2'
                    }}
                >
                  {row.nouveauTransporteurId && row.nouveauTransporteurId.societe}
                </div>
              ),
        },
        {
			name: "Statut de commande ",
			selector: (row) => row.statutCommande==='init' ? "Non commencée" : row.statutCommande==='en-cours' ? 'En cours' : row.statutCommande==='terminer' ? "Terminée" :"Annulée",
			sortable: false,
		},
        {
			name: "Date de changement ",
            selector: (row) => moment(row.dateChangement).format('DD/MM/YYYY'),
			sortable: false,
		}


	]
      useEffect(() => {
		fetchData(currentPage,perPage)
	}, [])

  return (
    <LayoutAdmin>
       <div
        style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '2rem',
            paddingBottom: '4rem',
            fontFamily: 'Montserrat',
        }}
    >
       <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    onClick={() => {
                        history.goBack()
                    }}
                    style={{
                        background: '#51C7F2',
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={14.017}
                        height={14.11}
                        viewBox='0 0 14.017 14.11'

                    >
                        <g
                            data-name='Group 11287'
                            fill='none'
                            stroke='#fff'
                            strokeWidth={2}
                        >
                            <path
                                data-name='Path 11687'
                                d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
                                transform='translate(-8.838 -11.113)'
                            />
                            <path
                                data-name='Path 11688'
                                d='M22.147 11.818l-6.348 6.349 6.348 6.348'
                                transform='translate(-8.838 -11.113)'
                            />
                        </g>
                    </svg>
                </div>

                <div
                    style={{
                        width: '1rem',
                    }}
                />

                <GradientText marginTop={'unset'}>
                    {' Historique Transporteurs'.toUpperCase()}
                </GradientText>
            </div>
            <div
					style={{
						width: '100%',
						padding: '0 1rem',
                        marginTop:'5rem'
					}}
				>
					 <DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des transporteurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/> 
				</div>
    </div>
    </LayoutAdmin>
  )
}

export default HistoriqueTransporteurCommande