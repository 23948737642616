import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { useHistory,useParams } from 'react-router'
import {getComptabiliteCommercial,getComptabiliteCommercialByMonth,getComptabiliteCommercialByMonthAndName,getComptabiliteCommercialByName,getSingleCommercial} from '../../../api/commerciaux'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import toast from 'react-hot-toast'
Modal.setAppElement('#root')

function ComptabiliteCommercial() {
	const history = useHistory()
	
	const [selectedDate, setSelectedDate] = useState(null);
	const  {id}=useParams()
	const [data, setData] = useState([])
	const [commercial,setCommercial]=useState(null)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const token = useSelector((state) => state.auth.token);
	const [currentPage, setCurrentPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
	const [totalPrix, setTotalPrix] = useState(0)
	const [chargementEnCours, setChargementEnCours] = useState(false);
    const [isSearching,setIsSearching]=useState(false)
	const [loading,setLoading]=useState(false)
	const fetchClients= async (page) => {
		setChargementEnCours(true)
		try {
			let res
			if(isSearching){
			   if (searchQuery.length === 0) {
				res = await getComptabiliteCommercial(id,page, perPage,token)

			   }else{
				   if(selectedDate){
					   const [year, month] = selectedDate.split('-'); 
					   res=await  getComptabiliteCommercialByMonthAndName(id,currentPage, perPage,token,month,year,searchQuery)

					   }else{
					    res = await getComptabiliteCommercialByName(id,searchQuery,page,perPage,token)

					   }
			   }
	   
			   
			}else{
				 res = await getComptabiliteCommercial(id,page, perPage,token)

		   }
		
		setData(res.docs)
		setTotalRows(res.totalDocs)
		setTotalPrix(res.totalPrix ?? 0)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            }); 
		}finally{
			setChargementEnCours(false)

		}
		
	}
	const fetchCommercial = async () => {
        setLoading(true)
        try {
            const response = await getSingleCommercial(id, token)
            setCommercial(response)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: { fontFamily: 'Montserrat' }
            });
        } finally {
            setLoading(false)
        }
    }

	
	
	
	
	const handleDateChange = async (value, page) => {
		const [year, month] = value.split('-'); 
	
		try {
			setChargementEnCours(true);
	
			// Si une date est sélectionnée et qu'aucune recherche n'est effectuée
			if (year && month && searchQuery.length === 0) {
				const res = await getComptabiliteCommercialByMonth(id,page, perPage, token, month, year);
				setData(res.docs);
				setTotalRows(res.totalDocs);
				setTotalPrix(res.totalPrix ?? 0);
			} 
			// Si une date et une recherche par nom sont présentes
			else if (searchQuery.length !== 0 && year && month) {
				const res = await getComptabiliteCommercialByMonthAndName(id,page, perPage, token, month, year, searchQuery);
				setData(res.docs);
				setTotalRows(res.totalDocs);
				setTotalPrix(res.totalPrix ?? 0);
			} 
			// Si aucune date n'est sélectionnée
			else {
				await fetchClients(page);
			}
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};
	const handleSearch = () => {
		setIsSearching(true); 
		setCurrentPage(1); 
		if(isSearching){
			fetchClients(1); 

		}
	};
	const resetSearch=()=>{
		setSearchQuery('')
		setSelectedDate('')
		setIsSearching(false)
		fetchClients(1)
	}



	const handlePageChange = (page) => {
		setCurrentPage(page)
		if (selectedDate) {
			handleDateChange(selectedDate,page)
		} else {
			fetchClients(page);
		}
	}
	const handlePerRowsChange = async (newPerPage, page) => {
		
		setChargementEnCours(true)
		try {
		const res = await getComptabiliteCommercial(id,page, newPerPage,token)
		setData(res.docs)
		setPerPage(newPerPage)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});  	
		}finally{
			setChargementEnCours(false)

		}
	}

	

	useEffect(() => {
		fetchClients(1)
		fetchCommercial()
	}, [])
	useEffect(()=>{
		fetchClients(currentPage)
	},[isSearching])
	
   
	const columns = [
		{
			name: 'ID du commercial',
			selector: () => commercial?.__id,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Nom  du  commercial',
			selector: () =>commercial && `${commercial?.first_name} ${commercial?.last_name}`,
			sortable: false,
			width:'200px'
		},
		{
			name: 'ID du client',
			selector: (row) => row.__id,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:'200px'
		},
		{
			name: 'Nom du client',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:"200px"
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:'250px'
		},
		{
			name: 'Montant payé',
			selector: (row) => `${(row.caPaiement)?.toFixed(2)}€`,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Montant remboursé',
			selector: (row) => `${(row.caRembourse)?.toFixed(2)}€`,
			sortable: false,
			width:'200px'
		},
		{
			name: 'Montant du remboursement en cours',
			selector: (row) => `${(row.caRemboursementEnCours)?.toFixed(2)}€`,
			sortable: false,
			width:'300px'
		},
		{
			name: 'Montant du remboursement échoué',
			selector: (row) => `${(row.caRemboursementEchoue)?.toFixed(2)}€`,
			sortable: false,
			width:'300px'
		},
		{
			name: 'Type de compte',
			selector: (row) => (row.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width:'150px'
		},
		{
			name: '% de réduction',
			selector: (row) => (row.reduction ? `${row.reduction}%` : '0%'),
			sortable: false,
			width:'150px'
		},
		{
			name: 'Statut',
			selector: (row) => (row.status==="suspendu" ? 'Suspendu' : 'Activé'),
			sortable: false,
		},
		{
			name: "Date de suspension",
			selector: (row) => (row.status==="suspendu"  ? moment(row?.date_suspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
		},
		
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:'150px'
		},
		
		
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {

							history.push(`/admin/comptabilite/client/${row._id}`)
							
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>

					
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
						{'Comptabilité commercial'.toUpperCase()}
						</GradientText>

					</div>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '4rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<input
						disabled={chargementEnCours}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
							style={{
								padding: '1rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							placeholder="Rechercher par l'identifiant, nom"
						/>
					    <input 
                        type="month"
	                    value={selectedDate || ''}
						onChange={(e)=>{
							setCurrentPage(1)
							setSelectedDate(e.target.value);
							handleDateChange(e.target.value,1)
						}}
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '15rem',
							fontFamily: 'Montserrat',
							marginLeft:'1rem'
						}}
						placeholder="Sélectionnez un mois et une année"
                        />
					<button
					        onClick={handleSearch}
							style={{
								padding: '1rem 2rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								//marginTop: 20,
								marginLeft: 20,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Rechercher
						</button>
						<button
						onClick={resetSearch}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>


						
					</div>
					
					<div
						style={{
							background: '#838EAB',
							padding: '1rem 2rem',
							display: 'flex',
							alignItems: 'center',
							color: 'white',
							borderRadius: 10,
						}}
					>
						<span>Total Payé:</span>
						<span style={{ width: '2rem' }} />
						<span style={{ fontWeight: 'bold' }}>{totalPrix?.toFixed(2)}€</span>
					</div>
				</div>
				{chargementEnCours && loading ?
				 <div
				 style={{
				   display: 'flex',
				   alignItems: 'center',
				   justifyContent: 'center',
				   backgroundColor: 'white',
				   marginTop:"10rem"
				 }}
				 >
				 <div className='customLoader'></div>	
				 </div>
				 :
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des clients à afficher"
						progressComponent='Chargement'
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
                } 
			</div>
		</LayoutAdmin>
	)
}

export default ComptabiliteCommercial
