import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { CustomInput } from '../../Containers/RegisterTransporter/style';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { updateSenderCommande,getRecipientInformation } from '../../api/commande'
import  toast from 'react-hot-toast'
import { Controller } from 'react-hook-form';
const UpdateInfomationsSignataire = ({ modalIsOpenUpdate, setModalIsOpenUpdate, commandeId }) => {
    const { register, handleSubmit, setValue,watch, reset,control, formState: { errors } } = useForm();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const token = useSelector(state => state.auth.token);

    const fetchData=async(commandeId)=>{
        setChargementEnCours(true)
        try {
            
            const res=await getRecipientInformation(commandeId,token)
            console.log('res',res.data)
            reset(res.data) 
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });  
        }finally{
            setChargementEnCours(false)

        }
    }

    const onSubmit = async (data) => {
        setUpdateLoading(true);
        try {
            const {_id,...dataWithoutId}=data
            await updateSenderCommande(dataWithoutId,token,commandeId) 
            toast.success("Les informations ont été mises à jour avec succès", {
                style: {
                    fontFamily: 'Montserrat',
                },
            })
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        } finally {
            setUpdateLoading(false);
            setModalIsOpenUpdate(false);
            reset()
        }
    };
    const onError = (errors) => {
        console.log('Validation errors:', errors);
      
    };
    
    useEffect(() => {
        if (commandeId && modalIsOpenUpdate) {
            fetchData(commandeId)
        }
    }, [commandeId,modalIsOpenUpdate]);

    return (
        <Modal
            isOpen={modalIsOpenUpdate}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.45)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 19,
                    background: '#fff',
                    padding: '2rem',
                    height:"5O0px"

                },
            }}
            contentLabel='Modifier les informations du signateur'
        >
            <div
                style={{
                    width: '100%',
                    fontFamily: 'Montserrat',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 15,
                    textAlign: 'left',
                    color: '#838EAB',
                }}
            >
                <div
						style={{
							color: '#51C7F2',
							fontSize: 25,
							marginBottom: '1rem',
                            fontWeight: 'bold',
						    fontFamily: 'Montserrat',
						
							
						}}
					>
						  Modifier les informations du signataire
					</div>
                    {chargementEnCours ?
                     <div
                       style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        marginTop:"2rem",
                        marginBottom:"2rem"
                      }}
                     >
                     <div className='customLoader'></div>	
                    </div>   
                    :
                <form onSubmit={handleSubmit(onSubmit,onError)}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Nom
                            </span>
                            <CustomInput
                                type="text"
                                {...register('nom_signataire', { required: 'Veuillez indiquer le noms' })}
                                name="nom_signataire"
                                style={{
                                    width: '100%',
                                    padding: '0.75rem',
                                    borderRadius: '8px',
                                    border: '1px solid #ddd',
                                    fontSize: '14px',
                                    outline: 'none',
                                }}
                               
                            />
                            {errors.nom_signataire && 
                            <span style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>{errors.nom_signataire.message}</span>
                            }
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Prénom
                            </span>
                            <CustomInput
                                type="text"
                                {...register('prénom_signataire', { required: 'Veuillez indiquer le prénom' })}
                                name="prénom_signataire"
                                style={{
                                    width: '100%',
                                    padding: '0.75rem',
                                    borderRadius: '8px',
                                    border: '1px solid #ddd',
                                    fontSize: '14px',
                                    outline: 'none',
                                }}
                            />
                            {errors.prénom_signataire &&
                             <span style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>{errors.prénom_signataire.message}</span>
                             }
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                            marginBottom: '1rem',
                            marginTop:"1rem"
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Numéro de Téléphone 1
                            </span>
                            <Controller
						       name='téléphone1_signataire'
						       rules={
								{required: 'Veuillez indiquer le numéro de téléphone .',
							     pattern: {
								 value: /^[0-9]+$/, 
								 message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
								},}
							   }
						      control={control}
	
						      render={({ field: { onChange, value } }) => (
						 	   <PhoneInput 
								country='fr'
								placeholder=''
								value={value}
								onChange={onChange}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%',
                                    border: '1px solid #ddd',

								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
                                    border: '1px solid #ddd',

								  }}
								
								/>
						)}
                        />
                            {errors.téléphone1_signataire && 
                            <span style={{ color: 'red' , marginTop: '0.5rem',fontSize:'14px'}}>{errors.téléphone1_signataire.message}</span>
                            }
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                                Numéro de Téléphone 2
                            </span>
                            <PhoneInput
                                {...register('téléphone2_signataire', 
                                { required: 'Veuillez indiquer le numéro de téléphone' })}
                                value={watch('téléphone2_signataire')}
                                onChange={(newPhoneValue) =>setValue("téléphone2_signataire",newPhoneValue)}
                                country='fr'
                                inputStyle={{
                                    paddingRight: '1.5rem',
                                    paddingTop: '1.5rem',
                                    paddingBottom: '1.5rem',
                                    borderRadius: '0.5rem',
                                    border: 'none',
                                    fontFamily: 'Montserrat',
                                    color: '#858ea8',
                                    flex: '1',
                                    display: 'flex',
                                    width: '100%',
                                    border: '1px solid #ddd',

                                }}
                                buttonStyle={{
                                    borderRadius: '0.5rem',
                                    border: 'none',
                                    fontFamily: 'Montserrat',
                                    color: '#858ea8',
                                    flex: '1',
                                    display: 'flex',
                                    border: '1px solid #ddd',

                                }}
                            />
                            {errors.téléphone2_signataire &&
                             <span style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>{errors.téléphone2_signataire.message}</span>
                             }
                        </div>

                    </div>
                    <div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
                                    style={{
                                    marginBottom: '.5rem',
                                    color: 'black',
                                    fontSize: 14,
                                    }}
                                    >
										Adresse email
									</span>
									<CustomInput
										type='text'
										{...register('email_signataire', {
											required: "Veuillez indiquer l'adresse e-mail du signataire.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
                                        style={{
                                            width: '100%',
                                            padding: '0.75rem',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            fontSize: '14px',
                                            outline: 'none',
                                        }}
									/>
									{errors.email_signataire && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email_signataire.message}
                                    </div>
                               )}
					</div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <button
                                disabled={updateLoading}
                                type='submit'
                                style={{
                                    background: '#5BBCEA',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                    fontWeight:'bold',
                                    height:'50px',
                                    width:"120px",
                                    display: 'flex',         
                                    alignItems: 'center',   
                                    justifyContent: 'center'                           
                                     }}
                            >
                                {updateLoading ? <div className='loader'></div> : 'Modifier'}
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    reset()
                                    setModalIsOpenUpdate(false)
                                }}
                                style={{
                                    background: 'red',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: 'white',
                                    marginLeft: '1rem',
                                    fontFamily: 'Montserrat',
                                    padding: '1rem 2rem',
                                    borderRadius: 6,
                                    fontWeight:'bold',
                                    height:'50px',
                                    width:"120px"


                                }}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </form>
                 }
            </div>
        </Modal>
    );
};

export default UpdateInfomationsSignataire;
