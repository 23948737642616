import React ,{useEffect, useState}from 'react';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { StyledInput } from '../../Containers/PasserCommande/PasserCommande.styles'
import Modal from 'react-modal'
import toast from 'react-hot-toast'
import  {sendSMSMiseADispo,verifyCodeSMSMiseADispo,updateRecipientInfo,softDeleteWaypoint} from '../../api/commande'
import { useSelector } from 'react-redux'
import Agrandir from '../../assets/img/agrandir@2x.png'
import Reduire from '../../assets/img/reduire@2x.png'
import { useHistory } from 'react-router';
import { CustomInput } from '../../Containers/RegisterTransporter/style';
import moment from "moment"


// Fonction pour formater l'heure de signature avec Moment.js
const formatHeureSignature = (dateSignature) => {
    if (!dateSignature) return '';
    return moment(dateSignature).format('HH:mm')
  };


const RecipientInfoForm = ({place,index ,setChargement,commandeID}) => {
    const {  handleSubmit, control,register,watch, reset,formState: { errors } } = useForm();
	const history=useHistory()
	const [modalIsOpenSendSMS, setModalIsOpenSendSMS] = useState(false)
	const [modalIsOpenSendEmail, setModalIsOpenSendEmail] = useState(false)
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [loadingSendSMS,setLoadingSendSMS]=useState(false)
    const [loadingverifySMS,setLoadingverifySMS]=useState(false)
	const [enteredCode, setEnteredCode] = useState('');
	const [updateLoading,setUpdateLoading]=useState(false)
	const [loading,setLoading]=useState(false)
    const token = useSelector((state) => state.auth.token);
	const [isSigned,setIsSigned]=useState(place?.signé)
	const [collapsedItems, setCollapsedItems] = useState(false)
    const [selectedPlace,setSelectedPlace]=useState(null)
    const onSubmit = async (data,placeId) => {
        setUpdateLoading(true);
        try {
			let updatedata = {
				nom_destinataire: data.nom_destinataire,
				prénom_destinataire: data.prénom_destinataire,
				adresse_destinataire: data.adresse_destinataire,
				téléphone_destinataire: data.téléphone_destinataire
			};
			
			if (data.email_destinataire) {
				updatedata = { ...updatedata, email_destinataire: data.email_destinataire };
			}
            await updateRecipientInfo(placeId,updatedata,token)
            toast.success('Les informations du destinataire ont été sauvegardées avec succès', {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                });
			setChargement(true)

        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        } finally {
            setUpdateLoading(false);
            
        }
    };
    useEffect(()=>{
     
    reset(place)
    },[place])

	function formatHeure(heure) {
		if (!heure) return ""; 
	
		const [hours, minutes] = heure.split(':').map(Number);
	
		if (hours === 0) {
			return `${minutes}min`; 
		} else {
			return `${hours}h${minutes > 0 ? minutes + "min" : ""}`;
		}
	}
    return (
        <div
            style={{
                width: '100%',
                background: isSigned ?'#E6F7FF' :(place?.active ?'#E4EAF0' :'#d3d3d3'), 
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                borderRadius: 10,
                marginBottom: 10,
				position:'relative',
            
            }}
        >
			{index!==0 &&
			<div
			>
			<div
			        onClick={()=>{
						setCollapsedItems(true)
					}}
					style={{
						position: 'absolute',
						top: 10,
						right: 10,
						cursor: 'pointer',
						display: collapsedItems
								? 'none'
								: 'flex',
					    alignItems: 'center',
					    justifyContent: 'center',
						opacity: !place?.active ? 0.5 : 1, 
                        pointerEvents: !place?.active ? 'none' : 'auto'
						 
					}}	
					>
						<span style={{color: '#838EAB'}}>Agrandir</span>

						<img
							src={Agrandir}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
			</div>
       
			<div
					onClick={()=>{
						setCollapsedItems(false)
					}}
						style={{
							position: 'absolute',
							bottom: 10,
							right: 10,
							cursor: 'pointer',
							display: !collapsedItems
								? 'none'
								: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							
						}}
					>
						<span style={{color: '#838EAB'}}>Réduire</span>
						<img
							src={Reduire}
							style={{ height: 30, marginLeft: '0.5rem' }}
							alt=''
						/>
			</div>
			{!isSigned &&
			<div
							
							onClick={()=>{
								setSelectedPlace(place?._id)
								setModalIsOpenDelete(true)
							}}
							style={{
								position: 'absolute',
								top:!collapsedItems && 10,
								bottom:collapsedItems && 10,
 								right: 130,
								cursor: 'pointer',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						
						>
							<div
								style={{
									height: 30,
									width: 30,
								}}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									color=' #838EAB'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
									/>
								</svg>
							</div>
			</div>
           }
			</div>
            }

            <div
                style={{
                    marginBottom: '1rem',

                }}>
                <span
                    style={{
                        marginRight: 13,
                        marginBottom: '1rem',
                        color: '#838EAB',
                        width: '15rem',
                    }}
                >
                    Adresse : {' '}
                    <span
                        style={{ color: "red" }}>
                        {place?.adresse?.label}
                    </span>
                </span>
                <span
                    style={{
                        marginRight: 13,
                        marginBottom: '1rem',
                        color: '#838EAB',
                        width: '15rem',
                    }}
                >
                    Distance : {' '}
                    <span
                        style={{ color: "red" }}>
                        {place?.distance}Km
                    </span>
                </span>
				<span
                    style={{
                        marginRight: 13,
                        marginBottom: '1rem',
                        color: '#838EAB',
                        width: '15rem',
                    }}
                >
                    Heure : {' '}
                    <span
                        style={{ color: "red" }}>
                        {formatHeure(place?.heure)}
                    </span>
                </span>
				{place?.signé && place?.date_signature && 
				<span
				style={{
					marginRight: 13,
					marginBottom: '1rem',
					color: '#838EAB',
					width: '15rem',
				}}
			>
				Heure de signature : {' '}
				<span
					style={{ color: "red" }}>
					{formatHeureSignature(place.date_signature)}
				</span>
			</span>}
            </div>
			{(index!==0 && collapsedItems) &&
			<>
            <form onSubmit={handleSubmit((data) => onSubmit(data, place._id))}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,
                                

                            }}
                        >
                            Nom du destinataire
                        </span>
                        <Controller
                            name='nom_destinataire'
                            control={control}
                            rules={{ required: 'Veuillez indiquer le nom.' }}
                            render={({ field }) => (
                                <StyledInput
                                    {...field}
									disabled={isSigned}
                                    type="text"
                                />
                            )}
                        /> 
						
                    </div>
					
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,

                            }}
                        >
                            Prénom du destinataire
                        </span>
                        <Controller
                            name='prénom_destinataire'
                            control={control}
                            rules={{ required: 'Veuillez indiquer le prénom.' }}
                            render={({ field }) => (
                                <StyledInput
                                    {...field}
									disabled={isSigned}
                                    type="text"
                                />
                            )}
                        />
                    </div>
                </div>
				<div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
						 <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
						{errors?.nom_destinataire && 
						<div style={{ color: 'red' }}>
						{errors?.nom_destinataire?.message}
					   </div> 
						}
					</div>
					<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
						{errors?.prénom_destinataire && 
						<div style={{ color: 'red' }}>
						{errors?.prénom_destinataire?.message}
					   </div> 
						}
					</div>
			    </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,

                            }}
                        >
                            Adresse du destinataire
                        </span>
                        <Controller
                            name='adresse_destinataire'
                            control={control}
                            rules={{ required: "Veuillez indiquer l'adresse." }}
                            render={({ field }) => (
                                <StyledInput
                                    {...field}
                                    type="text"
									disabled={isSigned}
                                />
                            )}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '.5rem',
                                color: 'black',
                                fontSize: 13,

                            }}
                        >
                            Numéro de Téléphone du destinataire
                        </span>
                        <Controller
                            name='téléphone_destinataire'
                            control={control}
                            rules={{ required: 'Veuillez indiquer le numéro de téléphone.' }}
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    country={'fr'}
									disabled={isSigned}
                                    inputStyle={{
                                        paddingRight: '1.5rem',
                                        paddingTop: '1.5rem',
                                        paddingBottom: '1.5rem',
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        fontFamily: 'Montserrat',
                                        color: '#858ea8',
                                        flex: '1',
                                        display: 'flex',
                                        width: '100%'
                                    }}
                                    buttonStyle={{
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        fontFamily: 'Montserrat',
                                        color: '#858ea8',
                                        flex: '1',
                                        display: 'flex'
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
				<div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
						 <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
						{errors?.adresse_destinataire && 
						<div style={{ color: 'red' }}>
						{errors?.adresse_destinataire?.message}
					   </div> 
						}
					</div>
					<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
						{errors?.téléphone_destinataire && 
						<div style={{ color: 'red' }}>
						{errors?.téléphone_destinataire?.message}
					   </div> 
						}
					</div>
			    </div>
				
                <div
                style={{
                        display: "flex",
                        flexDirection: 'row',
                 }}>
				<div
				style={{
					width: '50%',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: '2rem',
				}}> 

				<span
										style={{
											color: 'black',
											fontSize: 13,
											paddingBottom: '.5rem',
										}}
									>
										Adresse email  du destinataire 
									</span>
									<CustomInput
										type='text'
										{...register('email_destinataire',{
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
				</div>
				{!isSigned && 
                <button
                    disabled={updateLoading}
                    type="submit"
                    style={{
                        padding: '1rem 2rem',
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						marginTop:'1.5rem',
						marginLeft:'1rem',
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
                        height:"50px",

                    }}
                >
                    
                    {updateLoading ? (
									<div className='loader'></div>
								) : (
									'Sauvegarder'
								)}
                </button>
                }      
                </div>
			
			
               
               
            </form>
			{!isSigned && 
				<>
				<div 
				 style={{
					display: "flex",
					flexDirection: 'row',
					marginBottom: '1rem',
					width:'100%'
			     }}>
				<button  
					onClick={async(e)=>{
                        e.preventDefault()
						if (!watch('téléphone_destinataire')) {
							toast.error("Veuillez fournir un numéro de téléphone.", {
								style: {
									fontFamily: 'Montserrat',
								},
							});
							return;
						}
                        setModalIsOpenSendSMS(true)    
                    }}
					style={{
						padding: '1rem 2rem',
                        mariginLeft:"1rem",
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
                        height:"50px",
					}}>
						Envoyer le code par sms
                </button> 
				<button  
					onClick={async(e)=>{
                        e.preventDefault()
						if (!watch('email_destinataire')) {
							toast.error("Veuillez fournir un email.", {
								style: {
									fontFamily: 'Montserrat',
								},
							});
							return;
						}
                        setModalIsOpenSendEmail(true)    
                    }}
					style={{
						padding: '1rem 2rem',
                        mariginLeft:"1rem",
						background: '#60d2f8',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						marginRight: '1rem',
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
                        height:"50px",
					}}>
						Envoyer le code par email
                </button> 
				</div>

				
                <div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '1rem',
				 }}>
                 <div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 13,
                        fontFamily: 'Montserrat',

					    }}
						>
				       Introduit le code 					 
				</span>
				<StyledInput
							type='text'
							placeholder=''
							value={enteredCode}
                           onChange={(e) => setEnteredCode(e.target.value)}

							
				/>
				
				</div>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
					 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				     					 
				</span>
					<button 
					disabled={loadingverifySMS}
				    onClick={async(e)=>{
						e.preventDefault()
						if (enteredCode === '') {
							toast.error("Veuillez introduire le code", {
								style: {
									fontFamily: 'Montserrat',
								},
							});
							return;
						}	
					setLoadingverifySMS(true)
					try {
						const response=await verifyCodeSMSMiseADispo({"placeId":place._id,"code":enteredCode},token)
						setEnteredCode('')
						toast.success(response.message, {
								style: {
									fontFamily: 'Montserrat',
								},
						})
						setChargement(true)
						if(response.success){
							setIsSigned(true)
						}
						
							
						} catch (error) {
							toast.error("Une erreur s'est produite", {
								style: {
									fontFamily: 'Montserrat',
								},
							})						
						}finally{
							setLoadingverifySMS(false)
						}
				}}
				style={{
					padding: '1rem 2rem',
					background: '#60d2f8',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					marginRight: '1rem',
					marginTop:'1rem',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height:"50px",

				}}>
              {loadingverifySMS ? (
									<div className='loader'></div>
								) : (
									'  Valider le code'
								)}
                </button>
					</div>	
				</div>
				</>
                }
			

                 <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '60%',
					}}
				>
					<span
						style={{
							marginBottom: '.5rem',
							color: 'black',
							fontSize: 13,
                            fontFamily: 'Montserrat',

						}}
					>
						Signé
					</span>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '0.5rem',
					}}>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							disabled={true}
							 style={{
								background:isSigned?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: isSigned && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:isSigned ?'#fff': '#444'
							}}
							>Oui</button>
							
						
						</div>
						<div
							
							style={{
								marginRight: '1rem',
							}}
						>
							<button
							 disabled={true}
							 style={{
								background:!isSigned ?'#fcc200' :'#fff',
	                            border: '1px solid #ddd',
								borderColor: !isSigned && '#fcc200',
	                            padding:' 0.5rem 1.25rem',
	                            borderRadius: '5px',
	                            cursor: 'pointer',
								color:!isSigned?'#fff': '#444'
							}}
							>Non</button>
						</div>
						<div
							
							style={{
								marginRight: '1rem',
								marginTop:'-1rem'
							}}
						>
						<button
				        onClick={()=>{
					    history.push(`/chauffeur/missions/${place._id}/${commandeID}/signaler`)
				        }}
                       style={{
                    marginTop: '1rem',
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    
					padding:' 0.5rem 1.25rem',

                    cursor: 'pointer',
					
                }}
            >
                Signaler
                       </button>
					   </div>
					</div>					
				
				</div>
		
           </>
            }
			

            <Modal
				isOpen={modalIsOpenSendSMS}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Envoyer Code</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir envoyer le  code  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoadingSendSMS(true)
							try {
								const res=await sendSMSMiseADispo({ "numero": watch('téléphone_destinataire'),"placeId":place._id,"method":"sms"},token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally {
								setModalIsOpenSendSMS(false)
								setLoadingSendSMS(false)
							}
							  
                              
                            }}

						disabled={loadingSendSMS}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
				         	justifyContent: 'center',
					        alignItems: 'center'
						}}

					>
					 {loadingSendSMS ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenSendSMS(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		   
		   <Modal
				isOpen={modalIsOpenSendEmail}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Envoyer Code</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir envoyer le  code  ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							
							setLoadingSendSMS(true)
							try {
								const res=await sendSMSMiseADispo({ "email": watch('email_destinataire'),"placeId":place._id,"method":"email"},token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally {
								setModalIsOpenSendEmail(false)
								setLoadingSendSMS(false)
							}
							  
                              
                            }}

						disabled={loadingSendSMS}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
				         	justifyContent: 'center',
					        alignItems: 'center'
						}}

					>
					 {loadingSendSMS ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenSendEmail(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		   <Modal
				isOpen={modalIsOpenDelete}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					> Supprimer Adresse</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer cette adresse ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {
								setLoading(true)
								try {
									await softDeleteWaypoint(token,selectedPlace)
									setChargement(true)
								} catch (error) {
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}finally{
									setModalIsOpenDelete(false)
									setSelectedPlace(null)
									setLoading(false)

								}
							}}
                        disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
				         	justifyContent: 'center',
					        alignItems: 'center'
						}}

					>
					
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
								
					</button>
					<button
						onClick={ () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
        </div>
    );
}

export default RecipientInfoForm;
