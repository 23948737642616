import api  from './base'

export const  getAllCommercant=async(page,size,token)=>{
	const  res=await api.get(`/users?filter=commercial&fields=role&page=${page}&limit=${size}&order=-1&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export  const getValidCommerciaux=async(page, size,token)=>{
	const res = await api.get(`/users/validated/all?filter=commercial&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export  const getNotValidCommerciaux=async(page, size,token)=>{
	const res = await api.get(`/users/not-validated/all?filter=commercial&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export const  getCommercantbyIdAndName=async(query,status,page,size,token)=>{
	const  res=await api.get(`/users/commercial/byIdandName/${query}/all?status=${status}&filter=active&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: { 
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const updateAccountCommercial = async (id, values,token) => {
	const params = new URLSearchParams()

	Object.entries(values).map((item) => params.append(item[0], item[1]))

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/users/${id}`, params, config)

	return res.data
}
export const  getCommercialDeletedbyIdAndName=async(query,page,size,token)=>{
	  const  res=await api.get(`/users/commercial/byIdandName/${query}/all?filter=deleted&page=${page}&limit=${size}`,{
		  headers: { 
				Authorization: `Bearer `+token
			  },
			})
	  return res.data
  }
export const getClientOfCommercant=async(id,page,size,token)=>{
	const  res=await api.get(`/users/commercial/${id}/client?page=${page}&limit=${size}&sort=date_affectation&order=1&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const  getClientByNameAndIdForCommercial=async(query,id,page,size,token)=>{
	const  res=await api.get(`/users/clientforcommercial/${query}/${id}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandesByMonthByClientCommercial=async(id,token)=>{
	const res=await api.get(`/commandes/commercial/comptabilite/${id}/all/month`,{
		headers: {
			Authorization: `Bearer `+token
		  },
	})
	return res.data
}
export const  getArchivedCommercial=async(page,size,token)=>{
	const  res=await api.get(`/users/delete/all?filter=commercial&fields=role&page=${page}&limit=${size}&sort=name&order=-1&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const updateCommercial = async (data,id,token) => {
	const res = await api.patch(`/users/commercial/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const restoreDeletedCommercial = async (id,token) => {
	
	const res = await api.get(`/users/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const toggleSuspendreCommercial = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteCommercial = async (id,token) => {
	const res = await api.get(`/users/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercial = async (id,page, size,token) => {
	const res = await api.get(`/comptabilite/commercial/${id}/all?size=${size}&page=${page}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercialByMonth = async (id,page, size,token,month,year) => {
	const res = await api.get(`/comptabilite/commercial/month/${id}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercialByMonthAndName = async (id,page, size,token,month,year,query) => {
	query=query.trim() 
	const res = await api.get(`/comptabilite/commercial/month/${id}/${query}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteCommercialByName= async (id,name,page,size,token) => {
	const res = await api.get(`/comptabilite/commercial/${id}/${name}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getSingleCommercial = async (_id,token) => {
	const res = await api.get(`/users/${_id}?nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getValidClientOfCommercant=async(id,token)=>{
	const  res=await api.get(`/users/commercial/${id}/valid-client`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getCommandeClientCommercial=async(commercialId,clientId,page,size,token)=>{
	const  res=await api.get(`/commandes/commercial/${commercialId}/client/${clientId}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getValidatedCommercial = async (token) => {
    const res = await api.get(`/users/commercial-validated/all?nocache=${Date.now()}`, {
        headers: {
            Authorization: `Bearer ` + token,
        },
    });
    return res.data;
};
export const updateProfileCommercail = async (id,data,token) => {
	
	const res = await api.patch(`/profile/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getCommercialStats=async(commercialId,token)=>{
	const res = await api.get(`/commandes/commercial/stats/${commercialId}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}