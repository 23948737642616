import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { getSignalement } from '../../api/signalements';
import { BASE_URL } from '../../api/base'

// Styles pour le modal
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    borderRadius: '10px',
    border: 'none',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    fontFamily: 'Montserrat, sans-serif',
    maxHeight: '80vh',
    overflow: 'hidden',
    width: "70%",
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};

// Autres styles
const headerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#5BBCEA',
  padding: '20px',
  position: 'relative',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
};
const closeButtonStyle = {
  position: 'absolute',
  right: '15px',
  backgroundColor: 'transparent',
  padding: '0',
  color: '#FF5A5F',
  border: 'none',
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  cursor: 'pointer',
  fontSize: '22px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  transition: 'background-color 0.3s, color 0.3s',
};
const contentStyle = {
  padding: '20px',
  background: '#F7F8F8',
  overflowY: 'auto',
  maxHeight: 'calc(80vh - 60px)',
  fontFamily: 'Montserrat',
};
const details = {
  padding: "15px",
  marginTop: '10px',
  marginBottom: "10px",
  borderTop: '1px solid #D9D9D9A8',
};
const detailsTitle = {
  color: "#333333",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: '19.5px',
};
const detailsSpan = {
  color: "#606060",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: '19.5px',
};
const title = {
  color: '#25A6E4',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19.5px',
};
const documentListStyle = {
  listStyleType: 'none',
  padding: 0,
  marginTop: '10px',
};
const documentItemStyle = {
  marginBottom: '10px',
};

// Composant principal
const DetailsSignalementModal = ({ isOpen, onClose, signalementId }) => {
  const [chargementEnCours, setChargementEnCours] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [signalement, setSignalement] = useState(null);

  const fetchSignalement = async (signalementId) => {
    setChargementEnCours(true);
    try {
      const data = await getSignalement(signalementId, token);
      setSignalement(data);
    } catch (error) {
      toast.error("Une erreur s'est produite", {
        style: { fontFamily: 'Montserrat' },
      });
    } finally {
      setChargementEnCours(false);
    }
  };

  const typeLabels = {
    client_absent: "Client absent",
    retard_aux_chargement: "Retard aux chargement",
    livraison_reportée: "Livraison reportée",
    retard_aux_déchargement: "Retard aux déchargement",
    marchandises_manquantes_chargement: "Marchandises manquantes/chargement",
    marchandise_refusée: "Marchandise refusée",
    marchandises_incomplètes: "Marchandises incomplètes",
    marchandise_abîmée: "Marchandise abîmée",
    route_inaccessible_fermée: "Route inaccessible ou fermée",
    circulation_dense: "Circulation dense"
  };
  useEffect(() => {
    if (signalementId && isOpen) {
      fetchSignalement(signalementId);
    }
  }, [signalementId, isOpen]);

  const renderDocuments = (documents) => {
    if (!documents || documents.length === 0) {
      return <p style={detailsSpan}>Aucun document associé</p>;
    }

    return (
      <ul style={documentListStyle}>
        {documents.map((doc) => (
          <li key={doc._id} style={documentItemStyle}>
            {doc.mimetype.startsWith('image/') ? (
              <a href={`${BASE_URL}/${doc?.path}`} target="_blank" rel="noopener noreferrer">
                <img
                  src={`${BASE_URL}/${doc?.path}`}
                  alt={doc.name}
                  style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
                />
              </a>
            ) : (
              <p  style={detailsTitle}>
                              <a href={`${BASE_URL}/${doc?.path}`} target="_blank" rel="noopener noreferrer">
                                {doc?.name}
                              </a>
              </p>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={modalStyles}
    >
      <div style={headerStyle}>
        <h3 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>
          {`Détails du signalement`}
        </h3>
        <button onClick={onClose} style={closeButtonStyle}>
          X
        </button>
      </div>
      {chargementEnCours ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <div className='customLoader'></div>
        </div>
      ) : (
        <div style={contentStyle}>
          <div style={details}>
          <p style={detailsTitle}>
            Numéro de la mission :{' '}
              <span style={detailsSpan}>{signalement?.mission_index}</span>
         </p>
            <p style={detailsTitle}>
              Type d'anomalie :{' '}
              <span style={detailsSpan}>{typeLabels[signalement?.type] }</span>
            </p>
            <p style={detailsTitle}>
              Adresse du point de passage :{' '}
              <span style={detailsSpan}>{signalement?.points_passage?.adresse?.label}</span>
            </p>
            <p style={detailsTitle}>
              Note : <span style={detailsSpan}>{signalement?.note}</span>
            </p>
          </div>

          <div style={details}>
            <span style={title}>Informations sur le destinataire</span>
            <p style={detailsTitle}>
              Nom & Prénom :{' '}
              <span style={detailsSpan}>
                {signalement?.points_passage?.nom_destinataire}{" "}
                {signalement?.points_passage?.prénom_destinataire}
              </span>
            </p>
            <p style={detailsTitle}>
              Numéro de téléphone :{' '}
              <span style={detailsSpan}>{signalement?.points_passage?.téléphone_destinataire}</span>
            </p>
            <p style={detailsTitle}>
              Adresse email :{' '}
              <span style={detailsSpan}>{signalement?.points_passage?.email_destinataire}</span>
            </p>
            <p style={detailsTitle}>
              Adresse :{' '}
              <span style={detailsSpan}>{signalement?.points_passage?.adresse_destinataire}</span>
            </p>
          </div>

          <div style={details}>
            <span style={title}>Documents associés</span>
            {renderDocuments(signalement?.files)}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DetailsSignalementModal;
