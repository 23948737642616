import React, { useState } from 'react';

import { FaTrash } from 'react-icons/fa'; 
import File from '../../assets/img/file@2x.png';
import UploadSingleFileCommande from '../UploadSingleFile/UploadSingleFileCommande';
import { BASE_URL } from '../../api/base';
import { deleteMedia } from "../../api/upload";

function UpdateUploadFileCommande({ name, onChange, initialFiles = [], commandeId, missionId,hideAddFile=false,hideRemoveFile=false }) {
    const [files, setFiles] = useState(initialFiles);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
        },
        fileContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#fff',
            padding: '15px',
            borderRadius: '8px',
            marginBottom: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.2s',
        },
        fileDetails: {
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
        },
        image: {
            width: '80px',
            height: '80px',
            borderRadius: '8px',
            objectFit: 'cover',
        },
        pdfContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
        },
        fileName: {
            fontSize: '1rem',
            fontWeight: '500',
			color: '#25A6E4',
            cursor: 'pointer',
        },
        deleteIcon: {
            color: '#FF6B6B',
            fontSize: '1.2rem',
            cursor: 'pointer',
            transition: 'color 0.3s ease',
        },
        deleteIconHover: {
            color: '#D64545',
        },
    };

    return (
        <div style={styles.container}>
            {!hideAddFile &&
            <UploadSingleFileCommande
                name={name}
                accept=".pdf"
                onFile={(file) => {
                    const updatedFiles = [...files, file];
                    setFiles(updatedFiles);
                    onChange(updatedFiles);
                }}
                commandeId={commandeId}
                missionId={missionId}
            />
            }
            {files.map((file, index) => (
                <div key={index} style={styles.fileContainer}>
                    <div style={styles.fileDetails}>
                        {file.mimetype !== 'application/pdf' ? (
                            <img style={styles.image} src={`${BASE_URL}/${file?.path}`} alt={file?.name} />
                        ) : (
                            <div style={styles.pdfContainer}>
                                <img src={File} alt="PDF" style={{ width: '20px', height: '20px' }} />
                                <span
                                    style={styles.fileName}
                                    onClick={() => window.open(`${BASE_URL}/${file?.path}`, '_blank')}
                                >
                                    {file?.name}
                                </span>
                            </div>
                        )}
                    </div>
                    {!hideRemoveFile &&
                    <FaTrash
                        style={styles.deleteIcon}
                        onMouseOver={(e) => (e.target.style.color = styles.deleteIconHover.color)}
                        onMouseOut={(e) => (e.target.style.color = styles.deleteIcon.color)}
                        onClick={async () => {
                            await deleteMedia(file?.id || file?._id);
                            const updatedFiles = files.filter((_, i) => i !== index);
                            setFiles(updatedFiles);
                            onChange(updatedFiles);
                        }}
                    />
                   }
                </div>
            ))}
        </div>
    );
}

export default React.memo(UpdateUploadFileCommande);
