import React from 'react'
import moment from 'moment'
function MiseADispoHorizontal({
	commande,
	onClickDetails = () => {},
	onClickSignaler = () => {},
	onClickAccept = () => {},
	onClickRecu = () => {},
	onClickAttribuer = () => {},
	onClickRefuser=()=>{},
	hideStatus = false,
	hideAccept = true,
	hideRecu = false,
	hideSignaler,
	hideAttribuer = false,
	hideTarif = false,	
}) {
   
	const findClosestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
	
		if (validMissions.length === 0){
			const sortedAnnulerMissions=missions
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
			return sortedAnnulerMissions[0]
		}
	
		return validMissions[0];
	};
	
	const closestMission = findClosestMission(commande?.missions);
	
	const findLatestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
				return bDateTime - aDateTime;
			});
	
		if (validMissions.length === 0) {
			const sortedAnnulerMissions=missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
				return bDateTime - aDateTime;
			});
			return sortedAnnulerMissions[0]
		}
	
		return validMissions[0];
	};
	const latestMission = findLatestMission(commande?.missions);

	

	



   const totalForfaitsSupp = commande.missions
    .filter(mission => mission.statut !== 'annuler')
    .reduce((total, mission) => {
        const totalForfaitsMission = mission.forfaits
            ?.reduce((totalForfait, forfait) => totalForfait + (forfait.prix || 0), 0) || 0;
        
        return total + totalForfaitsMission;
    }, 0);
   


	
	

	return (
		<div
			style={{
				width: '100%',
				background: '#E4EAF0',
				display: 'flex',
				flexDirection: 'row',
				padding: '1rem',
				borderRadius: 10,
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginBottom: 10,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						marginBottom: 3,
					}}
				>
					Mise a disposition {!!commande ? 'N°' + commande.__id : '' } /{' '}
					<span style={{ color: 'red' }}>
						{commande.temporisation === 'immediat'
							? 'Immédiat'
							: 'Planifier'}
					</span>
				</span>
                <div 
				style={{
					display: 'flex',
					flexDirection: 'row'
				}}>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',


					}}
				>
					Date du Transports :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red',

						}}
					>
					{moment(commande.date_transport).format('DD/MM/YYYY')}
				</span>
				</span>
				
				</div>
				
                <span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginBottom: '1rem',

					}}
				>
					Date du Création :{' '}
					<span
						style={{
							marginRight: 13,
							color:'red'
						}}
					>
				    {moment(commande.createdAt).format('DD/MM/YYYY')}
					</span>
				</span>
				<span
					style={{
						marginRight: 13,
						marginBottom: '1rem',
						color: '#838EAB',
						width: '15rem',

					}}
				>
          Date de début  {' '}
		  <span 
		  style={{color:"red",
           }}>
		   {closestMission?.date_debut}{' '}
           {closestMission?.heure_debut}{' '}
           {closestMission?.adresse_debut?.label}
		  </span>
		 
				</span>

				<span
					style={{
						marginRight: 13,
						color: '#838EAB',
						width: '15rem',

					}}
				>
					 Date de fin:{' '}
					 <span 
					  style={{color:"red",
						 }}>
					{latestMission?.date_fin}{' '}
                    {latestMission?.heure_fin}{' '}
                    {latestMission?.adresse_fin?.label}
					 </span>
					
				</span>
                <span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,

					}}
				>
					 L'état de paiement :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red'
						}}
					>
				 {commande.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,

					}}
				>
					 Statut de la commande :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red'
						}}
					>
                    {commande?.statut==="init" && "Non commencée"}
                    {commande?.statut==="en-cours" && "En cours"}
                    {commande?.statut==="terminer" && "Terminée"}
                   {commande?.statut==="annuler" && "Annulée"}
					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,

					}}
				>
					Nombre de missions: {' '}
					<span style={{color:'red'}}>
					{commande?.missions?.length}
					</span>
					
				</span>
        
			</div>

			{/*  */}

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					height: '100%',

				}}
			>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Nombre de rippeurs :{' '}
					<span style={{ color: 'red' }}>
					{commande.statut!=="annuler" ?(commande?.missions
                    ?.filter(mission => mission.statut !== "annuler")
                    ?.reduce((total, mission) => total + (mission?.nombre_rippeur || 0), 0) || 0)
				    :
					(commande?.missions
						?.reduce((total, mission) => total + (mission?.nombre_rippeur || 0), 0) || 0)
				    }    


					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Documents :{' '}
					<span style={{ color: 'red' }}>
					{commande.statut!=="annuler"  ? (commande?.files?.length || 0 + 
					commande?.missions
					?.filter(mission=>mission.statut!=="annuler")
					?.reduce((total, mission) => total + (mission.files?.length || 0), 0))
					:
					(commande?.files?.length || 0 + 
						commande?.missions
						?.reduce((total, mission) => total + (mission.files?.length || 0), 0))
					}

					</span>
				</span>
				<span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Référence interne client :{' '}
					<span style={{ color: 'red' }}>
					{commande.ref_client}
					</span>
				</span>
                <span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Nombre de  kilomètrage :{' '}
					<span style={{ color: 'red' }}>
					{commande.distance}
					</span>
				</span>
				<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',

						}}
					>
						Notes utiles:{' '}
						<span style={{ color: 'red' }}>
                         {commande.statut!=="annuler"? 
						 (commande?.missions
                         ?.filter(mission => mission.statut !== "annuler")
                         ?.some(mission => mission?.notes?.length > 0) ? 'Oui' : 'Non')
						  :
						  (commande?.missions
							?.some(mission => mission?.notes?.length > 0) ? 'Oui' : 'Non')
						 }
                         </span>

					</span>
				
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft:'0.5rem',
					marginRight:'0.5rem',
					fontSize: 13,
				}}
			>
				{!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .1rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
							Montant Totale:{' '}
							
							{
                             ((commande.prix+totalForfaitsSupp) - ((commande.prix+totalForfaitsSupp) * (commande.margin ?? 0) / 100)).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
				{!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
						marginTop:2
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Initiale:{' '}
							
						
							{
                             (commande.prix - (commande.prix * (commande.margin ?? 0) / 100)).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
				{!hideTarif && (
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Forfaits supplémentaires:{' '}
							{
                             (totalForfaitsSupp - (totalForfaitsSupp * (commande.margin ?? 0) / 100)).toFixed(2)
                            }€
						</span>
					</span>
				</div> )}
			    
			
				
                
			</div>

			{/*  */}

			<div
				style={{
					color: '#838EAB',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span>
					Diable:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Diable') && 'Oui'}
					</span>
				</span>
				<span>
					Transpalette manuel:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Transpalette manuel') &&
							'Oui'}
					</span>
				</span>
				<span>
					Transpalette électrique:{' '}
					<span style={{ color: 'red' }}>
						{commande.transpalette_électrique ? 'Oui' :'Non'}
					</span>
				</span>
				<span>
					Sangle:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Sangle') && 'Oui'}
					</span>
				</span>

				<span>
					Casque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Casque') && 'Oui'}
					</span>
				</span>
				<span>
					Gants:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gants') && 'Oui'}
					</span>
				</span>
				<span>
					Masque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Masque') && 'Oui'}
					</span>
				</span>
				<span>
					Lunettes:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Lunettes') && 'Oui'}
					</span>
				</span>
				<span>
					Chaussure de sécurité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Chaussure de sécurité') &&
							'Oui'}
					</span>
				</span>
				<span>
					Gilet de visibilité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gilet de visibilité') && 'Oui'}
					</span>
				</span>
			</div>

			<div
				style={{
					//width: '16%',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					marginTop: 35,
				}}
			>
                {!hideAttribuer && (
					<>

						<button
							onClick={onClickAttribuer}
							style={{
								padding: '0.5rem 1.5rem',
								background: '#50F5A9',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								marginTop: 5,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Gestion des missions
						</button>
					</>
				)}
				
				
				<button
					onClick={onClickDetails}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir détails
				</button>
				

				{ hideStatus && !hideSignaler &&
				<button
				onClick={onClickSignaler}
				style={{
					padding: '0.5rem 1.5rem',
					background: '#838EAB',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Signalements
			   </button>
				}
			   
				
				
				
				
				
				{!hideRecu && !commande.recu && (
					<button
						onClick={onClickRecu}
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							background: '#fce399',
							color: 'white' ,
							border: 'none',
							fontWeight: 'bold',
							padding: '0.5rem 1.5rem',
							borderRadius: 4,
							marginTop: 5,
						}}
					>
						Reçu
					</button>
				)}
				   
					{(!hideRecu && !commande?.recu)&& (
					<button
						onClick={onClickRefuser}
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							background:'#f29393',
							color:  'white',
							border: 'none',
							fontWeight: 'bold',
							padding: '0.5rem 1.5rem',
							borderRadius: 4,
							marginTop: 5,
						}}
					>
						Refuser
					</button>
					
				)}
				{!hideAccept && (
					<button
						onClick={onClickAccept}
						disabled={!commande?.canAccept} 
						style={{
							cursor: 'pointer',
							fontFamily: 'Montserrat',
							background: commande?.canAccept? '#50F5A9':'#838EAB',
							color: commande?.canAccept? 'white' :'#9EA8BF',
							border: 'none',
							fontWeight: 'bold',
							padding: '0.5rem 1.5rem',
							borderRadius: 4,
							marginTop: 5,
						}}
					>
						Accepter
					</button>
				)}
				
			</div>
		
		 
			
		</div>
	)
}

export default React.memo(MiseADispoHorizontal)
