import React,{useState,useEffect} from 'react';
import Modal from 'react-modal';
import {getForfaitsByMissionId} from '../../api/commande'
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '80vh',
        overflow: 'hidden',
        width: "70%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#5BBCEA',
    padding: '20px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const closeButtonStyle = {
    position: 'absolute',
    right: '15px',
    backgroundColor: 'transparent',
    padding: '0',
    color: '#FF5A5F',
    border: 'none',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
};

const contentStyle = {
    padding: '20px',
    background: '#F7F8F8',
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 60px)',
};






const ForfaitSupplementaireModal = ({ isOpen, onClose, missionId,marge }) => {
   const [chargementEnCours,setChargementEnCours]=useState(false)
    const token = useSelector((state) => state.auth.token);
    const [forfaits,setForfaits]=useState([])


    const fetchForfaits=async(missionId)=>{
        setChargementEnCours(true)
        try {
           const res=await  getForfaitsByMissionId(missionId,token)
           setForfaits(res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });  
        }finally{
         setChargementEnCours(false)
        }
    }
    const getForfaitDetails = (forfait) => {
        switch (forfait.type) {
            case 'kilometrage':
            case 'kilometrage_etage':
                return (
                    <>
                        <strong style={{ marginLeft: "1rem" }}>Kilométrage :</strong> <span style={{ color: '#FF5A5F' }}>{forfait.details?.kilometrage ?? 'N/A'} km</span>
                        <strong style={{ marginLeft: "1rem" }}>Étages :</strong> <span style={{ color: '#FF5A5F' }}>{forfait.details?.etages ?? '0'}</span>   
                    </>
                );
            case 'heure':
            case 'heure_etage':
                return (
                    <>
                    
                        <strong style={{ marginLeft: "1rem" }}>Heures :</strong> <span style={{ color: '#FF5A5F' }}>  {forfait.details?.heures ?? 'N/A'}</span>   
                        <strong style={{ marginLeft: "1rem" }}>Étages :</strong> <span style={{ color: '#FF5A5F' }}>{forfait.details?.etages ?? '0'}</span>   

                    </>
                );
            case 'etage':
                return (
                    <>
                    <strong style={{ marginLeft: "1rem" }}>Étages :</strong> <span style={{ color: '#FF5A5F' }}>{forfait.details?.etages ?? '0'}</span>   
                    </>
                );
            case 'forfait_gratuit':
                return (
                    <>
                    <strong style={{ marginLeft: "1rem" }}>Heures :</strong> <span style={{ color: '#FF5A5F' }}>  {forfait.details?.heures ?? 'N/A'}</span>   
                    </>
                )

            default:
                return (
                    <span>
                        Aucun détail disponible
                    </span>
                );
        }
    };
    const getForfaitLabel = (type) => {
        switch (type) {
            case 'kilometrage':
                return 'Kilométrage';
            case 'kilometrage_etage':
                return 'Kilométrage et Étages';
            case 'heure':
                return 'Heures Supplémentaires';
            case 'heure_etage':
                return 'Heures et Étages';
            case 'etage':
                return 'Étages';
            case 'forfait_gratuit':
                return 'Forfait Gratuit';
            default:
                return type; 
        }
    };
        useEffect(()=>{
            if(missionId && isOpen){
                fetchForfaits(missionId) 
            }
        },[missionId,isOpen])

    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={onClose}  
            ariaHideApp={false}
            style={modalStyles}
        >
            <div style={headerStyle}>
                <h3 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>Forfaits Supplémentaires</h3>
                <button 
                    onClick={onClose}  
                    style={closeButtonStyle}
                >
                    X
                </button>
            </div>
            {chargementEnCours ?
            <div
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               backgroundColor: 'white',
               marginTop:"2rem",
               marginBottom:"2rem"
              }}
             >
            <div className='customLoader'></div>	
           </div>
            :
            <div style={contentStyle}>
                {forfaits?.length === 0 ?
                <div style={{
                    background: '#fff',
                    padding: '15px',
                    borderRadius: '8px',
                    marginBottom: '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.2s',
                    textAlign:'center'
                    }}>
                    Il n'y a pas de forfaits supplémentaires à afficher
                </div>
                :
                forfaits?.map((forfait, index) => (
                    <div key={index} 
                    style={{
                        background: '#fff',
                        padding: '15px',
                        borderRadius: '8px',
                        marginBottom: '15px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s',
                    }}>
                        <span style={{
                              fontWeight: 'bold',
                              color: '#25A6E4',
                              fontSize: '16px',
                        }}>
                            Forfait N° {index + 1} {' '}{getForfaitLabel(forfait.type)}
                        </span>
                        <div style={{
                             marginBottom: '8px',
                             marginTop:'1rem',
                             fontSize: '14px',
                             color: '#838EAB',
                        }}>
                                    <span>  {getForfaitDetails(forfait)}</span>
                                    <strong style={{ marginLeft: "1rem" }}>Prix :</strong> <span style={{ color: '#FF5A5F' }}>{((forfait?.prix)- ((forfait?.prix) * (marge ?? 0)) / 100).toFixed(2)} € </span>
                             
                        </div>
                        
                    </div>
                ))}
            </div>
             }
        </Modal>
    );
};

export default ForfaitSupplementaireModal;
