const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const UPDATE_PROFILE = 'UPDATE_PROFILE'
const SET_SUSPENDU = 'UPDATE_SUSPENDU'
const REFRESH_TOKEN ="REFRESH_TOKEN"

export const setSuspendu = (status) => {
	return {
		type: SET_SUSPENDU,
		payload: {
			suspendre: status === "suspendu",
		},
	}
}

export const loginAction = (user, token,refreshToken) => {
	return {
		type: LOGIN,
		payload: {
			user,
			token,
			refreshToken
		},
	}
}

export const logoutAction = () => {
	return {
		type: LOGOUT,
		payload: null,
	}
}

export const updateProfile = (user) => {
	return {
		type: UPDATE_PROFILE,
		payload: {
			user,
		},
	}
}

export const refreshTokenAction=(token)=>{
	return {
		type:REFRESH_TOKEN,
		payload:{
			token   
		}
	}
}

const initialState = {
	isAuthenticated: false,
	token: null,
	refreshToken:null,
	user: null,
}

export default (state = initialState, action) => {
	const { type, payload, error } = action

	switch (type) {
		case LOGIN:
			return {
				...state,
				isAuthenticated: true,
				user: payload.user,
				token: payload.token,
				refreshToken:payload.refreshToken
			}

		case UPDATE_PROFILE:
			return {
				...state,
				user: {
					...state.user,
					...payload.user,
				},
			}

		case SET_SUSPENDU:
			return {
				...state,
				user: {
					...state.user,
					statusSuspendre: payload.suspendre,
				},
			}
			
		case REFRESH_TOKEN: 
			return {
				...state,
				token: payload.token,
			}

		case LOGOUT:
			return {
				...initialState,
			}

		default:
			return state
	}
}
