
import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import { GradientText, Wrapper } from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import {getCommandeClientCommercial} from '../../api/commerciaux'
import { useHistory ,useParams} from 'react-router'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'
import toast from 'react-hot-toast'
import DetailCommandeModal from '../../Components/DetailCommande/DetailCommandeModal'
const SIZE=10

function ListCommande() {
    const history = useHistory()
    const {id}=useParams()
	const [commandes, setCommandes] = useState([])
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const commercialID = useSelector((state) => state.auth.user._id)
	const token = useSelector((state) => state.auth.token);

	
	const [page, setPage] = useState(1)
	const [totalPages,setTotalPages]=useState(1)

	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [selectedCommande,setSelectedCommande]=useState(null)
	
	 const fetchCommandes = async (page) => {
		setChargementEnCours(true);
		try {
			 
			const res = await getCommandeClientCommercial(commercialID,id, page, SIZE,token);
			
			setCommandes(res.commandes);
			setTotalPages(res.totalPages);
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};



	useEffect(() => {
		fetchCommandes(page)
	}, [page])

  return (
    <LayoutCommercant>
        <Wrapper>
		<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							margin:'2rem'
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Commandes'.toUpperCase()}
						</GradientText>
		</div>
				
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
				
						<div
							style={{
								width: '10rem',
								height: 1,
								background: '#F2F2F2',
							}}
						/>
					</div>
					{chargementEnCours ?
					<div
					style={{
					  display: 'flex',
					  alignItems: 'center',
					  justifyContent: 'center',
					  backgroundColor: 'white',
					  marginTop:"10rem"
					}}
					>
					<div className='customLoader'></div>	
					</div>
					:
					
				    <div>
					{ commandes?.length === 0 ? 
					<span
					style={{
					
						
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					Il n'y a pas des commandes à afficher
				</span>
				:commandes?.map((commande) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidefacture
                                    hideUpdate
                                    hidecancel
									hideStatus
									hideSignaler
									onClickDetails={() => {
										history.push(
											`/commerçant/${commande._id}`
										)
									}}
									
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
									
									
									
								/>
							)
						} else {
							return (
								<MiseADispoHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidefacture
									hideStatus
                                    hideUpdate
                                    hidecancel
									hideSignaler
									hideAddFile
									hideRemoveFile
									onClickDetails={() => {
										setSelectedCommande(commande._id)
										setModalIsOpenDetail(true)
									}}
									onClickSignaler={() => {
										history.push(
											`/transports/${commande._id}/single`
										)
									}}
									onClickMissions={()=>{
										history.push(
											`/commercial/transports/missions/${commande._id}`
										)
									}}
								
								
								/>
							)
						}
					})}
				   </div>
                   }
				   <div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom:"3rem",
							marginTop:"3rem"
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={totalPages}
							onChange={(newPage) => setPage(newPage)}
						/>
				   </div>
				   <DetailCommandeModal 
			        isOpen={modalIsOpenDetail} 
			        onClose={() => setModalIsOpenDetail(false)}
			        commandeId={selectedCommande}
			      />
				</div>
			</Wrapper>
    </LayoutCommercant>
  )
}

export default ListCommande