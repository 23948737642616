import React, { useEffect, useState } from 'react'
import {
    getCommandesAnnuleesForAdmin,
} from '../../../api/commande'
import toast from 'react-hot-toast'
import {
    searchCommandesByClientAndTransporterForPlateforme,
	searchCommandesByDate,
	searchCommandesByPrixMinMax,
	searchCommandesById
} from '../../../api/search'

import DataTable from 'react-data-table-component'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import {BASE_URL } from '../../../api/base'
import Select from 'react-select'
import moment from 'moment'
import DetailCommandeModal from '../../../Components/DetailCommande/DetailCommandeAdminModal'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

function CommandeAnnulles() {
	const history = useHistory()

	const [data, setData] = useState([])
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const token = useSelector((state) => state.auth.token);
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [searchQuery, setSearchQuery] = useState('')
	const [searchQueryId, setSearchQueryId] = useState('')
	const type="annulees"
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
    const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [selectedCommande,setSelectedCommande]=useState(null)
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	});
	const [isSearching, setIsSearching] = useState(false);
	
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };
	
	const fetchCommandes = async (page, perPage, isSearching) => {
        setChargementEnCours(true);
        try {
            let res;
            if (!isSearching) {
                res = await getCommandesAnnuleesForAdmin(page, perPage, token);
            } else {
                switch (searchCriteria) {
                    case 'id':
                        if (!searchQueryId.trim()) {
                            toast.error('Veuillez saisir un identifiant', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        res = await searchCommandesById(searchQueryId, type, token);
                        break;
                    case 'ct':
                        if (!searchQuery.trim()) {
                            toast.error('Veuillez saisir un nom de client', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        res = await searchCommandesByClientAndTransporterForPlateforme(searchQuery, type, token, page, perPage);
                        break;
                    case 'prix':
                        if (Number(min) > Number(max)) {
                            toast.error('Le prix minimum doit être inférieur au prix maximum', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        res = await searchCommandesByPrixMinMax(min, max, type, token, page, perPage);
                        break;
                    case 'date':
                        if (!range || !range[0] || !range[1]) {
                            toast.error('Veuillez sélectionner une plage de dates', {
                                style: { fontFamily: 'Montserrat' },
                            });
                            return;
                        }
                        const minDate = moment(range[0]).format();
                        const maxDate = moment(range[1]).format();
                        const offsetUTC = moment().format('Z');
                        res = await searchCommandesByDate(minDate, maxDate, type, token, page, perPage, offsetUTC);
                        break;
                    default:
                        return;
                }
            }
            setData(res?.commandes || []);
            setTotalRows(res?.totalCommandes || 0);
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la recherche", {
                style: { fontFamily: 'Montserrat' },
            });
        } finally {
            setChargementEnCours(false);
        }
    };
	const handleSearch = async () => {
        setIsSearching(true);
        setCurrentPage(1);
        await fetchCommandes(1, perPage, true);
    };
	const handleClear = async () => {
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		})
		setSearchCriteria('id')
		setSearchQuery('');
		setSearchQueryId('');
		setMin(0);
		setMax(300);
		setRange([new Date(), new Date()]);
		setIsSearching(false);
		setCurrentPage(1);
	
		await fetchCommandes(1, perPage, false);
	};

		

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchCommandes(page,perPage,isSearching)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchCommandes(page,newPerPage,isSearching)
		setPerPage(newPerPage)
	}
	const findClosestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
	
		if (validMissions.length === 0){
			const sortedAnnulerMissions=missions
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
			return sortedAnnulerMissions[0]
		}
	
		return validMissions[0];
	};
	const findLatestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
				return bDateTime - aDateTime;
			});
			
			
		if (validMissions.length === 0){
				const sortedAnnulerMissions=missions
				.sort((a, b) => { 
					const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
					const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
					return bDateTime - aDateTime;
				});
				return sortedAnnulerMissions[0]
			}
		return validMissions[0];
	};

	

	useEffect(() => {
		fetchCommandes(currentPage,perPage,isSearching)
	}, [])

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			width: '110px',
			sortable: false,
		},
		{
			name: 'Type du transport',
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: '#51C7F2',
							marginBottom: 3,
						}}
					>
						{row.type_commande == 'course-a-course'
							? 'Course à course'
							: 'Mise à disposition'}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		{
			name: 'Date début',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const closestMission = findClosestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
						<span>{closestMission?.date_debut}</span>
						<span style={{marginLeft:'0.5rem'}}>{closestMission?.heure_debut}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].date_debut}</span>
								<span style={{marginLeft:'0.5rem'}}>{row.courses[0].heure_debut}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Date fin',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const latestMission = findLatestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
						<span>{latestMission?.date_fin}</span>
						<span style={{marginLeft:'0.5rem'}}>{latestMission.heure_fin}</span>
						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								
								<span>{row.courses.length===1 ? row.courses[0].date_fin :row.courses[row.courses.length-1].date}</span>
								<span style={{marginLeft:'0.5rem'}}> {row.courses.length===1 ? row.courses[0].heure_fin : row.courses[row.courses.length-1].heure}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '200px',
		},
		{
			name: 'Départ',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const closestMission = findClosestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'row' }}
						>
						<span>{closestMission?.adresse_debut?.label}</span>

						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					
						return (
							<div
								style={{ display: 'flex', flexDirection: 'row' }}
							>
								<span>{row.courses[0].adresse_debut?.label}</span>
							</div>
						)
					
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: ' Arrivé',
			selector: (row) => {
				if (row.type_commande === 'mise-a-disposition') {
					const latestMission = findLatestMission(row?.missions);
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
						<span>{latestMission?.adresse_fin?.label}</span>

						</div>
					)
				}

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length===1 ? row.courses[0].adresse_fin?.label :row.courses[row.courses.length-1].adresse?.label}</span>
						</div>	
					)
				}
			},
			sortable: false,
			width: '250px',
		},
		{
			name: 'Etapes ',
			selector: (row) => {

				if (row.type_commande === 'course-a-course') {
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.courses.length}</span>
						</div>	
					)
				}else{
					return (
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>{row.missions.length}</span>
						</div>
					)
				}
			},
			sortable: false,
			width: '100px',
		},
		{
			name: 'Statut',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						
						style={{
							
							fontWeight: 'bold',
							color: '#51C7F2',
							
						}}
					>
						{row.statut ==="en-cours" ? 'En cours' : (row.statut==="terminer" ? "Terminée" : row.statut==="annuler" ? "Annulée": "Non commencée" )}
					</span>	
				</div>
			),
			sortable: false,
			width: '180px',
		},
		{
			name: 'Annulation',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						
						style={{
							
							fontWeight: 'bold',
							color: '#51C7F2',
							
						}}
					>
						{row.statut ==="annuler" ? 'Complète' : 'Partielle'}
					</span>	
				</div>
			),
			sortable: false,
			width: '150px',
		},
		{
			name: "Date d'annulation",
			selector: (row) =>  moment(row?.date_annulation).format('DD/MM/YYYY'),
			sortable: false,
			width: '150px',
		},
		{
			name: 'Montant',
			selector: (row) => {
				const totalForfait = row.packages || [];
				const totalPackagePrice = totalForfait.reduce((total, p) => total + p.price, 0);
				
				const montantTotal = row.prix + totalPackagePrice;
				const montantAvecMargin = (montantTotal - (montantTotal * (row.margin ?? 0)) / 100).toFixed(2);
			  
				return (
				  <div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{montantTotal.toFixed(2)}€</span>
					<span>{montantAvecMargin}€</span>
				  </div>
				);
			  },
			sortable: false,
			width: '180px',
		},
		{
			name: '% marge appliqué',
			selector: (row) => <div 
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		><span  
		style={{
			// background: '#50F5A9',
			border: '1px solid',
			borderBlockColor: 'grey',
			// cursor: 'pointer',
			color: 'black',
			fontFamily: 'Montserrat',
			padding: '.5rem',
			borderRadius: 6,
		}} >{row.margin ?? 0}</span></div>,
			sortable: false,
			width:'180px'
		},
		{
			name: 'Transporteur',
			cell: (row) => (
				row.transporterID ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
							onClick={()=>{
								if(row.transporterID?.deletedAt){
									history.push(`/admin/archive/transporter/${row.transporterID._id}`)
		
								}else{
									history.push(`/admin/transporter/${row.transporterID._id}`)
		
								}
							}}
						>
							<img
								style={{
									width: '2rem',
									height: '2rem',
									borderRadius: '1rem',
									marginRight: 10,
								}}
								src={
									row.transporterID && row.transporterID.logo
										? `${BASE_URL}/${row.transporterID.logo.path}`
										: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
								}
								alt=''
							/>
							<span style={{
								fontWeight: 'bold',
								cursor: 'pointer',
								color: row?.clientID?.deletedAt !== null ? 'red' : '#51C7F2'
							}}>
								{row?.transporterID?.societe}
							</span>
						</div>
						<button
							style={{
								cursor: 'pointer',
								fontFamily: 'Montserrat',
								background: row?.recu ? '#FCC200' : '#838EAB',
								color: row?.recu ? 'white' : '#9EA8BF',
								border: 'none',
								marginTop:"2px",
								fontWeight: 'bold',
								padding: '0.3rem 1rem',
								borderRadius: 4,
							}}
						>
							Reçu
						</button>
					</div>
				) : '__'
			),
			sortable: false,
			width: '200px',
		},
		{
			name: 'Client',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onClick={()=>{
						if(row.clientID?.deletedAt !== null){
							history.push(`/admin/clients/single/${row.clientID._id}`)

						}else{
							history.push(`/admin/clients/${row.clientID._id}`)

						}
					}}
				>
					<img
						style={{
							width: '2rem',
							height: '2rem',
							borderRadius: '1rem',
							marginRight: 10,
						}}
						src={
							row.clientID && row.clientID.logo
								? `${BASE_URL}/${row.clientID.logo.path}`
								: 'https://secure.gravatar.com/avatar/6ee7af2da22737fd49ce56adc226d2cb?size=200&d=mp'
						}
						alt=''
					/>
                  <span style={{
					fontWeight: 'bold',
					cursor: 'pointer',
					color:row?.clientID?.deletedAt !== null ? 'red' :'#51C7F2'
				  }}>
                  {row?.clientID?.societe}
                  </span>				
				  </div>
			),
			sortable: false,
			width:'180px'
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							setSelectedCommande(row?._id)
							setModalIsOpenDetail(true)
						  }}
						style={{
							cursor: 'pointer',
						}}
					>
							 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      
					  width='26'
					  height='26'
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#b4b4c3"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#b4b4c3"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>
					</div>

					
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Annulées'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{ value: 'id', label: "Recherche par l'identifiant " },
								{
									value: 'ct',
									label: 'Nom Client / Nom transporteur',
								},
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
                    {searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'ct' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}

					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
						onClick={handleSearch}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={handleClear}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>

					

					
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des commandes à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
			<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
			
		</LayoutAdmin>
	)
}

export default CommandeAnnulles
