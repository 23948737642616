import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import {
	
	softDeleteClient,
	getSingleClient,
	toggleSuspendreClient,
	updateClient,
} from '../../api/clients'
import { useHistory, useParams } from 'react-router'
import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import { FormSectionCard } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import { BASE_URL } from '../../api/base'
import Switch from 'react-switch'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import ActionModal from '../../Components/ValidationModal/ActionModal'

function SingleClient() {
	const { register, handleSubmit, reset, control ,formState: { errors }} = useForm()
	const { id } = useParams()
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [client, setClient] = useState(null)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [modalIsOpenSuspende, setModalIsOpenSuspende] = useState(false);
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [loading, setLoading] = useState(false)
	const user = useSelector((state) => state.auth.user)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [suspendre, setSuspendre] = useState('')
    const isArchiveMode = window.location.pathname.includes('archive'); 
	const fetchClient=async(id)=>{
		setChargementEnCours(true)

		try {
			const data = await getSingleClient(id,token)
			setClient(data)
			setSuspendre(data.status)
			reset(data);
		} catch (error) {
			console.log('error',error)
			toast.error("Une erreur s'est produite", {
				style: {
				  fontFamily: 'Montserrat',
				},
			  });
		}finally{
			setChargementEnCours(false)

		}
	}
	useEffect(() => {
		

		if (id) {
			fetchClient(id)
		}
	}, [id])

	const onSubmit = async (values) => {
		setLoading(true)

		if (!values.reduction) {
			values.reduction = 0
		}
		const modifiableData = {
			reduction:values.reduction,
			petiteentreprise: values.petiteentreprise,
			tva: values.tva,
			activerRippeur: values.activerRippeur,
			seuilDepenses: values.seuilDepenses,
		}
		
		try {
		const res=await updateClient(client._id, modifiableData,token)
		toast.success(
			res.message,
			{
				style: {
					fontFamily: 'Montserrat',
				},
			}
		)
		history.goBack()
		} catch (error) {
			toast.error(
				error?.response.data?.errors  || "Une erreur s'est produite",
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}finally{
			setLoading(false)
		}
		
		
	}

	

	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					fontFamily: 'Montserrat',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingBottom: '1rem',
				}}
			> 
			{chargementEnCours ?
			    <div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"10rem"
			}}
			>
			<div className='customLoader'></div>	
			    </div>
			      :
				<div 
				  style={{width:'65%'}}>
				<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Fiche Client
								</p>
							</div>
                             {!isArchiveMode &&
							<div
								style={{
									display: 'flex',
								}}
							>
								<button
									onClick={ () => {
										setModalIsOpenSuspende(true)
									}}
									style={{
										border: 'none',
										background: '#ECC9F7',
										color: '#AB1CC5',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
									}}
								>
								{suspendre==="actif" ? 'Suspendre' : 'Activer'}
								</button>
								{(user.role==="admin" || user?.role==="super admin" )&&
								<button
									onClick={ () => {
										setModalIsOpenDelete(true)
									}}
									style={{
										border: 'none',
										background: '#F7CDC9',
										color: '#C5431C',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '1rem',
									}}
								>
									Supprimer
								</button>
                                }
								<button
								    onClick={ () => {
										setModalIsOpen(true)			
									}}
									style={{
										border: 'none',
										background: '#168EEA',
										color: '#FFF',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '2rem',
									}}
								>
									Enregistrer
								</button>
							</div>
			                }
						</div>
				</FormSectionCard>
				<form
					onSubmit={handleSubmit(onSubmit)}
				>
					
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Nom société
								</span>
								<CustomInput
									type='text'
									disabled
									placeholder='Wayne'
									defaultValue={client?.societe}
									style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
									}}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									SIRET
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='833 543 509 000'
									defaultValue={client?.siret}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
								/>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Nom
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='Doe'
									defaultValue={client?.last_name}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
									
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Prénom
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='John'
									defaultValue={client?.first_name}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
									
								/>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Qualité
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='Directeur d’exploitation'
									{...register('quality', {
										required: true,
									})}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
									
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Domaine d’activité
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='Transport frigorifique'
									defaultValue={client?.domain}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
								/>
							</div>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Adresse
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								defaultValue={client?.address}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
								}}
							/>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Ville
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='Paris'
								defaultValue={client?.city}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
								}}
							/>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Code Postal
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='75017'
								defaultValue={client?.postalCode}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
								}}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Téléphone
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='(0) 01 45 66 32 88'
									defaultValue={client?.phone}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse email
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='John.doe@wayne.fr'
									defaultValue={client?.email}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
								    }}
								/>
							</div>
						</div>

						

						{client?.logo && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
									width: '100%',
									fontFamily: 'Montserrat',
									marginLeft: 10,
									marginTop: 10,
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Logo (PNG ou JPG)
								</span>

								<img
									alt=''
									style={{
										width: 100,
										height: 100,
										borderRadius: 5,
										objectFit: 'cover',
									}}
									src={`${BASE_URL}/${client.logo.path}`}
								/>
							</div>
						)}

						{client?.kbis && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
									width: '100%',
									fontFamily: 'Montserrat',
									marginLeft: 10,
									marginTop: 10,
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									KBIS (PDF ou JPG)
								</span>

								{client?.kbis?.mimetype !==
									'application/pdf' && (
									<img
										alt=''
										style={{
											width: 100,
											height: 100,
											borderRadius: 5,
											objectFit: 'cover',
										}}
										src={`${BASE_URL}/${client?.kbis?.path}`}
									/>
								)}

								{client?.kbis?.mimetype ==
									'application/pdf' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<img
											alt=''
											src={File}
											style={{
												height: 20,
												marginRight: '1rem',
											}}
										/>
										<span
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${BASE_URL}/${client.kbis.path}`
												)
											}}
										>
											{client?.kbis?.name}
										</span>
									</div>
								)}
							</div>
						)}
						{client?.piece_identite &&
						<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginBottom: '2rem',
							width: '100%',
							fontFamily: 'Montserrat',
							marginLeft: 10,
							marginTop: 10,
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Pièce d'identité 
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<img
									alt=''
									src={File}
									style={{
										height: 20,
										marginRight: '1rem',
									}}
								/>
								<span
									style={{
										cursor: 'pointer',
									}}
									onClick={() => {
										window.open(
											`${BASE_URL}/${client.piece_identite.path}`
										)
									}}
								>
									{client?.piece_identite?.name}
								</span>
							</div>
						
					</div>
						}

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Activer compte premium
								</span>
								<Controller
									control={control}
									name='premium'
									defaultValue={client?.premium || false} 
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Paramétrer une réduction en %
								</span>
								<CustomInput
                                  type='number'
                                  min={0}
                                  max={100}
                                  {...register('reduction', {
                                  valueAsNumber: true, 
                                  min: {
                                  value: 0,
                                  message: 'La réduction doit être un nombre supérieur ou égal à 0',
                                  },
                                  max: {
                                  value: 100,
                                  message: 'La réduction doit être un nombre inférieur ou égal à 100',
                                },
                                validate: {
                                 isNumber: (value) => !isNaN(value) || 'La réduction doit être un nombre valide', 
                                 },
                               })}
                               />
							   {errors.reduction && (
                               <span style={{ color: 'red', fontSize: '12px' ,marginTop:'0.5rem'}}>
                               {errors.reduction.message}
                              </span>
                               )}
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
								Petite entreprise
								</span>
								<Controller
									control={control}
									name='petiteentreprise'
									defaultValue={client?.petiteentreprise || false} 
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									seuil
								</span>
								<CustomInput
                                 type="number"
                                 {...register('seuilDepenses', {
                                 valueAsNumber: true, 
                                 min: {
                                  value: 0,
                                  message: 'Le seuil de dépenses doit être un nombre supérieur ou égal à 0',
                                },
                                validate: {
                                isNumber: (value) => !isNaN(value) || 'Le seuil de dépenses doit être un nombre valide', 
                               },
                               })}
                             />
                           {errors.seuilDepenses && (
                            <span style={{ color: 'red', fontSize: '12px',marginTop:'0.5rem' }}>
                             {errors.seuilDepenses.message}
                           </span>
                           )}
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     TVA
								</span>
								<Controller
									control={control}
									name='tva'
									defaultValue={client?.tva || false} 
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>
							<div style={{ width: 50 }} />
                            <div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     Activer les rippeurs
								</span>
								<Controller
									control={control}
									name='activerRippeur'
									defaultValue={client?.activerRippeurs || false} 
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>
						</div>
					</FormSectionCard>
				</form>
				</div>
            }
			</div>
			<ActionModal
			  isOpen={modalIsOpenSuspende}
			  onRequestClose={()=>setModalIsOpenSuspende(false)}
			  title={suspendre==="actif" ? 'Suspendre Client' :  'Activer Client' }
			  message=	{`Êtes-vous sûr de vouloir ${suspendre==="actif" ? 'Suspendre' : 'Activer'} ce Client ?`}
			  onConfirm={async () => {	
				setLoading(true)
				try {
					const data={status:suspendre==="actif" ? "suspendu" :"actif"}
					const res=await toggleSuspendreClient(
						client._id,
						data,
						token
					)
				toast.success(res.message, {
				style: {
					fontFamily: 'Montserrat',
				},
			})
			setSuspendre(suspendre==="actif" ? "suspendu" :"actif")
			history.goBack()
				} catch (error) {
					toast.error(
						error.response?.data.message || 'Erreur lors de la suspension du client',
						{
							style: {
								fontFamily: 'Montserrat',
							},
						}
					)
				}finally {
					setLoading(false)
					setModalIsOpenSuspende(false)
				}	
				}}
				
			  onCancel={()=>setModalIsOpenSuspende(false)}
			  isLoading={loading}
			/>
			<ActionModal
			  isOpen={modalIsOpenDelete}
			  onRequestClose={()=>setModalIsOpenDelete(false)}
			  title="Supprimer Client"
			  message="Êtes-vous sûr de vouloir supprimer ce Client ?"
			  onConfirm={async () => {	
				setLoading(true)
				   try {
					const res=await softDeleteClient(client._id,token)
					toast.success(res.message, {
						style: {
							fontFamily: 'Montserrat',
						},
					})
					 history.goBack()
				   } catch (error) {
					toast.error(
						error.response?.data.message || "Erreur lors de la suppression du client ",
						{
							style: {
								fontFamily: 'Montserrat',
							},
						}
					)
				   }finally {
					setLoading(false)
					setModalIsOpenDelete(false)
				}
				  
				  
				}}				
			  onCancel={()=>setModalIsOpenDelete(false)}
			  isLoading={loading}
			/>
			<ActionModal
			  isOpen={modalIsOpen}
			  onRequestClose={()=>setModalIsOpen(false)}
			  title="Enregistrement des modifications"
			  message={`Êtes-vous sûr de vouloir enregistrer les modifications ?`}
			  onConfirm={async () => {	handleSubmit(onSubmit)()}}				
			  onCancel={()=>setModalIsOpen(false)}
			  isLoading={loading}
			/>
		
	
		</LayoutAdmin>
	)
}

export default SingleClient
