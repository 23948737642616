import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import { getSingleClient, updateClientAccount } from '../../api/clients'
import { useDispatch, useSelector } from 'react-redux'
import Switch from 'react-switch'
import Container from '../../Components/LayoutClient/container.component'
import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import Modal from 'react-modal'
import { BASE_URL} from '../../api/base'
import UploadFile from '../../Components/UploadSingleFile/upload.component'
import toast from 'react-hot-toast'
import { updateProfile } from '../../redux/auth'
import { useForm,Controller } from 'react-hook-form'
import { useHistory } from 'react-router'
import { payTarifSEPA } from '../../api/tarif'


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function ClientAccount() {
	const { _id } = useSelector((state) => state.auth.user)
	const [loading, setLoading] = useState(false)
	const history=useHistory()
	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		control,
		formState: { errors },
	} = useForm()

	 const [client, setClient] = useState()
	 const [chargementEnCours, setChargementEnCours] = useState(false);
	 const token = useSelector((state) => state.auth.token);
    
	async function fetchClient(_id) {
		setChargementEnCours(true)
		try {
			const data = await getSingleClient(_id,token)
			setClient(data)
			reset({
				quality:data?.quality,
				address:data?.address,
				city:data?.city,
				postalCode:data?.postalCode,
				phone:data?.phone
			})
			
			
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
	
		

	}
	useEffect(() => {
			fetchClient(_id)	
	}, [_id])
	const handlePayTarif = async () => {
		try {
		  const response = await payTarifSEPA({})
		  console.log(response);
		  window.location.href = response.url;
		} catch (error) {
		  console.error('Une erreur s\'est produite lors du paiement :', error);
		  // Gérez l'erreur de manière appropriée, par exemple, affichez un message à l'utilisateur.
		}
	  };
	  
	

	

	

	

	const onSubmit = async (data) => {
		setLoading(true)
		
		

		const modifiableData = {
			
			quality: data.quality,
			address: data.address,
			city: data.city,
			postalCode: data.postalCode,
			phone: data.phone,
			password:data.password,
		}
		if (modifiableData.password.length === 0) {
			delete modifiableData.password
		}
		const modifiedFiles = [];
		const fileFields = [
			'kbis', 
			'logo',
			'piece_identite'
		];
		fileFields.forEach(field => {
			if (data[field]) {
				modifiedFiles.push({
				[field]: data[field] 
			  });
			}
			delete data[field];  

		  });
		try {
			
			
			const res = await updateClientAccount(_id,modifiableData,modifiedFiles,token)

			toast.success('Votre compte a été mis à jour', {
				style: {
					fontFamily: 'Montserrat',
				},
			})

			dispatch(updateProfile(res))
			fetchClient(_id)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setLoading(false)
		}
	}
	
	return (
		<Container>
			<Wrapper>
				<GradientText>MON COMPTE</GradientText>
			</Wrapper>
			{chargementEnCours ?
			<div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"3rem",
			  top: 0,
			  left: 0,
			  right: 0,
			  bottom: 0,
   
			}}
			>
			<div className='customLoader'></div>	
			</div>
			:
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						 width: '70%',
						fontFamily: 'Montserrat',
						alignSelf: 'center',
					}}
				>
					<div>
									{/* <button 
									 style={{
										padding: '1rem',
										borderRadius: '0.3rem',
										background: '#50f5a9',
										color:"white",
										border: 'none',
										marginTop:'1rem'
	                                  
									}}
									onClick={()=>handlePayTarif()}>Sauvegarder les informations de la carte</button> */}
									
									{client?.premium &&	(
									!client.paymentMethodId &&
													
									<button 
									 style={{
										padding: '1rem',
										borderRadius: '0.3rem',
										background: '#50f5a9',
										color:"white",
										border: 'none',
										marginTop:'1rem',
										fontFamily: 'Montserrat',
	                                    fontWeight: 'bold',
										cursor: 'pointer'
	                                  
									}}
									onClick={()=>{history.push(`/client/card`)}}
									>
									Enregistrer vos informations de paiement
									</button>
                                    )}
									</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Wayne'
										defaultValue={client?.societe}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										SIRET
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='833 543 509 000'
										defaultValue={client?.siret}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
							            disabled
										type='text'
										placeholder='Doe'
										defaultValue={client?.last_name}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										placeholder='John'
										disabled
										defaultValue={client?.first_name}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité
									</span>
									<Controller
                                    name="quality"
                                    control={control}
                                    defaultValue={client?.quality} 
                                    rules={{
                                    required: 'Veuillez indiquer la qualité du poste.',
                                    minLength: {
                                    value: 3,
                                    message: 'La qualité doit contenir au moins 3 caractères.',
                                    },
                                    pattern: {
                                    value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
                                    message: 'La qualité doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
                                    },
                                    }}
                                    render={({ field }) => (
                                     <CustomInput
                                      type="text"
                                      placeholder="Directeur d’exploitation"
                                      {...field} 
                                     />
                                    )}
                                    />
									
									{errors.quality && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                      {errors.quality.message}
                                    </div>
                                     )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Domaine d’activité
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Transport frigorifique'
										defaultValue={client?.domain}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Pays 
								</span>
								<CustomInput
										type='text'
										disabled
										defaultValue={client?.pays?.label}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<Controller
                                    name="address"
                                    control={control}
									defaultValue={client?.address }
                                    rules={{
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^[A-Za-z0-9\s',-]+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
                                    }}
                                    render={({ field }) => (
                                     <CustomInput
                                     type='text'
									 placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
                                      {...field} 
                                     />
                                    )}
                                    />
								   {errors.address && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                    {errors.address.message}
                                    </div>
                                   )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<Controller
                                    name="city"
                                    control={control}
									defaultValue={client?.city}
                                    rules={{
										required: 'Veuillez indiquer la ville.',
										minLength: {
										   value: 3,
										   message: 'La ville doit contenir au moins 3 caractères.',
										 },
										 pattern: {
										   value: /^.+$/,
										   message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										 },
                                    }}
                                    render={({ field }) => (
                                     <CustomInput
                                     type='text'
									 placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
                                      {...field} 
                                     />
                                    )}
                                    />
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal 
								</span>
								<Controller
                                    name="postalCode"
                                    control={control}
									defaultValue={client?.postalCode}
                                    rules={{
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
                                    }}
                                    render={({ field }) => (
                                     <CustomInput
                                     type='text'
									 placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
                                      {...field} 
                                     />
                                    )}
                                    />
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<Controller
                                    name="phone"
                                    control={control}
									defaultValue={client?.phone}
                                    rules={{
										required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													
											},
                                    }}
                                    render={({ field }) => (
                                     <CustomInput
                                      type='text'
									  placeholder='(0) 01 45 66 32 88'
                                      {...field} 
                                     />
                                    )}
                                    />
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                               )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='test@gmail.com'
										defaultValue={client?.email}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Changer mon mot de passe
										</span>
									</div>
									<CustomInput
										type='password'
										//disabled
										autoComplete='new-password'
										placeholder='Saisir mot de passe'
										{...register('password', {
											minLength: 8,
										})}
									/>
								</div>
								

								 <div style={{ width: '1rem' }} />
								 <div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									
										<span
										
											style={{
												color: '#262626',
												paddingBottom: '1rem',
																						}}
										>
									    TVA
										</span>
										<Switch
											checked={client?.tva}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
											disabled
										/>
									
									
								</div>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
									width: '100%',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Logo(PNG ou JPG)
									</span>
									<span
										style={{
											marginBottom: '1rem',
											color: '#838EAB',
											fontSize: 14,
										}}
									>
										(Facultatif)
									</span>
								</div>
								
								<Controller
                                     name='logo'
                                     control={control}
					                 render={({ field }) => (
                                    <UploadFile
                                     name={field.name}
                                     accept={'.jpg, .png'}
                                     setValue={setValue}
                                     {...field}
                                    />
                                   )}
                                   />
									{client?.logo &&
									client?.logo ?.mimetype !==
										'application/pdf' && (
										<img
											style={{
												width: 100,
												height: 100,
												borderRadius: 5,
												objectFit: 'cover',
											}}
											src={`${BASE_URL}/${client?.logo?.path}`}
										/>
									)}
									{client?.logo &&
									client?.logo.mimetype ==
										'application/pdf' && (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<img
												src={File}
												style={{
													height: 20,
													marginRight: '1rem',
												}}
											/>
											<span
												style={{
													cursor: 'pointer',
												}}
												onClick={() => {
													window.open(
														`${BASE_URL}/${client?.logo?.path}`
													)
												}}
											>
												{client?.logo?.name}
											</span>
										</div>
									)}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										KBIS (PDF)
									</span>
									<span
										style={{
											marginBottom: '1rem',
											color: '#838EAB',
											fontSize: 14,
										}}
									>
										(Facultatif)
									</span>
								</div>
								
								<Controller
                                     name='kbis'
                                     control={control}
					                 render={({ field }) => (
                                    <UploadFile
                                     name={field.name}
									 accept={'.pdf'}
                                     setValue={setValue}
                                     {...field}
                                    />
                                   )}
                                />
								{client?.kbis &&
				                <div
                                 style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 }}
                                >
                                <img
                                src={File}
                               style={{
                                height: 20,
                                marginRight: '1rem',
                               }}
                               />
                               <span
                                style={{
                                cursor: 'pointer',
                                color: '#007bff',
                                }}
                                onClick={() => {
                                window.open(`${BASE_URL}/${client?.kbis?.path}`)
                                }}
                                >
                                {client?.kbis?.name}
                               </span>
                               </div>
                               }
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Pièce d'identité (PDF)
									</span>
								</div>
								
								<Controller
                                     name='piece_identite'
                                     control={control}
					                 render={({ field }) => (
                                    <UploadFile
                                     name={field.name}
									 accept={'.pdf'}
                                     setValue={setValue}
                                     {...field}
                                    />
                                   )}
                                />
								{client?.piece_identite &&
				                <div
                                 style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 }}
                                >
                                <img
                                src={File}
                               style={{
                                height: 20,
                                marginRight: '1rem',
                               }}
                               />
                               <span
                                style={{
                                cursor: 'pointer',
                                color: '#007bff',
                                }}
                                onClick={() => {
                                window.open(`${BASE_URL}/${client?.piece_identite?.path}`)
                                }}
                                >
                                {client?.piece_identite?.name}
                               </span>
                               </div>
                               }
							</div>
						</FormSectionCard>
						<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
								 {loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					
             
					</form>
				</div>
			</div>
             }
		</Container>
	)
}

export default ClientAccount
