import { Controller, useForm } from 'react-hook-form'
import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	StyledTextArea,
	SubmitButton,
	SubmitButtonContainer,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useRef, useState,useEffect } from 'react'
import toast from 'react-hot-toast'
import LayoutChauffeur from '../../../Components/LayoutChauffeur/LayoutChauffeur'
import UploadMultipleFiles from '../../../Components/UploadMultipleFiles/UploadMultipleFiles'
import { getSignalement, updateSignalement } from '../../../api/signalements'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {getCommande} from '../../../api/commande'
import { deleteMultipleMedia } from '../../../api/upload'

function EditSignalement() {
	

	const history = useHistory()
	const { commande_id, signalement_id } = useParams()
	const [loading, setLoading] = useState(false)
	const [signalement,setSignalement]=useState(null)
	const [commande,setCommande]=useState(null)
	const [deletedFiles, setDeletedFiles] = useState([]);
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const { register, handleSubmit, watch, setValue,reset, control,formState: { errors}} = useForm()
	const token = useSelector((state) => state.auth.token);
	const file = useRef()
	file.current = watch('file', null)

	const type = useRef()
	type.current = watch('type', '')

	useEffect( () => {
		async function _getSignalement(_id) {
			try {
				const data = await getSignalement(_id,token)
				setSignalement(data)

				reset({
					note: data.note,
					type: data.type,
					course:data.course,
					duration: data.duration,
				})
			} catch (error) {
				// TODO show some type of error alert
				console.log(error)
			}
		}
		signalement_id && _getSignalement(signalement_id)
		async function _getCommande(_id) {
			setChargementEnCours(true)
			try {
				const data = await getCommande(_id,token)
				setCommande(data)
			} catch (error) {
				// TODO show some type of error alert
				console.log(error)
			}
			finally{
				setChargementEnCours(false)
	
			}
		}
		_getCommande(commande_id)		
	}, [commande_id, signalement_id])
	const onSubmit = async (values) => {
		let data = { ...values }

		if (data.files) {
			data.files = data.files.map((file) => file.id ?? file._id)
		}
		// Vérifiez si une photo est requise
		const typesRequisPhoto = [
			'marchandise_abîmée',
			'route_inaccessible_fermée',
			'circulation_dense'
		];
		if (typesRequisPhoto.includes(data.type) && (!data.files || data.files.length === 0)) {
			
			toast.error(
				'Une photo est obligatoire pour ce type d\'anomalie.',
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
			return;
		}
		const updateData = {
			files: data.files,
			type: data.type, 
			note: data.note, 
		  };
		setLoading(true)
		try {
			await updateSignalement(signalement_id, updateData,token)
			await deleteMultipleMedia(deletedFiles)
			toast.success("Le signalement a été modifié  avec succès.", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
			history.goBack()
		} catch (error) {
			toast.error("Une erreur s'est produite.",
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
		}finally{
			setLoading(false)
		}
	}
	return (
		<LayoutChauffeur>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div>
				<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'modifier signalement'.toUpperCase()}
						</GradientText>
					</div>
                    
					{chargementEnCours ? 
						<div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  position: 'absolute',
						  top: 0,
						  left: 0,
						  right: 0,
						  bottom: 0,
			   
						}}
						>
						<div className='customLoader'></div>	
						</div> 
						:
						<form onSubmit={handleSubmit(onSubmit)}>
						
					
							<FormContentSection>
									<FormSectionTitle></FormSectionTitle>
									<FormSectionCard>
									<div 
									style={{
										display: 'flex',
                                        flexDirection: 'column',
                                         marginBottom: '2rem'}}>
										<span
									    style={{
											marginBottom: '1rem',
                                          color: 'black',
                                          fontSize: '14px',
                                          display: 'block',
										  fontWeight: 'bold',
										}}
										
									>
										Sélectionnez le type d'anomalie <span style={{ color: 'red' }}>*</span>
									</span>
									
									<Controller
											name="type"
											control={control}
											rules={{ required: true }}
											defaultValue=""
											render={({ field }) => (
												<select
													{...field}
													style={{
														padding: '1rem',
														borderRadius: '0.5rem',
														color: '#858ea8',
														border: 'none',
														fontFamily: 'Montserrat',
														flex: '1',
														outline: 'none',
														width: '100%'
													}}
												>
													<option value="" disabled>Sélectionnez une option</option>
													<option value="client_absent">Client absent</option>
													<option value="retard_aux_chargement">Retard aux chargement</option>
													<option value="livraison_reportée">Livraison reportée</option>
													<option value="retard_aux_déchargement">Retard aux déchargement</option>
													<option value="marchandises_manquantes_chargement">Marchandises manquantes/chargement</option>
													<option value="marchandise_refusée">Marchandise refusée</option>
													<option value="marchandises_incomplètes">Marchandises incomplètes</option>
													<option value="marchandise_abîmée">Marchandise abîmée</option>
													<option value="route_inaccessible_fermée">Route inaccessible ou fermée</option>
													<option value="circulation_dense">Circulation dense</option>
												</select>
											)}
										/>
										{errors.type && <p style={{ color: 'red', marginTop: '0.5rem' }}>Veuillez sélectionner le type d'anomalie</p>}
										
										</div>
									</FormSectionCard>
								</FormContentSection>
						

						<FormContentSection>
							<FormSectionTitle></FormSectionTitle>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '2.5rem',
										width: '100%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
											fontWeight: 'bold',
										}}
									>
										Note <span style={{ color: 'red' }}>*</span>
									</span>
									<StyledTextArea
										type='text'
										rows={4}
										placeholder=''
										{...register('note', {
											required: true,
										})}
									/>
									{errors.note && 
									<p style={{color:'red',marginTop:'0,5rem'}}>Veuillez saisir une  note</p>}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
										// marginLeft: 10,
										// marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
												fontWeight: 'bold',

											}}
										>
											Joindre images ou PDF
										</span>
									</div>
									{signalement && (
										
										<UploadMultipleFiles
											name='files'
											initialFiles={signalement.files}
											onChange={(files) => {
												setValue('files', files)
											}}
											id={commande._id}
											setDeletedFiles={setDeletedFiles}
										/>
										
									)}

								</div>
							</FormSectionCard>
						</FormContentSection>

						<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
						<SubmitButton type='submit' disabled={loading}>
								
								{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					   </form>
                    }
						
				
				</div>
			</div>

		
		</LayoutChauffeur>
	)
}

export default EditSignalement
